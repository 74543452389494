import { PageType } from 'Shared/State';
import { componentNames } from 'Shared/component-registry';
import StoreSelectionPageViewModel from './StoreSelectionPageViewModel.type';

type StoreSelectionPageType = StoreSelectionPageViewModel & PageType;

export default (currentPage: PageType): currentPage is StoreSelectionPageType => {
  return (
    currentPage.componentName === componentNames.Avensia_Common_Features_PickUpInStore_StoreSelectionPageViewModel
  );
};

import { PageType, Action } from 'Shared/State';
import { equalsUrl, PAGE_LOAD_SUCCESS, PageLoadSuccessAction } from '@polarnopyret/scope';
import { componentIsSearch } from './current-page-is-search';
import SearchPageType from './SearchPage.type';

type SearchPage = PageType & SearchPageType;

export default function(state: PageType = null, action: Action): SearchPage {
  if (componentIsSearch(state.componentName)) {
    const searchPage = state as SearchPage;
    switch (action.type) {
      case PAGE_LOAD_SUCCESS: {
        const pageLoadSuccessAction = action as PageLoadSuccessAction;
        const oldSearch = pageLoadSuccessAction.urlBeforeLoad;
        const newSearch = pageLoadSuccessAction.url;
        if (oldSearch && !equalsUrl(oldSearch, newSearch)) {
          return Object.assign({}, searchPage, {
            scrollPosition: 0,
          });
        }
        return searchPage;
      }
      default:
        return searchPage;
    }
  }
  return state as SearchPage;
}

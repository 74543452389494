import React from 'react';
import ProductCard from '../ProductCard';
import ProductListItemViewModel from '../ProductListing/ProductListItemViewModel.type';
import { styled, StyledProps } from '@glitz/react';
import SwiperList from 'Shared/SwiperList';

type PropType = StyledProps & {
  products: ProductListItemViewModel[];
  numberOfProductsVisible?: number;
  isCompact: boolean;
  quickAddToCart?: boolean;
  cellSpacing?: number;
  listHeading?: string;
  alternativeNav?: boolean;
  overflowHidden?: boolean;
  showNav?: boolean;
  freeMode?: boolean;
  showSwirl?: boolean;
  activeIndex?: number;
  sliderRef?: React.MutableRefObject<any>;
  onSlideChange?: (swiper: any) => any;
};

type StateType = {
  translateX?: number;
};

const SwiperWrap = styled.div({
  width: '100%'
})

const ProductCardList = (props: PropType & StateType) => {
  const { products, numberOfProductsVisible, isCompact, cellSpacing, quickAddToCart, listHeading, alternativeNav, overflowHidden, showNav, freeMode, sliderRef, showSwirl  } =
    props;

  return (
    (products?.length ?? 0) > 0 && (
      <SwiperWrap>
        <SwiperList
          listHeading={listHeading}
          slidesPerView={numberOfProductsVisible ?? (isCompact ? 1.2 : 3.6666)}
          slidesPerGroup={isCompact ? 1 : 2}
          spaceBetween={cellSpacing ?? (isCompact ? 12 : 16)}
          isCompact={isCompact}
          alternativeNav={alternativeNav}
          overflowHidden={overflowHidden}
          showNav={showNav}
          cssMode={true}
          freeMode={freeMode}
          showSwirl={showSwirl}
          sliderRef={sliderRef}
          onSlideChange={props.onSlideChange}
        >
          {products?.map((product, index) => (
            <div key={index + product.itemNumber} className="swiper-slide">
              <ProductCard product={product} listIndex={index} quickAddToCart={quickAddToCart}/>
            </div>
          ))}
        </SwiperList>
      </SwiperWrap>
    )
  );
};

export default styled(ProductCardList);

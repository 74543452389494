import React from 'react';
import { styled, StyledProps, applyClassName, StyledElementProps } from '@glitz/react';
import PopLogo from 'Shared/Logotype/POP-Logo.svg';
import PopLogoAlt2 from 'Shared/Logotype/POP_logo_alt2.svg';
import {factoryScaledToFit} from '../Icons/icon';

export default styled(
  applyClassName((props: StyledElementProps & StyledProps) => {
      return <PopLogo className={props.className} />;
    }),
);

export const AltLogo = styled(
  applyClassName((props: StyledElementProps & StyledProps) => {
      return <PopLogoAlt2 className={props.className} />;
    }),
);

export const ScaledLogo = factoryScaledToFit(PopLogoAlt2);

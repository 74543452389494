import React from 'react';
import {MainMenuType} from 'Shared/State';
import MainMenuItemType from 'SiteLayout/MainMenu/MainMenuItem.type';
import SiteHeader from "SiteLayout/Header/SiteHeader.type";
import SecondaryMenuItem from "SiteLayout/MainMenu/SecondaryMenuItem.type";
import { styled, StyledProps } from '@glitz/react';
import connect from 'Shared/connect';
import Button, { Appearance as ButtonAppearance } from 'Shared/Button';
import Ul from 'Shared/Generic/Ul';
import { toggleMainMenu, setMainMenuCategory, setSelectedSecondaryMenuItem } from 'SiteLayout/MainMenu/action-creators';
import { colorFromTag } from 'Shared/utils';

type PropType = {
  isVisible: boolean;
  mainMenu: MainMenuType;
  foreGroundColor: string;
} & StyledProps & ConnectedPropType;

type ConnectedPropType = {
  currentUrl: string;
  toggleMainMenu: (item: MainMenuItemType) => void;
  setMainMenuCategory: (item: MainMenuItemType) => void;
  setSelectedSecondaryMenuItem: (item: SecondaryMenuItem) => void;
  header: SiteHeader;
};

const Base = styled.div({
  display: 'flex',
  width: '100%',
  height: '100%',
  justifyContent: 'space-between',
  paddingTop: 16, //Margin between HR and text
});

const MenuButton = styled(Button, {
  textTransform: 'uppercase',
  height: '100%',
  marginLeft: '12px',
  marginRight: '12px',
  ':first-child': {
    marginLeft: 0,
  },
  ':last-child': {
    marginRight: 0,
  },
  position: 'relative',
  ':before': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '2px',
    borderRadius: '4px',
    bottom: '0',
    left: '0',
    transition: {
      property: 'transform',
      duration: '0.3s',
      timingFunction: 'ease-in-out',
    },
    transformOrigin: 'right',
    transform: 'scaleX(0)',
  },
  ':hover': {
    ':before': {
      transformOrigin: 'left',
      transform: 'scaleX(1)',
    },
  },
  fontSize: '14px',
  lineHeight: '100%',
  fontStyle: 'normal',
  fontWeight: 'bold',
  ':active': {
    color: 'inherit',
  },
  ':focus': {
    color: 'inherit',
  }
});

const SelectedMenuButton = styled(MenuButton, {
  ':before': {
    transformOrigin: 'left',
    transform: 'scaleX(1)',
  },
});

const List = styled(Ul, {
  fontSize: '14px',
  lineHeight: '100%',
  fontStyle: 'normal',
  fontWeight: 500,
})

const MenuBar = styled((props: PropType) => {
  const onButtonClick = (e: React.SyntheticEvent<{}>, item: MainMenuItemType) => {
    if (item.children.length > 0) {
      e.preventDefault();
      props.setMainMenuCategory(item);
    }
  };
  const onClickSecondary = (e: React.SyntheticEvent<{}>, item: SecondaryMenuItem) => {
    if (item.children.length > 0) {
      e.preventDefault();
      props.setSelectedSecondaryMenuItem(item);
    }
  };

  return (
    <Base>
      <List row>
        {props.mainMenu.primaryMenuItems.map(menuItem => {
          const ButtonComponent =
            props.mainMenu.isOpen && props.mainMenu.selectedCategory && menuItem === props.mainMenu.selectedCategory
              ? SelectedMenuButton
              : MenuButton;

          return (
            <ButtonComponent
              {...(menuItem.children.length > 0 && { 'data-test': 'open-category-menu' })}
              onClick={(e: React.SyntheticEvent<{}>) => onButtonClick(e, menuItem)}
              key={menuItem.url}
              to={menuItem.url}
              title={menuItem.name}
              appearance={[ButtonAppearance.Bare]}
              css={{
                ':before': {
                  backgroundColor: props.foreGroundColor,
                },
                ...(menuItem.tagName && menuItem.tagName !== 'NONE' && {
                  color: colorFromTag(menuItem.tagName),
                  ':active': {
                    color: undefined,
                  },
                  ':focus': {
                    color: undefined,
                  }
                })
              }}
            >
              {menuItem.name}
            </ButtonComponent>
          );
        })}
      </List>
      <List row>
        {props.header.secondaryMenuItems.map(menuItem => {
          const ButtonComponent =
            props.mainMenu.isOpen && props.mainMenu.selectedSecondaryMenuItem === menuItem
              ? SelectedMenuButton
              : MenuButton;

          return (
            <ButtonComponent
              {...(menuItem.children.length > 0 ? { 'data-test': 'open-category-menu' } : undefined)}
              onClick={(e: React.SyntheticEvent<{}>) => {
                onClickSecondary(e, menuItem);
              }}
              key={menuItem.url}
              to={menuItem.url}
              title={menuItem.name}
              appearance={[ButtonAppearance.Bare]}
              className={`secondary-menu-item-${menuItem.name}`}
              css={{
                ':before': {
                  backgroundColor: props.foreGroundColor,
                },
              }}
            >
              {menuItem.name}
            </ButtonComponent>
          );
        })}
      </List>
    </Base>
  );
});

export default connect(
  state => ({
    currentUrl: state.currentPage.url,
    header: state.appShellData.header,
  }),
  dispatch => ({
    toggleMainMenu(item: MainMenuItemType) {
      dispatch(toggleMainMenu(item));
    },
    setMainMenuCategory(item: MainMenuItemType) {
      dispatch(setMainMenuCategory(item));
    },
    setSelectedSecondaryMenuItem(item: SecondaryMenuItem) {
      dispatch(setSelectedSecondaryMenuItem(item));
    },
  }),
)(MenuBar);

import React from 'react';
import { TransitionMotion, spring, OpaqueConfig } from 'react-motion';
import { styled } from '@glitz/react';
import { Spinner24x24 } from 'Shared/Icons/Spinner';
import * as style from 'Shared/Style';

const timeout = 300;

type PlainStyleType<TValue> = {
  opacity: TValue;
};

type StylesType<TValue> = {
  key: string;
  style: PlainStyleType<TValue>;
};
type PropType = {
  visible: boolean;
};

type StateType = {
  visible: boolean;
};

export default class Loader extends React.Component<PropType, StateType> {
  visibilityTimer: number;
  constructor(props: PropType) {
    super(props);
    this.state = {
      visible: props.visible,
    };
  }
  componentWillUnmount() {
    if (this.visibilityTimer) {
      clearTimeout(this.visibilityTimer);
      this.visibilityTimer = null;
    }
  }
  componentWillReceiveProps(nextProps: PropType) {
    if (nextProps.visible) {
      clearTimeout(this.visibilityTimer);
      this.visibilityTimer = setTimeout(() => {
        this.setState({ visible: true });
      }, timeout);
    } else {
      if (this.visibilityTimer) {
        clearTimeout(this.visibilityTimer);
        this.visibilityTimer = null;
      }
      this.setState({ visible: false });
    }
  }
  shouldComponentUpdate(nextProps: PropType, nextState: StateType) {
    const shouldUpdate = this.state.visible !== nextState.visible;
    if (!shouldUpdate) {
      console.info('Ignoring update to Loader');
    }
    return shouldUpdate;
  }
  getStyles = (): Array<StylesType<OpaqueConfig>> =>
    this.state.visible
      ? [
          {
            key: 'spinner',
            style: { opacity: spring(1) },
          },
        ]
      : [];
  willEnter = (): PlainStyleType<number> => ({ opacity: 0 });
  willLeave = (): PlainStyleType<OpaqueConfig> => ({ opacity: spring(0) });
  render() {
    return (
      <TransitionMotion styles={this.getStyles} willEnter={this.willEnter} willLeave={this.willLeave}>
        {(interpolatedStyles: Array<StylesType<number>>) => {
          if (interpolatedStyles[0]) {
            const entry = interpolatedStyles[0];
            return (
              <Base key={entry.key} style={{ opacity: entry.style.opacity }}>
                <Glyph />
              </Base>
            );
          }
          return null;
        }}
      </TransitionMotion>
    );
  }
}

const Base = styled.div({
  position: 'fixed',
  zIndex: style.ZIndex.Spinner,
  top: '15vh',
  left: 'calc(50vw - 20px)',
  lineHeight: 1,
  transform: 'translateX(-50%)',
  pointerEvents: 'none',
  padding: { xy: '8px' },
  borderRadius: '50%',
  backgroundColor: 'white',
  color: style.colors.monochrome.black,
  boxShadow: `0 2px 4px 0 rgba(0, 0, 0, .1), 0 2px 8px 0 rgba(0, 0, 0, .05)`,
});

const Glyph = styled(Spinner24x24, {
  display: 'block',
});

import { styled, StyledProps, StyledComponent } from '@glitz/react';
import React from 'react';
import connect from 'Shared/connect';
import { isCompact } from 'Shared/Viewport';
import { WithRefProp } from '@glitz/react/types/styled/create';

const fontWeightBold = 500;
const fontWeightStandard = 450;

export enum DesktopSize {
  Scale96,
  Scale64,
  Scale56,
  Scale48,
  Scale40,
  Scale32,
  Static40,
  Static32,
  Static24,
  Static20,
  Static16,
  Static14,
  Static12,
  Static10,
  Inherit
}

export enum MobileSize {
  Scale48,
  Scale40,
  Scale32,
  Scale28,
  Static40,
  Static32,
  Static24,
  Static20,
  Static18,
  Static16,
  Static14,
  Static12,
  Static10,
  Inherit
}

type SizeAndHeight = {
  [key: number]: { fontSize: string; minHeight: string; fontWeight: number; upperCaseFontWeight?: number };
};

const desktopSizes = (): SizeAndHeight => ({
  [DesktopSize.Scale96]: { fontSize: '7.028vw', minHeight: '7.731vw', fontWeight: fontWeightBold },
  [DesktopSize.Scale64]: { fontSize: '4.685vw', minHeight: '5.154vw', fontWeight: fontWeightBold },
  [DesktopSize.Scale56]: {
    fontSize: '4.099vw',
    minHeight: '4.509vw',
    fontWeight: fontWeightStandard,
    upperCaseFontWeight: fontWeightBold,
  },
  [DesktopSize.Scale48]: { fontSize: '3.514vw', minHeight: '3.865vw', fontWeight: fontWeightBold },
  [DesktopSize.Scale40]: { fontSize: '2.928vw', minHeight: '3.221vw', fontWeight: fontWeightBold },
  [DesktopSize.Scale32]: { fontSize: '2.342vw', minHeight: '2.576vw', fontWeight: fontWeightBold },
  [DesktopSize.Static40]: {
    fontSize: '40px',
    minHeight: '44px',
    fontWeight: fontWeightStandard,
    upperCaseFontWeight: fontWeightBold,
  },
  [DesktopSize.Static32]: { fontSize: '32px', minHeight: '35.2px', fontWeight: fontWeightStandard },
  [DesktopSize.Static24]: {
    fontSize: '24px',
    minHeight: '26.4px',
    fontWeight: fontWeightStandard,
    upperCaseFontWeight: fontWeightBold,
  },
  [DesktopSize.Static20]: {
    fontSize: '20px',
    minHeight: '22px',
    fontWeight: fontWeightStandard,
    upperCaseFontWeight: fontWeightBold,
  },
  [DesktopSize.Static16]: {
    fontSize: '16px',
    minHeight: '17.6px',
    fontWeight: fontWeightStandard,
    upperCaseFontWeight: fontWeightBold,
  },
  [DesktopSize.Static14]: {
    fontSize: '14px',
    minHeight: '15.4px',
    fontWeight: fontWeightStandard,
    upperCaseFontWeight: fontWeightBold,
  },
  [DesktopSize.Static12]: {
    fontSize: '12px',
    minHeight: '13.2px',
    fontWeight: fontWeightStandard,
    upperCaseFontWeight: fontWeightBold,
  },
  [DesktopSize.Static10]: { fontSize: '10px', minHeight: '11px', fontWeight: fontWeightStandard },
  [DesktopSize.Inherit]: { fontSize: 'inherit', minHeight: 'inherit', fontWeight: 0 },
});

const mobileSizes = (): SizeAndHeight => ({
  [MobileSize.Scale48]: { fontSize: '13.333vw', minHeight: '14.666vw', fontWeight: fontWeightBold },
  [MobileSize.Scale40]: { fontSize: '11.11vw', minHeight: '12.221vw', fontWeight: fontWeightBold },
  [MobileSize.Scale32]: { fontSize: '8.888vw', minHeight: '9.777vw', fontWeight: fontWeightBold },
  [MobileSize.Scale28]: { fontSize: '6.555vw', minHeight: '7.211vw', fontWeight: fontWeightBold },
  [MobileSize.Static40]: {
    fontSize: '40px',
    minHeight: '44px',
    fontWeight: fontWeightStandard,
    upperCaseFontWeight: fontWeightBold,
  },
  [MobileSize.Static32]: { fontSize: '32px', minHeight: '35.2px', fontWeight: fontWeightStandard },
  [MobileSize.Static24]: {
    fontSize: '24px',
    minHeight: '26.4px',
    fontWeight: fontWeightStandard,
    upperCaseFontWeight: fontWeightBold,
  },
  [MobileSize.Static20]: {
    fontSize: '20px',
    minHeight: '22px',
    fontWeight: fontWeightStandard,
    upperCaseFontWeight: fontWeightBold,
  },
  [MobileSize.Static18]: { fontSize: '18px', minHeight: '19.8px', fontWeight: fontWeightStandard },
  [MobileSize.Static16]: {
    fontSize: '16px',
    minHeight: '17.6px',
    fontWeight: fontWeightStandard,
    upperCaseFontWeight: fontWeightBold,
  },
  [MobileSize.Static14]: {
    fontSize: '14px',
    minHeight: '15.4px',
    fontWeight: fontWeightStandard,
    upperCaseFontWeight: fontWeightBold,
  },
  [MobileSize.Static12]: {
    fontSize: '12px',
    minHeight: '13.2px',
    fontWeight: fontWeightStandard,
    upperCaseFontWeight: fontWeightBold,
  },
  [MobileSize.Static10]: { fontSize: '10px', minHeight: '11px', fontWeight: fontWeightStandard },
  [MobileSize.Inherit]: { fontSize: 'inherit', minHeight: 'inherit', fontWeight: 0 },
});

export enum ResponsiveSize {
  D96_M48,
  D96_M40,
  D64_M40,
  D64_M32,
  D56_M40,
  D56_M32,
  D56_M24,
  D48_M32,
  DS40_MS40,
  D40_M32,
  D40_M28,
  D40_M18,
  D32_M32,
  D32_M24,
  D32_M20,
  D32_M16,
  D24_M24,
  D24_M20,
  D24_M16,
  D20_M20,
  D20_M16,
  D16_M16,
  D14_M14,
  D14_M14_L130,
  D14_M12,
  D12_M12,
  D10_M10,
  Inherit
}

export enum ResponsiveLineHeight {
  D200_M200,
  D130_M130,
  D130_M120,
  D120_M120,
  D110_M110,
  D100_M100,
  D110_M130,
}

type ResponsiveSizes = {
  [key: number]: {
    desktop: DesktopSize;
    mobile: MobileSize;
    lineHeight: ResponsiveLineHeight;
    desktopLowerCase?: DesktopSize;
  };
};
type ResponsiveLineHeights = { [key: number]: { desktop: number; mobile: number } };

const responsiveSizes = (): ResponsiveSizes => ({
  [ResponsiveSize.D96_M48]: {
    desktop: DesktopSize.Scale96,
    mobile: MobileSize.Scale48,
    lineHeight: ResponsiveLineHeight.D100_M100,
  },
  [ResponsiveSize.D96_M40]: {
    desktop: DesktopSize.Scale96,
    mobile: MobileSize.Scale40,
    lineHeight: ResponsiveLineHeight.D100_M100,
  },
  [ResponsiveSize.D64_M40]: {
    desktop: DesktopSize.Scale64,
    mobile: MobileSize.Scale40,
    lineHeight: ResponsiveLineHeight.D100_M100,
  },
  [ResponsiveSize.D64_M32]: {
    desktop: DesktopSize.Scale64,
    mobile: MobileSize.Scale32,
    lineHeight: ResponsiveLineHeight.D100_M100,
  },
  [ResponsiveSize.D56_M40]: {
    desktop: DesktopSize.Scale56,
    mobile: MobileSize.Scale40,
    lineHeight: ResponsiveLineHeight.D100_M100,
  },
  [ResponsiveSize.D56_M32]: {
    desktop: DesktopSize.Scale56,
    mobile: MobileSize.Scale32,
    lineHeight: ResponsiveLineHeight.D110_M110,
  },
  [ResponsiveSize.D56_M24]: {
    desktop: DesktopSize.Scale56,
    mobile: MobileSize.Static24,
    lineHeight: ResponsiveLineHeight.D100_M100,
  },
  [ResponsiveSize.D48_M32]: {
    desktop: DesktopSize.Scale48,
    mobile: MobileSize.Scale32,
    lineHeight: ResponsiveLineHeight.D100_M100,
  },
  [ResponsiveSize.DS40_MS40]: {
    desktop: DesktopSize.Static40,
    mobile: MobileSize.Static40,
    lineHeight: ResponsiveLineHeight.D100_M100,
  },
  [ResponsiveSize.D40_M32]: {
    desktop: DesktopSize.Scale40,
    mobile: MobileSize.Scale32,
    lineHeight: ResponsiveLineHeight.D100_M100,
  },
  [ResponsiveSize.D40_M28]: {
    desktop: DesktopSize.Scale40,
    mobile: MobileSize.Scale28,
    lineHeight: ResponsiveLineHeight.D100_M100,
  },
  [ResponsiveSize.D40_M18]: {
    desktop: DesktopSize.Scale40,
    mobile: MobileSize.Static18,
    lineHeight: ResponsiveLineHeight.D110_M130,
  },
  [ResponsiveSize.D32_M32]: {
    desktop: DesktopSize.Static32,
    mobile: MobileSize.Static32,
    lineHeight: ResponsiveLineHeight.D110_M110,
  },
  [ResponsiveSize.D32_M24]: {
    desktop: DesktopSize.Scale32,
    mobile: MobileSize.Static24,
    lineHeight: ResponsiveLineHeight.D110_M110,
    desktopLowerCase: DesktopSize.Static32,
  },
  [ResponsiveSize.D32_M20]: {
    desktop: DesktopSize.Scale32,
    mobile: MobileSize.Static20,
    lineHeight: ResponsiveLineHeight.D100_M100,
  },
  [ResponsiveSize.D32_M16]: {
    desktop: DesktopSize.Static32,
    mobile: MobileSize.Static16,
    lineHeight: ResponsiveLineHeight.D100_M100,
  },
  [ResponsiveSize.D24_M24]: {
    desktop: DesktopSize.Static24,
    mobile: MobileSize.Static24,
    lineHeight: ResponsiveLineHeight.D110_M110,
  },
  [ResponsiveSize.D24_M20]: {
    desktop: DesktopSize.Static24,
    mobile: MobileSize.Static20,
    lineHeight: ResponsiveLineHeight.D100_M100,
  },
  [ResponsiveSize.D24_M16]: {
    desktop: DesktopSize.Static24,
    mobile: MobileSize.Static16,
    lineHeight: ResponsiveLineHeight.D100_M100,
  },
  [ResponsiveSize.D20_M20]: {
    desktop: DesktopSize.Static20,
    mobile: MobileSize.Static20,
    lineHeight: ResponsiveLineHeight.D100_M100,
  },
  [ResponsiveSize.D20_M16]: {
    desktop: DesktopSize.Static20,
    mobile: MobileSize.Static16,
    lineHeight: ResponsiveLineHeight.D100_M100,
  },
  [ResponsiveSize.D16_M16]: {
    desktop: DesktopSize.Static16,
    mobile: MobileSize.Static16,
    lineHeight: ResponsiveLineHeight.D130_M130,
  },
  [ResponsiveSize.D14_M14]: {
    desktop: DesktopSize.Static14,
    mobile: MobileSize.Static14,
    lineHeight: ResponsiveLineHeight.D100_M100,
  },
  [ResponsiveSize.D14_M14_L130]: {
    desktop: DesktopSize.Static14,
    mobile: MobileSize.Static14,
    lineHeight: ResponsiveLineHeight.D130_M130,
  },
  [ResponsiveSize.D14_M12]: {
    desktop: DesktopSize.Static14,
    mobile: MobileSize.Static12,
    lineHeight: ResponsiveLineHeight.D120_M120,
  },
  [ResponsiveSize.D12_M12]: {
    desktop: DesktopSize.Static12,
    mobile: MobileSize.Static12,
    lineHeight: ResponsiveLineHeight.D100_M100,
  },
  [ResponsiveSize.D10_M10]: {
    desktop: DesktopSize.Static10,
    mobile: MobileSize.Static10,
    lineHeight: ResponsiveLineHeight.D100_M100,
  },
  [ResponsiveSize.Inherit]: {
    desktop: DesktopSize.Inherit,
    mobile: MobileSize.Inherit,
    lineHeight: ResponsiveLineHeight.D100_M100,
  },
});

const responsiveLineHeights = (): ResponsiveLineHeights => ({
  [ResponsiveLineHeight.D130_M130]: { desktop: 130, mobile: 130 },
  [ResponsiveLineHeight.D130_M120]: { desktop: 130, mobile: 120 },
  [ResponsiveLineHeight.D120_M120]: { desktop: 120, mobile: 120 },
  [ResponsiveLineHeight.D110_M130]: { desktop: 110, mobile: 130 },
  [ResponsiveLineHeight.D110_M110]: { desktop: 110, mobile: 110 },
  [ResponsiveLineHeight.D100_M100]: { desktop: 100, mobile: 100 },
});

type ConnectedProps = {
  currentBreakpoint: number;
};

type BaseProps = StyledProps & {
  upperCase?: boolean;
  shouldAlwaysClaimHeight?: boolean;
};

type HTagElementProps = BaseProps & {
  fontSize?: ResponsiveSize;
  lineHeight?: ResponsiveLineHeight;
  className?: string;
  letterSpacing?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLElement> | undefined;
};

type HeadingElementProps = BaseProps & {
  fontSize: ResponsiveSize;
  lineHeight?: ResponsiveLineHeight;
  semanticTag?: StyledComponent<WithRefProp<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>>;
  className?: string;
  letterSpacing?: string;
  style?: React.CSSProperties;
};

type TextElementProps = React.HTMLAttributes<HTMLElement> &
  StyledProps & {
    fontSize?: ResponsiveSize;
    shouldAlwaysClaimHeight?: boolean;
    upperCase?: boolean;
    lineHeight?: ResponsiveLineHeight;
    keepMargin?: boolean;
  };

const connected = connect(
  (state) => ({
    currentBreakpoint: state.currentBreakpoint,
  }),
  (dispatch) => ({})
);

const Heading = connected(
  styled((props: { children: React.ReactNode } & ConnectedProps & HeadingElementProps) => {
    const {
      fontSize,
      shouldAlwaysClaimHeight = false,
      upperCase = true,
      compose,
      children,
      semanticTag,
      currentBreakpoint,
      lineHeight,
      className,
      letterSpacing,
      ...restProps
    } = props;

    const HeaderTag = semanticTag || styled.H2;
    const responsiveSize = responsiveSizes()[fontSize];
    const isMobile = isCompact(currentBreakpoint);

    const fs = isMobile
      ? mobileSizes()[responsiveSize.mobile]
      : desktopSizes()[
      !upperCase && responsiveSize.desktopLowerCase ? responsiveSize.desktopLowerCase : responsiveSize.desktop
      ];
    const responsiveLineHeight = responsiveLineHeights()[lineHeight ? lineHeight : responsiveSize.lineHeight];
    const lh = isMobile ? responsiveLineHeight.mobile : responsiveLineHeight.desktop;
    const fontWeight = upperCase && fs.upperCaseFontWeight ? fs.upperCaseFontWeight : fs.fontWeight;

    return (
      <HeaderTag
        {...restProps}
        className={className}
        css={compose({
          fontStyle: 'normal',
          fontWeight: fontWeight > 0 ? fontWeight : 'inherit',
          ...(upperCase && {
            textTransform: 'uppercase',
          }),
          fontSize: fs.fontSize,
          lineHeight: `${lh}%`,
          marginTop: 0,
          marginBottom: 0,
          ...(shouldAlwaysClaimHeight && {
            minHeight: fs.minHeight,
          }),
          ...(letterSpacing && {
            letterSpacing: letterSpacing,
          }),
        })}
      >
        {children}
      </HeaderTag>
    );
  }),
);

const TextInternal = connected(
  styled(
    (
      props: {
        children: React.ReactNode;
        ElementTag:
        | StyledComponent<WithRefProp<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>>
        | StyledComponent<WithRefProp<React.HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>>;
        display?: string;
      } & ConnectedProps &
        TextElementProps,
    ) => {
      const {
        compose,
        fontSize = ResponsiveSize.D16_M16,
        children,
        display,
        ElementTag,
        currentBreakpoint,
        shouldAlwaysClaimHeight,
        lineHeight,
        upperCase = false,
        keepMargin = false,
        color,
        ...restProps
      } = props;

      const responsiveSize = responsiveSizes()[fontSize];
      const isMobile = isCompact(currentBreakpoint);
      const fs = isMobile
        ? mobileSizes()[responsiveSize.mobile]
        : desktopSizes()[
        !upperCase && responsiveSize.desktopLowerCase ? responsiveSize.desktopLowerCase : responsiveSize.desktop
        ];
      const responsiveLineHeight = responsiveLineHeights()[lineHeight ? lineHeight : responsiveSize.lineHeight];
      const lh = isMobile ? responsiveLineHeight.mobile : responsiveLineHeight.desktop;
      const fontWeight = upperCase && fs.upperCaseFontWeight ? fs.upperCaseFontWeight : fs.fontWeight;

      return (
        <ElementTag
          {...restProps}
          css={compose({
            fontWeight: fontWeight,
            ...(display && {
              display: display,
            }),
            ...(upperCase && {
              textTransform: 'uppercase',
            }),
            fontStyle: 'normal',
            fontSize: fs.fontSize,
            ...(color && {
              color: color,
            }),
            lineHeight: `${lh}%`,
            letterSpacing: '0.01em',
            ...(shouldAlwaysClaimHeight && {
              minHeight: fs.minHeight,
            }),
            ...(!keepMargin && {
              margin: { xy: 0 },
            }),
          })}
        >
          {children}
        </ElementTag>
      );
    },
  ),
);

export const getResposiveFontSize = function (fontSize: ResponsiveSize, isMobile: boolean) {
  const responsiveSize = responsiveSizes()[fontSize];
  const fs = isMobile ? mobileSizes()[responsiveSize.mobile] : desktopSizes()[responsiveSize.desktop];
  return fs.fontSize;
};

export const H1 = styled((props: { children: React.ReactNode } & HTagElementProps) => {
  const { children, fontSize = ResponsiveSize.D96_M40, letterSpacing = '-0.01em', compose, ...restProps } = props;
  return (
    <Heading {...restProps} css={compose()} fontSize={fontSize} semanticTag={styled.H1} letterSpacing={letterSpacing}>
      {children}
    </Heading>
  );
});

export const H2 = styled((props: { children: React.ReactNode } & HTagElementProps) => {
  const { children, fontSize = ResponsiveSize.D96_M40, compose, ...restProps } = props;
  return (
    <Heading {...restProps} css={compose()} fontSize={fontSize} semanticTag={styled.H2}>
      {children}
    </Heading>
  );
});

export const H3 = styled((props: { children: React.ReactNode } & HTagElementProps) => {
  const { children, fontSize = ResponsiveSize.D32_M24, upperCase = false, compose, ...restProps } = props;
  return (
    <Heading {...restProps} upperCase={upperCase} css={compose()} fontSize={fontSize} semanticTag={styled.H3}>
      {children}
    </Heading>
  );
});

export const H4 = styled((props: { children: React.ReactNode } & HTagElementProps) => {
  const { children, fontSize = ResponsiveSize.D96_M40, upperCase = false, compose, ...restProps } = props;
  return (
    <Heading {...restProps} upperCase={upperCase} css={compose()} fontSize={fontSize} semanticTag={styled.H4}>
      {children}
    </Heading>
  );
});

export const HeadingLabel = styled((props: { children?: React.ReactNode } & TextElementProps) => {
  return (
    <TextInternal {...props} css={props.compose()} display="block" upperCase ElementTag={styled.Span}>
      {props.children}
    </TextInternal>
  );
});

export const InlineLabel = styled((props: { children: React.ReactNode } & TextElementProps) => {
  return (
    <TextInternal
      {...props}
      css={props.compose()}
      display="inline-block"
      fontSize={ResponsiveSize.D14_M14}
      upperCase
      ElementTag={styled.Span}
    >
      {props.children}
    </TextInternal>
  );
});

export const Paragraph = styled(
  (
    props: {
      children: React.ReactNode;
    } & TextElementProps,
  ) => {
    return (
      <TextInternal {...props} css={props.compose()} ElementTag={styled.P}>
        {props.children}
      </TextInternal>
    );
  },
);

export const TextInline = styled((props: { children: React.ReactNode } & TextElementProps) => {
  return (
    <TextInternal {...props} css={props.compose()} ElementTag={styled.Span}>
      {props.children}
    </TextInternal>
  );
});

export const Text = styled((props: { children: React.ReactNode } & TextElementProps) => {
  return (
    <TextInternal {...props} display={'inline-block'} css={props.compose()} ElementTag={styled.Span}>
      {props.children}
    </TextInternal>
  );
});

export const TextBlock = styled((props: { children: React.ReactNode } & TextElementProps) => {
  return (
    <TextInternal {...props} css={props.compose()} display={'block'} ElementTag={styled.Span}>
      {props.children}
    </TextInternal>
  );
});

export const TextBlockSmall = styled((props: { children: React.ReactNode } & TextElementProps) => {
  return (
    <TextInternal
      {...props}
      css={props.compose()}
      display={'block'}
      fontSize={ResponsiveSize.D14_M14}
      ElementTag={styled.Span}
    >
      {props.children}
    </TextInternal>
  );
});

export const TextBlockTiny = styled((props: { children: React.ReactNode } & TextElementProps) => {
  return (
    <TextInternal
      {...props}
      css={props.compose()}
      display={'block'}
      fontSize={ResponsiveSize.D12_M12}
      ElementTag={styled.Span}
    >
      {props.children}
    </TextInternal>
  );
});

export const TextLabel = styled((props: { children: React.ReactNode } & TextElementProps) => {
  return (
    <TextInternal
      {...props}
      css={props.compose()}
      display={'block'}
      upperCase
      fontSize={ResponsiveSize.D14_M14}
      ElementTag={styled.Span}
    >
      {props.children}
    </TextInternal>
  );
});

export const ArticleTag = styled((props: { children: React.ReactNode } & TextElementProps) => {
  return (
    <TextInternal
      {...props}
      display={'inline-block'}
      upperCase
      fontSize={ResponsiveSize.D14_M14}
      css={props.compose()}
      ElementTag={styled.Span}
    >
      {props.children}
    </TextInternal>
  );
});

import { styled } from '@glitz/react';
import React, { useCallback, useEffect } from 'react';
import { currentUrl, equalsUrl, loadPage, pushState, URLX } from '@polarnopyret/scope';
import { QuickSearchType } from 'Shared/State';
import SearchPageViewModelType from 'Search/SearchPageViewModel.type';
import { LeftTieredModalDesktop } from 'SiteLayout/MainMenu';
import connect from 'Shared/connect';
import { isCompact as functionIsCompact } from 'Shared/Viewport';
import {
  hideQuickSearch,
  loadQuickSearch,
  MIN_SEARCH_LENGTH,
  showQuickSearch,
  updateSelectedCompletion,
} from 'Search/action-creators';
import currentPageIsSearch from 'Search/current-page-is-search';
import { Search16x16 } from 'Shared/Icons/Search';
import { SolidButton, ButtonTheme } from 'Shared/TextButtons';
import * as searchHistory from 'Search/search-history';
import { ModalScrollableContent } from 'Shared/SharedComponents/atoms/Modal';
import QuickSearchResultProductCard from './QuickSearchResultProductCard';
import QuickSearchResultArticleCard from './QuickSearchResultArticleCard';
import MobileSearchResults from './MobileSearchResults';
import SnippetList from './SnippetList';
import SuggestionList from './SuggestionList';
import CategoryList from './CategoryList';
import RecentSearches from './RecentSearches';
import RecentProducts from './RecentProducts';
import { EmptyResult, SearchSoftHeading, searchUrl } from './shared';
import * as style from 'Shared/Style';

type ConnectedPropType = {
  currentBreakpoint: number;
  currentPageIsSearch: boolean;
  quickSearch?: QuickSearchType;
  placeHolder: string;
};

type ConnectedDispatchType = {
  loadPage: (url: URLX, showSpinner: boolean, shouldUseResult?: (page: SearchPageViewModelType) => boolean) => void;
  loadQuickSearch: (searchText: string) => void;
  hideQuickSearch: (setFirstSuggestionAsSearchText?: boolean) => void;
  showQuickSearch: () => void;
  updateSelectedCompletion: (index: number) => void;
};

const EmptyResultsText = styled(EmptyResult, {
  marginBottom: '40px',
})

export function useDebounce(effect: () => void, dependencies: React.DependencyList, delay: number) {
  const callback = useCallback(effect, dependencies);

  useEffect(() => {
    const timeout = setTimeout(callback, delay);
    return () => clearTimeout(timeout);
  }, [callback, delay]);
}

const SearchIconSmall = styled(Search16x16, {
  position: 'absolute', 
  top: '24px', 
  right: '16px',
})

const ResultsWrap = styled.div({
  flexBasis: '50%',
});

const ResultsContainer = styled.div({
  padding: { bottom: '32px' },
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});

const ModalScrollableContentResultsContainer = styled(ModalScrollableContent, {
  display: 'flex', 
  columnGap: '15px', 
  padding: { 
    top: '24px', 
    bottom: '32px', 
    x: '16px' 
  }
})

const ResultsButtonWrap = styled.div({
  width: '100%', padding: { x: '16px' }
})

const ResultsButton = styled(SolidButton, {
  width: '100%', 
  marginBottom: '16px', 
  textAlign: 'center'
})

const QuickSearchResults = connect(
  (state): ConnectedPropType => ({
    currentBreakpoint: state.currentBreakpoint,
    currentPageIsSearch: currentPageIsSearch(state.currentPage),
    quickSearch: state.quickSearch,
    placeHolder: state.appShellData.header?.quickSearchPlaceHolder,
  }),
  (dispatch): ConnectedDispatchType => ({
    loadPage(url: URLX, showSpinner: boolean, shouldUseResponse?: (page: SearchPageViewModelType) => boolean) {
      dispatch(loadPage({ url, options: { hideSpinner: !showSpinner }, shouldUseResponse }));
    },
    loadQuickSearch(searchText: string) {
      dispatch(loadQuickSearch(searchText));
    },
    hideQuickSearch(setFirstSuggestionAsSearchText = true) {
      dispatch(hideQuickSearch(setFirstSuggestionAsSearchText));
    },
    showQuickSearch() {
      dispatch(showQuickSearch());
    },
    updateSelectedCompletion(index: number) {
      dispatch(updateSelectedCompletion(index));
    },
  }),
)((props: ConnectedPropType & ConnectedDispatchType) => {
  const { quickSearch } = props;
  const { hideQuickSearch, currentBreakpoint } = props as any;
  const isCompact = functionIsCompact(currentBreakpoint);
  const initialSearch = quickSearch?.emptySearchLists;
  const loadResults = (useSuggestion: boolean) => {
    const query = (useSuggestion ? quickSearch?.suggestion || quickSearch?.searchText : quickSearch?.searchText) ?? '';

    searchHistory.add(query);

    const newUrl = searchUrl(query, true);

    // Note that we perform the page load regardless of whether the same result is already loaded.
    // This is because we want to load it without the preview parameter to notify eSales of the actual search.
    if (equalsUrl(currentUrl(), newUrl)) {
      loadPage({ url: newUrl });
    } else {
      go(query);
    }
  };
  
  const go = (searchText: string) => {
    if (searchText.length) {
      const newUrl = searchUrl(searchText, false);
      if (!equalsUrl(currentUrl(), newUrl)) {
        pushState(newUrl);
      }
    }
  };

  const showResults =
    (quickSearch?.quicksearchLists?.products?.length ?? 0) > 0 ||
    (quickSearch?.quicksearchLists?.results?.find((e) => e.id === 'cmspages')?.result?.length ?? 0) > 0;

  const activeSearch = (quickSearch?.searchText ?? '').length > MIN_SEARCH_LENGTH;

  const gotSnippetReults = (quickSearch?.quicksearchLists?.contentResultSnippets?.length ?? 0) > 0;
  const resultsAreEmpty = activeSearch && !showResults && !gotSnippetReults;

  if (isCompact) {
    return <MobileSearchResults {...{ initialSearch, showResults, loadResults }} />;
  }

  const suggestions = quickSearch?.quicksearchLists?.results
    ?.find((e) => e.id === 'completions' && activeSearch)
    ?.result.map(x => x.text) ?? initialSearch?.suggestions;

  const categories = quickSearch?.quicksearchLists?.results
    ?.find((e) => e.id === 'categories' && activeSearch)
    ?.result.map(x => ({ name: x.text, url: x.url })) ?? [];

  const initialSearchContent = (
    <>
      {initialSearch && (
        <>
          <SearchIconSmall />
          <SnippetList quicksearchLists={quickSearch?.quicksearchLists} isMobile={false} />

          {resultsAreEmpty && (
            <EmptyResultsText>
              {quickSearch?.quicksearchLists?.labels?.noResultsFor} {`"${quickSearch?.searchText}"`}
            </EmptyResultsText>
          )}
          <SuggestionList suggestions={suggestions} searchText={quickSearch?.searchText} heading={initialSearch?.labels?.suggestions} isMobile={false} />
          {categories.length > 0 && <CategoryList categories={categories} heading={quickSearch?.quicksearchLists?.labels?.categories} isMobile={false} />}
          {!showResults && <RecentSearches recentSearches={initialSearch?.recentSearches} heading={initialSearch?.labels?.recentSearches} isMobile={true} />}
          {!(activeSearch) && initialSearch?.products?.length > 0 && <RecentProducts products={initialSearch?.products} heading={initialSearch?.labels?.recentProducts} isMobile={false} /> }
        </>
      )}
    </>
  );
                    
  const results = (
    <ModalScrollableContentResultsContainer>
      <ResultsWrap>
        <SearchSoftHeading>{quickSearch?.quicksearchLists?.labels?.products}</SearchSoftHeading>
        <ResultsContainer>
          {quickSearch?.quicksearchLists?.products?.map((e, i) => (
            <QuickSearchResultProductCard key={i} product={e} listIndex={i} />
          ))}
        </ResultsContainer>
      </ResultsWrap>
      <ResultsWrap>
        <SearchSoftHeading>{quickSearch?.quicksearchLists?.labels?.pages}</SearchSoftHeading>
        <ResultsContainer>
          {quickSearch?.quicksearchLists?.results
            ?.find((e) => e.id === 'cmspages')
            ?.result?.map((e, i) => (
              <QuickSearchResultArticleCard key={i} entry={e} />
            ))}
        </ResultsContainer>
      </ResultsWrap>
    </ModalScrollableContentResultsContainer>
  );

  return (
    <LeftTieredModalDesktop
      isOpen={!!initialSearch && !!quickSearch?.isOpen}
      onClose={() => hideQuickSearch()}
      FirstTierChildren={initialSearchContent}
      ShownBelowFirstTier={
        (quickSearch?.searchText ?? '').length > MIN_SEARCH_LENGTH && (
          <ResultsButtonWrap>
            <ResultsButton
              text={quickSearch?.quicksearchLists?.labels?.showResults ?? ''}
              onClick={() => {
                hideQuickSearch(false);
                loadResults(false);
              }}
              disabled={resultsAreEmpty}
              theme={ButtonTheme.BlackToWhite}
              display="block"
            />
          </ResultsButtonWrap>
        )
      }
      SecondTierChildren={results}
      secondTier={showResults}
      secondTierColor={style.colors.monochrome.white}
      secondTierWide
    />
  );
});

export default QuickSearchResults;
import { epiPropertyValue } from '@polarnopyret/scope-episerver';
import { URLX, currentUrl } from '@polarnopyret/scope';
import * as style from 'Shared/Style';

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object
    ? RecursivePartial<T[P]>
    : T[P];
};

export function getCurrentBaseUrl() {
  const url = currentUrl();
  return new URLX(`${url.protocol}//${url.host}${url.pathname}`);
}

export function equalsBaseUrl(urlA: URLX, urlB: URLX) {
  if (
    urlA.host === urlB.host &&
    urlA.pathname === urlB.pathname 
  ) {
    return true;
  }

  return false;
}

export function FormatString(str: string, ...val: string[]) {
  for (let index = 0; index < val.length; index++) {
    str = str.replace(`{${index}}`, val[index]);
  }
  return str;
}

export function formatStringByKeys(originalString: string, interpolands: { [item: string]: string }): string {
  return Object.entries(interpolands).reduce((alteredString: string, interpoland: [string, string]) => {
    const [key, value] = interpoland;
    return alteredString.replace(`{${key}}`, value);
  }, originalString);
}

export const colorFromTag = (tag: string, fallbackColor?: string) => {
  switch (tag?.toLowerCase()) {
    case 'blackfriday':
      return style.colors.functionColors.blackfriday;
    case 'popplus':
      return style.colors.brandColors.green;
    case 'sale-alt':
    case 'sale':
      return style.colors.functionColors.sale;
    case 'online only':
      return style.colors.functionColors.onlineOnly;
    case 'discount':
      return style.colors.functionColors.discount;
    case 'new':
      return style.colors.functionColors.new;
    case 'christmas':
      return style.colors.functionColors.christmas;
    default:
      return fallbackColor ? fallbackColor : undefined;
  }
};

export const colorFromStatus = (status: string) => {
  switch (status) {
    case 'GREEN':
      return style.colors.brandColors.green;
    case 'RED':
      return style.colors.brandColors.red;
    case 'YELLOW':
      return style.colors.brandColors.yellow;
    default:
      return undefined;
  }
};

export const selectDeviceImageFromProperty = (image: Scope.Property<Scope.UrlViewModel>, imageSmallScreen: Scope.Property<Scope.UrlViewModel>, isMobile: boolean) => {
  const smallScreen = epiPropertyValue(imageSmallScreen);
  const normal = epiPropertyValue(image);

  return isMobile && smallScreen ? smallScreen : normal;
}

export const selectDeviceImage = (image: Scope.UrlViewModel, imageSmallScreen: Scope.UrlViewModel, isMobile: boolean) => {
  return isMobile && imageSmallScreen ? imageSmallScreen : image;
}

export const waitForElementToLoad = (selector: string) => {
  return new Promise(resolve => {
      if (document.querySelector(selector)) {
          return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver(mutations => {
          if (document.querySelector(selector)) {
              resolve(document.querySelector(selector));
              observer.disconnect();
          }
      });

      observer.observe(document.body, {
          childList: true,
          subtree: true
      });
  });
}
import React from 'react';
import { styled, applyClassName, StyledProps } from '@glitz/react';
import { Unchecked14x14 as UncheckedCheckBox, Checked14x14 as CheckedCheckBox } from 'Shared/Icons/Checkbox';
import { Unchecked16x16 as UncheckedRadio, Checked16x16 as CheckedRadio } from 'Shared/Icons/Radio';
import { Check16x16 } from 'Shared/Icons/Check';
import * as style from 'Shared/Style';
import { Spinner16x16 } from '../Icons/Spinner';
import { Error16x16 } from '../Icons/Error';

const Input = styled.input(style.visuallyHidden());
const CheckMark = styled(Check16x16, {});

const Empty = styled(CheckMark, {
  opacity: 0,
});

const CheckBox = styled(CheckedCheckBox, {
  marginRight: 8,
  color: style.colors.monochrome.white,
});

const EmptyCheckBox = styled(UncheckedCheckBox, {
  marginRight: 8,
  color: style.colors.monochrome.black,
  ':hover': {
    color: style.colors.monochrome.black,
  },
  ...style.transition({ property: 'color' }),
});

export const RelativeSpinner = styled(Spinner16x16, {
  position: 'relative',
  color: 'inherit',
});

export const RelativeReject = styled(Error16x16, {
  position: 'relative',
  color: 'inherit',
});

export enum Status {
  Default,
  Pending,
  Fulfilled,
  Rejected,
}

export type FadeContainerType = React.HTMLAttributes<HTMLSpanElement> &
  StyledProps & {
    visible: boolean;
  };
  
export const FadeContainer = styled((props: FadeContainerType) => {
  const { visible, compose, ...restProps } = props;
  return (
    <styled.Div
      {...restProps}
      css={compose({
        position: 'absolute',
        display: 'flex',
        ...style.truncate(),
        ...(!visible && {
          // There's a bug in Chrome that doesn't change the appearance of text color when
          // the browsers is performance optimizing the element and is completely hidden, that's
          // why the value is set to `0.01`
          opacity: 0.01,
          transform: 'scale(0.01)',
        }),
        width: '100%',
        ...style.transition({ property: ['opacity', 'transform'] }),
        top: 0,
        right: 0,
        left: 0,
        textAlign: 'center',
        paddingTop: 'inherit',
        paddingBottom: 'inherit',
      })}
    />
  );
});

export const IconContainer = styled.div({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
});

type PropType = React.InputHTMLAttributes<HTMLInputElement> & {
  elementRef?: (el: HTMLInputElement) => void;
  feedbackToggle?: () => Promise<void>;
  color?: string;
  iconWidth?: string;
  iconHeight?: string;
  checkBoxNoneReversed?: boolean;
};

type StateType = {
  checked: boolean;
  status: Status;
};

class Toggle extends React.Component<PropType, StateType> {
  mounted = false;
  constructor(props: PropType) {
    super(props);
    this.state = {
      checked: typeof props.checked === 'undefined' ? false : props.checked,
      status: Status.Default,
    };
  }
  componentDidMount() {
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  componentWillReceiveProps(nextProps: PropType) {
    const checked = typeof nextProps.checked === 'undefined' ? this.state.checked : nextProps.checked;
    if (this.props.checked !== checked) {
      this.setState({ checked });
    }
  }
  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.state.status !== Status.Default) {
      return;
    }

    if (this.props.onChange) {
      this.props.onChange(e);
    }

    if (this.props.feedbackToggle) {
      this.setState({ status: Status.Pending });

      this.props
        .feedbackToggle()
        .then(() => {
          if (this.mounted) {
            this.setState({ status: Status.Fulfilled });
          }
        })
        .catch(() => {
          if (this.mounted) {
            this.setState({ status: Status.Rejected });
          }
          setTimeout(() => {
            if (this.mounted) {
              this.setState({ status: Status.Default });
            }
          }, 800);
        })
        .then(() => {
          if (this.mounted) {
            this.setState({ status: Status.Default });
          }
        });
    }

    if (typeof this.props.checked === 'undefined') {
      const checked = (e.target as HTMLInputElement).checked;
      if (checked !== this.state.checked) {
        this.setState({ checked });
      }
    }
  };
  render() {
    const restProps = Object.assign({}, this.props) as PropType;
    delete restProps.children;
    delete restProps.className;
    delete restProps.style;
    delete restProps.color;
    delete restProps.feedbackToggle;
    delete restProps.elementRef;
    delete restProps.iconWidth;
    delete restProps.iconHeight;
    delete restProps.checkBoxNoneReversed;

    //const { color } = this.props;
    let Checked = null;
    let Unchecked = null;
    let type = this.props.type;
    const color = this.props.color ? this.props.color : style.colors.monochrome.black;
    switch (this.props.type) {
      case 'checkbox':
        Checked = (
          <CheckBox
            css={{
              ...(this.props.checkBoxNoneReversed && { marginLeft: '8px', marginRight: 0 }),
              color: color,
              ...(this.props.iconWidth && { width: this.props.iconWidth }),
              ...(this.props.iconHeight && { width: this.props.iconHeight }),
            }}
          />
        );
        Unchecked = (
          <EmptyCheckBox
            css={{
              ...(this.props.checkBoxNoneReversed && { marginLeft: '8px', marginRight: 0 }),
              color: color,
              ':hover': { color: color },
              ...(this.props.iconWidth && { width: this.props.iconWidth }),
              ...(this.props.iconHeight && { width: this.props.iconHeight }),
            }}
          />
        );
        break;
      case 'radio':
        Checked = (
          <CheckedRadio
            css={{
              color: color,
              ...(this.props.iconWidth && { width: this.props.iconWidth }),
              ...(this.props.iconHeight && { width: this.props.iconHeight }),
            }}
          />
        );
        Unchecked = (
          <UncheckedRadio
            css={{
              color: color,
              ':hover': { color: color },
              ...(this.props.iconWidth && { width: this.props.iconWidth }),
              ...(this.props.iconHeight && { width: this.props.iconHeight }),
            }}
          />
        );
        break;
      default: // fallback to checkbox dom element
        // checkmark
        type = 'checkbox';
        Checked = <CheckMark />;
        Unchecked = <Empty />;
        break;
    }

    const status = this.state.status;

    return (
      <label className={this.props.className} style={this.props.style}>
        {this.props.children}
        <Input {...restProps} type={type} onChange={this.onChange} ref={this.props.elementRef} />
        <IconContainer>
          {status === Status.Default && this.state.checked ? Checked : Unchecked}
          <FadeContainer visible={status !== Status.Default}>
            {/*adding text here will zoom in/out accurateley, but icons are not affected...*/}
            {status === Status.Pending && <RelativeSpinner css={{ ...(color && { color: color }) }} />}
            {status === Status.Rejected && <RelativeReject />}
          </FadeContainer>
        </IconContainer>
      </label>
    );
  }
}

export type ToggleType = 'radio' | 'checkbox' | 'checkmark';

function factory(type: ToggleType) {
  return styled(
    applyClassName(
      function LinkElement(props: PropType) {
        const { color } = props;
        return (
          <Toggle {...props} type={type} color={color} />
        );
      }
    ),
    {
      display: 'inline-flex',
      alignItems: 'center',
      flexDirection: type === 'checkbox' ? 'row-reverse' : 'row',
      cursor: 'pointer',
    },
  );
}

export const Radio = factory('radio');

export const Checkbox = factory('checkbox');

export const Checkmark = factory('checkmark');

import React from 'react';
import connect from 'Shared/connect';
import { openAccountBox, closeAccountBox, AccountBoxScene } from './action-creators';
import LoginModal, { ModalView } from './LoginModal';
import currentPageIsCheckout from 'Checkout/Pages/Checkout/current-page-is-checkout';

type RequiredPropType = {
  isCompact: boolean;
};

type ConnectedStateType = {
  isOpen: boolean;
  scene: AccountBoxScene;
  redirectOnLogin: boolean;
  redirectToAfterLogin: string;
};

type ConnectedDispatchType = {
  openAccountBox: (scene?: AccountBoxScene) => void;
  onClose: () => void;
};

type PropType = ConnectedStateType & ConnectedDispatchType & RequiredPropType;

const AccountBox = (props: PropType) => {
  const { isOpen, scene, redirectOnLogin, redirectToAfterLogin, isCompact, onClose } = props;

  return (
    <LoginModal
      isOpen={isOpen}
      hideModal={onClose}
      startModalView={scene === AccountBoxScene.Register ? ModalView.Register : ModalView.Login}
      redirectOnLogin={redirectOnLogin}
      redirectToAfterLogin={redirectToAfterLogin}
      isCompact={isCompact}
    />
  );
};

export default connect(
  (state): ConnectedStateType => ({
    isOpen: state.currentUser.loginMenuVisible,
    scene: state.currentUser.scene,
    redirectOnLogin: !currentPageIsCheckout(state.currentPage),
    redirectToAfterLogin: state.currentUser.redirectToAfterLogin,
  }),
  (dispatch): ConnectedDispatchType => ({
    onClose: () => dispatch(closeAccountBox()),
    openAccountBox: (scene?: AccountBoxScene) => dispatch(openAccountBox(scene)),
  }),
)(AccountBox);

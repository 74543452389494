import {
  PAGE_LOAD,
  PAGE_LOAD_CACHED,
  PAGE_LOAD_DONE,
  PAGE_LOAD_SUCCESS,
} from '@polarnopyret/scope/CurrentPage/action-creators';
import currentPageIsVariation from './current-page-is-variation';
import { PageType, Action } from 'Shared/State';

export default function (state: PageType = null, previousState: PageType, action: Action): PageType {
  if (currentPageIsVariation(state) && currentPageIsVariation(previousState)) {
    switch (action.type) {
      case PAGE_LOAD:
      case PAGE_LOAD_CACHED:
      case PAGE_LOAD_DONE:
      case PAGE_LOAD_SUCCESS:
        if (state && previousState) {
          // only reset scroll if we click on a 'new' product, navigating inside a product 'family' should keep the current scroll position.
          const code = state.code;
          const prevCode = previousState.code;

          const newCode = code && prevCode && code.split('_')[0] !== prevCode.split('_')[0];
          const newVariationFamily =
            !state.variations ||
            state.variations.filter(variation => variation.url === previousState.url).length === 0;
          // const newSizeFamily =
          //   !previousState.relatedProducts ||
          //   (state.url !== previousState.relatedProducts.bigger &&
          //     state.url !== previousState.relatedProducts.smaller);

          //if (newCode && newVariationFamily && newSizeFamily) {
          if (newCode && newVariationFamily) {
            return state;
          }

          // if (state.variation && previousState.variation) {
          //   // only reset scroll if we click on a 'new' product, navigating inside a product 'family' should keep the current scroll position.
          //   const code = epiPropertyValue(state.variation.code);
          //   const prevCode = epiPropertyValue(previousState.variation.code);

          //   const newCode = code && prevCode && code.split('_')[0] !== prevCode.split('_')[0];
          //   const newVariationFamily =
          //     !state.variations ||
          //     state.variations.filter(variation => variation.url === previousState.url).length === 0;
          //   // const newSizeFamily =
          //   //   !previousState.relatedProducts ||
          //   //   (state.url !== previousState.relatedProducts.bigger &&
          //   //     state.url !== previousState.relatedProducts.smaller);

          //   //if (newCode && newVariationFamily && newSizeFamily) {
          //     if (newCode && newVariationFamily) {
          //     return state;
          //   }
          // }
        }
        return {
          ...(state as PageType),
          scrollPosition: undefined,
        };
      default:
        return state;
    }
  }
  return state;
}

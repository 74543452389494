import { PAGE_LOAD_DONE } from '@polarnopyret/scope';
import { QuickSearchType } from 'Shared/State';
import { PageType, Action } from 'Shared/State';
import {
  HIDE_QUICKSEARCH,
  SHOW_QUICKSEARCH,
  UPDATE_QUICKSEARCH_TEXT,
  UPDATE_QUICKSEARCH_COMPLETIONS,
  UPDATE_EMPTYSEARCH,
  UPDATE_SELECTED_QUICKSEARCH_COMPLETION,
  UpdateQuicksearchTextAction,
  UpdateQuicksearchCompletionsAction,
  UpdateEmptySearchCompletionsAction,
  UpdateSelectedQuicksearchCompletionAction,
} from './action-creators';
import QuicksearchViewModelType from './QuicksearchViewModel.type';

export default function (state: QuickSearchType = null, action: Action, currentPage: PageType): QuickSearchType {
  if (state === null) {
    state = {
      isOpen: false,
      searchText: '',
      suggestion: null,
      quicksearchLists: {
        results: [],
        products: [],
        hasMoreProductHits: false,
        didYouMean: null,
        contentResultSnippets: [],
        labels: null
      },
      emptySearchLists: {
        recentSearches: [],
        suggestions: [],
        products: [],
        labels: null
      },
      selectedCompletionIndex: null,
      originalSearchTerm: null,
    };
  }
  switch (action.type) {
    case HIDE_QUICKSEARCH: {
      return Object.assign({}, state, {
        isOpen: false,
        searchText: '',
        suggestion: null,
        quicksearchLists: null,
        emptySearchLists: null,
      });
    }
    case SHOW_QUICKSEARCH: {
      return Object.assign({}, state, { isOpen: true });
    }
    case PAGE_LOAD_DONE: {
      return {
        ...state,
        searchText: '',
        suggestion: null,
        quicksearchLists: null,
        emptySearchLists: null,
        isOpen: false,
      };
    }
    case UPDATE_SELECTED_QUICKSEARCH_COMPLETION: {
      const index = (action as UpdateSelectedQuicksearchCompletionAction).index;

      const text =
        index !== null
          ? getCompletionAtIndex(index, state.quicksearchLists)
          : state.originalSearchTerm || state.searchText;

      const suggestion = getQuerySuggestion(text, state.quicksearchLists);

      return Object.assign({}, state, {
        selectedCompletionIndex: index,
        searchText: text,
        suggestion: suggestion || text,
      });
    }

    case UPDATE_QUICKSEARCH_TEXT: {
      const { searchText } = action as UpdateQuicksearchTextAction;
      const suggestion = getQuerySuggestion(searchText, state.quicksearchLists);

      return Object.assign({}, state, {
        searchText,
        suggestion,
        selectedCompletionIndex: null,
        originalSearchTerm: searchText,
      });
    }
    case UPDATE_QUICKSEARCH_COMPLETIONS: {
      const completionsAction = action as UpdateQuicksearchCompletionsAction;
      const newState = Object.assign({}, state, {
        quicksearchLists: completionsAction.quicksearchLists,
      });
      return newState;
    }
    case UPDATE_EMPTYSEARCH: {
      const completionsAction = action as UpdateEmptySearchCompletionsAction;
      const newState = Object.assign({}, state, {
        emptySearchLists: completionsAction.emptySearchLists,
      });
      return newState;
    }
    default:
      break;
  }
  return state;
}

export function getQuerySuggestion(currentQuery: string, quicksearchLists: QuicksearchViewModelType) {
  const res =
    quicksearchLists && quicksearchLists.results && quicksearchLists.results.filter(list => list.result.length > 0);

  if (!res || res.length === 0) {
    return null;
  }

  const firstNonEmptyList = res[0].result;

  if (!firstNonEmptyList || firstNonEmptyList.length === 0) {
    return null;
  }

  const firstAutocomplete = firstNonEmptyList[0].text;

  if (!firstAutocomplete || !currentQuery || currentQuery[currentQuery.length - 1] === ' ') {
    return currentQuery;
  }

  const currentWords = currentQuery.split(' ').filter(s => !!s);
  const autocompleteWords = firstAutocomplete.split(' ').filter(s => !!s);

  if (currentWords.length > autocompleteWords.length) {
    return currentQuery;
  }

  const lastIndex = currentWords.length - 1;
  for (let i = 0; i < lastIndex; i++) {
    if (currentWords[i].toLowerCase() !== autocompleteWords[i].toLowerCase()) {
      return currentQuery;
    }
  }

  if (!autocompleteWords[lastIndex].toLowerCase().startsWith(currentWords[lastIndex].toLowerCase())) {
    return currentQuery;
  }

  return currentQuery + autocompleteWords[lastIndex].substr(currentWords[lastIndex].length);
}

function getCompletionAtIndex(index: number, quicksearchLists: QuicksearchViewModelType): string {
  if (index == null) {
    return '';
  }

  let idx = -1;
  for (const result of quicksearchLists.results) {
    for (const item of result.result) {
      idx++;
      if (idx === index) {
        return item.text;
      }
    }
  }
  return '';
}

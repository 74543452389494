export enum GridSize {
  XS = 'xs',
  LG = 'lg',
}

export const createGridClasses = (mobile: number, desktop: number, additionalClasses: string = '') => {
  return (
    'grid__cell ' +
    (mobile > 0 ? 'size-xs-' + mobile + ' ' : '') +
    (desktop > 0 ? 'size-lg-' + desktop + ' ' : '') +
    additionalClasses
  );
};

export const createCenterGridClass = (start: number, stop: number, size: GridSize) => {
  return 'center-' + size.toString().toLowerCase() + '-' + start + '-' + stop;
};

export const createOffsetGridClass = (nrCols: number, size: GridSize) => {
  return 'offset-' + size.toString().toLowerCase() + '-' + nrCols;
};

export const createOffsetGridClassLarge = (nrCols: number) => {
  if(nrCols === 0) {
    return '';
  }
  
  return 'offset-lg-' + nrCols;
};

export const createOffsetGridClassTiny = (nrCols: number) => {
  return 'offset-xs-' + nrCols;
};

export const fillGridBins = (totalColumns: number, numberOfBins: number, index: number, startOffset: number = 1) => {
  return createGridClasses(
    12,
    totalColumns / numberOfBins,
    createOffsetGridClassLarge(startOffset + (totalColumns / numberOfBins) * (index % numberOfBins)),
  );
};

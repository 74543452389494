import State, { Action, Store } from 'Shared/State';
import { currentUrl } from '@polarnopyret/scope';

export const ACCOUNT_BOX_OPEN = 'ACCOUNT_BOX_OPEN';
export const ACCOUNT_BOX_CLOSE = 'ACCOUNT_BOX_CLOSE';
export const ACTION_REQUIRES_LOGIN = 'ACTION_REQUIRES_LOGIN';

export enum AccountBoxScene {
  Login,
  ForgotPassword,
  Register,
}

export type OpenAccountBoxAction = Action & {
  scene: AccountBoxScene;
  message?: string;
  redirectToAfterLogin?: string;
};

export const openAccountBox = (
  scene: AccountBoxScene = AccountBoxScene.Login,
  message: string = null,
  redirectToAfterLogin?: string,
): OpenAccountBoxAction => {
  return {
    type: redirectToAfterLogin ? ACTION_REQUIRES_LOGIN : ACCOUNT_BOX_OPEN,
    scene,
    message,
    redirectToAfterLogin,
  };
};

export const closeAccountBox = (): Action => {
  return {
    type: ACCOUNT_BOX_CLOSE,
  };
};

export function openLoginBoxIfLoginRequired(store: Store) {
  const returnUrlQueryParam = 'returnurl';
  const loginQuery = 'login';
  const url = currentUrl();

  const returnUrl = url.searchParams.get(returnUrlQueryParam) || null;
  const showLogin = url.searchParams.has(loginQuery);

  const isLoggedIn = (store.getState() as State).currentUser.isLoggedIn;

  // So this is quite icky, but since Shared/Modal uses componentDidMount to get the DOM node to create a portal
  // for we will end up firing this action before componentDidMount happens. Which means that Shared/Modal won't
  // render anything (yet).
  setTimeout(() => {
    if (!isLoggedIn) {
      if (returnUrl) {
        store.dispatch(openAccountBox(AccountBoxScene.Login, '', returnUrl));
      } else if (showLogin) {
        store.dispatch(openAccountBox(AccountBoxScene.Login, ''));
      }
    }
  }, 0);
}

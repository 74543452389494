import { styled } from '@glitz/react';
import React from 'react';
import { loadPage, URLX } from '@polarnopyret/scope';
import { QuickSearchType } from 'Shared/State';
import SearchPageViewModelType from 'Search/SearchPageViewModel.type';
import connect from 'Shared/connect';
import {
  hideQuickSearch,
  loadQuickSearch,
  MIN_SEARCH_LENGTH,
  showQuickSearch,
  updateSelectedCompletion,
} from 'Search/action-creators';
import currentPageIsSearch from 'Search/current-page-is-search';
import { SolidButton, ButtonTheme } from 'Shared/TextButtons';
import ProductListItemViewModel from 'Product/ProductListing/ProductListItemViewModel.type';
import NewProductCard from 'Product/ProductCard';
import Modal, { ModalScrollableContent } from 'Shared/SharedComponents/atoms/Modal';
import SwiperList from 'Shared/SwiperList';
import QuickSearchResultArticleCard from './QuickSearchResultArticleCard';
import { EmptyResult, SearchSoftHeading } from './shared';
import SnippetList from './SnippetList';
import SuggestionList from './SuggestionList';
import RecentSearches from './RecentSearches';
import RecentProducts from './RecentProducts';
import CategoryList from './CategoryList';

type ConnectedPropType = {
  currentBreakpoint: number;
  currentPageIsSearch: boolean;
  quickSearch?: QuickSearchType;
  placeHolder: string;
};

type ConnectedDispatchType = {
  loadPage: (url: URLX, showSpinner: boolean, shouldUseResult?: (page: SearchPageViewModelType) => boolean) => void;
  loadQuickSearch: (searchText: string) => void;
  hideQuickSearch: (setFirstSuggestionAsSearchText?: boolean) => void;
  showQuickSearch: () => void;
  updateSelectedCompletion: (index: number) => void;
};

const MobileEmptyResults = styled(EmptyResult, {
  marginBottom: '32px'
})

const ModalScrollableContentMobileResultsContainer = styled(ModalScrollableContent, {
  paddingTop: '12px',
})

const MobileResultsButtonWrap = styled.div({
  width: '100%'
})

const MobileResultsButton = styled(SolidButton, {
  marginBottom: '12px'
})

const MobileSearchResults = connect(
  (state): ConnectedPropType => ({
    currentBreakpoint: state.currentBreakpoint,
    currentPageIsSearch: currentPageIsSearch(state.currentPage),
    quickSearch: state.quickSearch,
    placeHolder: state.appShellData.header.quickSearchPlaceHolder,
  }),
  (dispatch): ConnectedDispatchType => ({
    loadPage(url: URLX, showSpinner: boolean, shouldUseResponse?: (page: SearchPageViewModelType) => boolean) {
      dispatch(loadPage({ url, options: { hideSpinner: !showSpinner }, shouldUseResponse }));
    },
    loadQuickSearch(searchText: string) {
      dispatch(loadQuickSearch(searchText));
    },
    hideQuickSearch(setFirstSuggestionAsSearchText = true) {
      dispatch(hideQuickSearch(setFirstSuggestionAsSearchText));
    },
    showQuickSearch() {
      dispatch(showQuickSearch());
    },
    updateSelectedCompletion(index: number) {
      dispatch(updateSelectedCompletion(index));
    },
  }),
)(
  (
    props: ConnectedPropType &
      ConnectedDispatchType & {
        showResults: boolean;
        loadResults: (useSuggestion: boolean) => void;
      },
  ) => {
    const { quickSearch, showResults, loadResults, hideQuickSearch } = props;
    const activeSearch = (quickSearch?.searchText ?? '').length > MIN_SEARCH_LENGTH;
    const resultsAreEmpty = activeSearch && !showResults;
    const initialSearch = quickSearch?.emptySearchLists;
    
    const suggestions = quickSearch?.quicksearchLists?.results
    ?.find((e) => e.id === 'completions' && activeSearch)
    ?.result.map(x => x.text) ?? initialSearch?.suggestions;
    
    const categories = quickSearch?.quicksearchLists?.results
    ?.find((e) => e.id === 'categories' && activeSearch)
    ?.result.map(x => ({ name: x.text, url: x.url })) ?? [];

    return (
      <Modal modalIsShown={quickSearch?.isOpen ?? false} hideModal={() => hideQuickSearch()} isOnLeft>
        <ModalScrollableContentMobileResultsContainer>
          {quickSearch?.isOpen && (
            <>
              {initialSearch && (
                <>
                  <SnippetList quicksearchLists={quickSearch?.quicksearchLists} isMobile={true} />
                  
                  {resultsAreEmpty && (
                    <MobileEmptyResults>
                      {quickSearch?.quicksearchLists?.labels?.noResultsFor} {`"${quickSearch.searchText}"`}
                    </MobileEmptyResults>
                  )}
                  <SuggestionList suggestions={suggestions} searchText={quickSearch?.searchText} heading={initialSearch?.labels?.suggestions} isMobile={true} />
                  {categories.length > 0 && <CategoryList categories={categories} heading={quickSearch?.quicksearchLists?.labels?.categories} isMobile={false} />}
                  {!showResults && <RecentSearches recentSearches={initialSearch?.recentSearches} heading={initialSearch?.labels?.recentSearches} isMobile={true} />}                          
                  
                  <>
                    {showResults && (
                      <>
                        <div>
                          <SearchSoftHeading>{quickSearch?.quicksearchLists?.labels?.products}</SearchSoftHeading>
                          <SwiperList
                            isCompact={true}
                            spaceBetween={8}
                            slidesPerView={1.5}
                            cssMode={true}
                          >
                            {quickSearch?.quicksearchLists?.products?.map((product, index, array) => (
                              <div key={index + '-' + product.itemNumber} className="swiper-slide">
                                <NewProductCard product={product as ProductListItemViewModel} listIndex={index} />
                              </div>
                            ))}
                          </SwiperList>
                        </div>
                        <div>
                          <SearchSoftHeading>{quickSearch?.quicksearchLists?.labels?.pages}</SearchSoftHeading>
                          <SwiperList
                            isCompact={true}
                            spaceBetween={8}
                            slidesPerView={1.5}
                            cssMode={true}
                          >
                            {quickSearch?.quicksearchLists?.results?.find((e) => e.id === 'cmspages')?.result?.map((e, i, array) => (
                              <div key={i + '-cms'} className="swiper-slide">
                                <QuickSearchResultArticleCard key={i} entry={e} />
                              </div>
                            ))}
                          </SwiperList>
                        </div>
                      </>
                    )}
                  </>

                  {!((quickSearch?.searchText ?? '').length > MIN_SEARCH_LENGTH) && <RecentProducts products={initialSearch?.products} heading={initialSearch?.labels?.recentProducts} isMobile={true} /> }
                </>
              )}
            </>
          )}
        </ModalScrollableContentMobileResultsContainer>
        <MobileResultsButtonWrap>
          {(quickSearch?.searchText ?? '').length > MIN_SEARCH_LENGTH && (
            <MobileResultsButton
              text={quickSearch?.quicksearchLists?.labels?.showResults ?? ''}
              onClick={() => {
                hideQuickSearch(false);
                loadResults(false);
              }}
              disabled={!showResults}
              display="block"
              theme={ButtonTheme.BlackToWhite}
            />
          )}
        </MobileResultsButtonWrap>
      </Modal>
    );
  },
);

export default MobileSearchResults;
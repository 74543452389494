import {
  PAGE_LOAD,
  PAGE_LOAD_FAILURE,
  PageLoadFailureAction,
  UPDATE_APP_SHELL_DATA,
  UpdateAppShellDataAction,
  PAGE_LOAD_DONE,
} from '@polarnopyret/scope';
import AppShellDataType from 'AppShell/AppShellContextData.type';
import { CurrentUserType, Action } from 'Shared/State';
import {
  AccountBoxScene,
  ACCOUNT_BOX_OPEN,
  ACCOUNT_BOX_CLOSE,
  ACTION_REQUIRES_LOGIN,
  OpenAccountBoxAction,
} from 'SiteLayout/AccountBox/action-creators';
import { PageType } from 'Shared/State';
import CurrentPageIsRegisterPage from './Register/current-page-is-register';

export default function(
  state: CurrentUserType = null,
  action: Action,
  appShellData: AppShellDataType,
  page: PageType,
): CurrentUserType {
  state = state || { ...appShellData.currentUser, loginMenuVisible: false, scene: AccountBoxScene.Login };

  switch (action.type) {
    case PAGE_LOAD: {
      return state;
    }

    case PAGE_LOAD_FAILURE:
      if ((action as PageLoadFailureAction).loadFailure.status === 401 && state.isLoggedIn) {
        // The user has either been logged out because of inactivity or the account has been
        // locked out
        return Object.assign({}, state, {
          isLoggedIn: false,
        });
      }
      return state;

    case UPDATE_APP_SHELL_DATA:
      const updateAppShellDataAction = action as UpdateAppShellDataAction<AppShellDataType>;
      return {
        ...state,
        ...updateAppShellDataAction.appShellData.currentUser,
      };

    case ACCOUNT_BOX_OPEN: {
      const openAction = action as OpenAccountBoxAction;
      return { ...state, loginMenuVisible: true, scene: openAction.scene, message: openAction.message };
    }

    case ACCOUNT_BOX_CLOSE:
      return { ...state, loginMenuVisible: false };

    case ACTION_REQUIRES_LOGIN: {
      const openAction = action as OpenAccountBoxAction;
      return {
        ...state,
        loginMenuVisible: true,
        scene: openAction.scene,
        message: openAction.message,
        redirectToAfterLogin: openAction.redirectToAfterLogin,
      };
    }

    case PAGE_LOAD_DONE: {
      if (state.loginMenuVisible && (state.isLoggedIn || CurrentPageIsRegisterPage(page))) {
        return { ...state, loginMenuVisible: false };
      } else {
        return state;
      }
    }

    default:
      return state;
  }
}

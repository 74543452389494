import React from 'react';
import { styled, StyledProps, StyledComponent } from '@glitz/react';
import {Spinner8x8, Spinner12x12, Spinner16x16 } from '../Icons/Spinner';
import { Check8x8, Check12x12, Check16x16 } from '../Icons/Check';
import {Error8x8, Error12x12, Error16x16 } from '../Icons/Error';
import timeout from '../timeout';
import * as style from '../Style';
import { SolidButton, SolidButtonProps } from 'Shared/TextButtons';

/* type ButtonPropType = {
  theme: ButtonTheme;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;
 */
export { ButtonTheme } from 'Shared/TextButtons';

enum Status {
  Default,
  Pending,
  Fulfilled,
  Rejected,
}

export enum Behavior {
  /** Always clickable. Will revert back from fulfilled to idle. Default */
  KeepEnabled,
  /** Only clickable while idle, will never leave fulfilled state once reached.  */
  SingleSuccess,
  /** Only clickable while idle or fulfilled. Will revert back from fulfilled to idle */
  MultipleSuccesses,
}

type OptionType = {
  minimumPending?: number;
  maximumFulfilled?: number;
  maximumRejected?: number;
  behavior?: Behavior;
};

type FeedbackFuncType = (asyncOperation: Promise<string | void>) => any;

type FeedbackType = {
  status: Status;
  text: string | void;
};

export type ConnectFeedbackButtonType = StyledComponent<FeedbackButtonPropType>;

export type ConnectPropType = {
  feedback: {
    push: FeedbackFuncType;
    Button: StyledComponent<FeedbackButtonPropType>;
  };
};

type LayerPropType = {
  visible: boolean;
};

type FeedbackButtonPropType = SolidButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement> & {feedBackSize?: number, successText?: string, failedText?: string, pendingText?: string};

type FeedbackSlavePropType = StyledProps & FeedbackButtonPropType & {
    feedback: FeedbackType;

  };

type ContextType = {
  status: Status;
  statusText: string | void;
  enabled: boolean;
};

type FeedbackButtonComponentPropType = Pick<
  FeedbackButtonPropType,
  Exclude<keyof FeedbackButtonPropType, 'onClick'>
> & {
  onClick: (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => Promise<any>;
};

type FactoryType = StyledProps & FeedbackButtonComponentPropType & ConnectPropType;

function factory<TProps>(options: OptionType) {
  return styled(
    connectWithFeedback(options)(
      class FeedbackButton extends React.Component<FactoryType> {
        onClick = (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
          if (this.props.onClick) {
            this.props.feedback.push(this.props.onClick(e));
          }
        };
        render() {
          const { feedback, compose, ...restProps } = this.props;
          return <feedback.Button {...restProps} onClick={this.onClick} css={compose()} />;
        }
      },
    ),
  );
}

export default factory<React.Component<FactoryType>>({ behavior: Behavior.KeepEnabled });

export function connectWithFeedback(options: OptionType = {}) {
  return <TInnerProps extends ConnectPropType>(Component: React.ComponentType<TInnerProps>) => {
    const {
      minimumPending = 500,
      maximumFulfilled = options.behavior === Behavior.SingleSuccess ? Infinity : 2000,
      maximumRejected = 2000,
      behavior = Behavior.KeepEnabled,
    } = options;

    const defaultFeedbackStatus: ContextType = {
      status: Status.Default,
      statusText: null,
      enabled: true,
    };

    const { Provider, Consumer } = React.createContext(defaultFeedbackStatus);
    

    const FeedbackSlave = styled(
      ({ compose, feedback, children, theme, iconAfter, iconBefore, feedBackSize = 24, successText, failedText, pendingText, disabled, text, ...restProps }: FeedbackSlavePropType) => (
        <Consumer>
          {({ status, statusText, enabled }) => {
            const isDefault = status === Status.Default;
            const isPending = status === Status.Pending;
            const isFulFilled = status === Status.Fulfilled;
            const isRejected = status === Status.Rejected;
            
            const CheckIcon = feedBackSize < 9 ? Check8x8 : (feedBackSize < 13 ? Check12x12 : Check16x16);
            const RejectedIcon = feedBackSize < 9 ? Error8x8 : (feedBackSize < 13 ? Error12x12 : Error16x16);
            const Spinner = feedBackSize < 9 ? Spinner8x8 : (feedBackSize < 13 ? Spinner12x12 : Spinner16x16);

            if(!isDefault) {
              if(isFulFilled)
              {
                statusText = (typeof failedText === 'string') && successText ? successText : text;
                text = (typeof statusText === 'string') && statusText ? '' : text;
                iconAfter = <CheckIcon 
                  css={{ 
                    fill: 'currentColor',
                    ...((typeof statusText === 'string') && statusText && {
                        marginLeft: '6px',
                      }
                    ),
                  }} 
                />;
                iconBefore = null;
              }
              else if(isRejected)
              {
                statusText = (typeof failedText === 'string') && failedText ? failedText : text;
                text = (typeof statusText === 'string') && statusText ? '' : text;
                iconAfter = <RejectedIcon css={{ fill: 'currentColor', ...((typeof statusText === 'string') && statusText && { marginLeft: '6px' }) }} />;
                iconBefore = null;
              }
              else if(isPending)
              {
                statusText = (typeof pendingText === 'string') && pendingText ? pendingText : text;
                text = (typeof statusText === 'string') && statusText ? '' : text;
                iconAfter = <Spinner css={{ fill: 'currentColor', ...((typeof statusText === 'string') && statusText && { marginLeft: '6px' }) }} />;
                iconBefore = null;
              }
            }
            
            const buttonStatus = disabled ? true : !enabled;
            return (
              <SolidButton
                asButton
                disabled={buttonStatus}
                css={compose({
                  position: 'relative',
                })}
                iconAfter={iconAfter}
                iconBefore={iconBefore}
                theme={theme}
                text={text}
                {...restProps}
              >
                {isDefault && children}
                {!isDefault && (typeof statusText === 'string') && statusText}
              </SolidButton>
            );
          }}
        </Consumer>
      ),
    );

    return class FeedbackConnector extends React.Component<
      Pick<TInnerProps, Exclude<keyof TInnerProps, keyof ConnectPropType>>,
      ContextType
    > {
      mounted: boolean;
      asyncOperationQueue: Promise<any> = Promise.resolve();
      state = defaultFeedbackStatus;
      componentDidMount() {
        this.mounted = true;
      }
      componentWillUnmount() {
        this.mounted = false;
      }
      push = async (asyncOperation: Promise<string | void>) => {
        const queue = (this.asyncOperationQueue = Promise.all([asyncOperation, this.asyncOperationQueue]));
        const isLastOperation = () => queue === this.asyncOperationQueue;

        if (this.mounted) {
          if (this.state.status !== Status.Pending) {
            this.setState({
              status: Status.Pending,
              enabled: behavior === Behavior.KeepEnabled,
            });
          }

          const setStatus = async (statusState: ContextType, resetState: ContextType, maximumDisplayed: number) => {
            if (maximumDisplayed > 0 && this.mounted) {
              this.setState(statusState);
            }

            if (maximumDisplayed < Infinity) {
              await timeout(maximumDisplayed);

              if (isLastOperation() && this.mounted) {
                this.setState(resetState);
              }
            }
          };

          // Sometimes you want the spinner to be visible so the user has a chance
          // to notice that something happen. Studies shows that users today expects
          // that things like this take some time. So if it's to quick they
          // assume that something went wrong. I know... stupid... right?
          const minimumPendingTimer = timeout(minimumPending);

          try {
            const [[statusText]] = [await queue, await minimumPendingTimer];
            if (isLastOperation()) {
              const enabled = behavior !== Behavior.SingleSuccess;
              await setStatus(
                { status: Status.Fulfilled, statusText, enabled },
                { ...defaultFeedbackStatus, enabled },
                maximumFulfilled,
              );
            }
          } catch (statusText) {
            if (isLastOperation()) {
              await setStatus({ status: Status.Rejected, statusText, enabled: true }, defaultFeedbackStatus, maximumRejected);
            }
          }
        }

        if (isLastOperation()) {
          this.asyncOperationQueue = Promise.resolve();
        }

        return Promise.resolve();
      };
      // tslint:disable-next-line member-ordering
      feedback = { push: this.push, Button: FeedbackSlave };
      render() {
        return (
          <Provider value={this.state}>
            <Component {...(this.props as any)} feedback={this.feedback} />
          </Provider>
        );
      }
    };
  };
}

const Text = styled(({ compose, visible, ...restProps }: StyledProps & LayerPropType) => (
  <styled.Span
    {...restProps}
    css={compose({
      display: 'block',
      ...style.truncate(),
      // There's a bug in Chrome that doesn't change the appearance of text color when
      // the browsers is performance optimizing the element and is completely hidden, that's
      // why the value is set to `0.01`
      opacity: visible ? 1 : 0.01,
      transform: visible ? 'scale(1)' : 'scale(0.01)',
      ...style.transition({ property: ['opacity', 'transform'] }),
    })}
  />
));

const Layer = styled(Text, {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  right: 0,
  left: 0,
  textAlign: 'center',
});

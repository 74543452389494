import { Style } from '@glitz/type';

const DEFAULT_DURATION = '200ms';
const DEFAULT_TIMING_FUNCTION = 'ease-out';

type TransitionPropType = Style['transition'] & { willChange?: boolean };

export function transition(props: TransitionPropType): Style {
  const { property, duration = DEFAULT_DURATION, timingFunction = DEFAULT_TIMING_FUNCTION, delay, willChange } = props;
  const properties = Array.isArray(property) ? property.join(', ') : property;
  return {
    transition: {
      property: properties,
      duration,
      timingFunction,
      delay,
    },
    ...(willChange && {
      willChange: properties,
    }),
  };
}

type AnimationPropType = Style['animation'];

export function animation(props: AnimationPropType): Style {
  const {
    name,
    duration = DEFAULT_DURATION,
    timingFunction = DEFAULT_TIMING_FUNCTION,
    delay,
    iterationCount,
    direction,
    fillMode,
    playState,
  } = props;
  return {
    animation: {
      name,
      duration,
      timingFunction,
      delay,
      iterationCount,
      direction,
      fillMode,
      playState,
    },
  };
}

import React from 'react';
import { styled, StyledProps, applyClassName, StyledElementProps } from '@glitz/react';
import appearance, { AppearanceType } from 'Shared/appearance';
import * as style from 'Shared/Style';

export enum Appearance {
  Gap
}

// export enum Layout {
//   OneToOne,
//   TwoToThree,
//   ThreeToTwo,
//   OneToThree,
//   OneToFive,
//   ThreeToOne,
// }

// type LayoutPropType = {
//   fraction: number;
//   order: number;
// };

// Gives a layout based on visual order for wider windows like desktop,
// but sorts columns by ratio descendingly so the greatest ratio will be
// the first child and the less ratio will be the last
//
// E.g. `[3, 5, 2]` will display a layout like:
// ________________________
// |30%___|50%_______|20%_|
//
// But DOM order and visual order för narrower windows will be:
// ________________________
// |50%_______|30%___|20%_|
// function entity(layout: number[]): LayoutPropType[] {
//   const total = layout.reduce((a, b) => a + b, 0);
//   return layout
//     .map((ratio, order) => ({
//       order,
//       fraction: ratio / total,
//     }))
//     .sort((a, b) => b.fraction - a.fraction);
// }

// const entities = {
//   [Layout.OneToOne]: entity([1, 1]),
//   [Layout.TwoToThree]: entity([2, 3]),
//   [Layout.ThreeToTwo]: entity([3, 2]),
//   [Layout.OneToThree]: entity([1, 3]),
//   [Layout.OneToFive]: entity([1, 5]),
//   [Layout.ThreeToOne]: entity([3, 1]),
// };

const SPACING = '12px';
const LARGE_SPACING = '16px';
const LAYOUT_BREAKPOINT = '@media ' + style.mediaMinLarge;

// const Child: React.StatelessComponent<LayoutPropType> = ({ fraction, order, children }) => (
//   <styled.Div
//     css={{
//       [LAYOUT_BREAKPOINT]: {
//         width: `calc(${fraction * 100}% - (${SPACING} - ${SPACING} * ${fraction}))`,
//         order,
//       },
//     }}
//   >
//     {children}
//   </styled.Div>
// );

type PropType<TElement> = {
  appearance?: AppearanceType<Appearance>;
  //layout?: Layout;
  elementRef?: React.Ref<TElement>;
};

export function factory<TElement extends HTMLElement>(tagName: string, defaults: AppearanceType<Appearance> = []) {
  type FactoryPropType = PropType<TElement> & React.HTMLAttributes<TElement>;

  const Base = styled(
    applyClassName(
      class Layout extends React.Component<FactoryPropType & StyledElementProps> {
        render() {
          const {
            // tslint:disable-next-line:no-unused-variable
            appearance,
            //layout,
            elementRef,
            children,
            ...restProps
          } = this.props;

          //let passChildren = children;
          // if (typeof this.props.layout === 'number') {
          //   const entity = entities[layout];
          //   passChildren = React.Children.map(this.props.children, (child, i) => {
          //     if (entity[i]) {
          //       return <Child {...entity[i]}>{child}</Child>;
          //     }
          //     if (__DEV__) {
          //       console.warn(
          //         `Child index ${i} was not rendered because it exceeded the number of allowed children of ${entity.length}`,
          //       );
          //     }
          //   });
          // }
          return React.createElement(tagName, { ...restProps, ref: elementRef }, children);
        }
      },
    ),
  );

  return styled(({ compose, ...restProps }: FactoryPropType & StyledProps) => {
    const appear = appearance(([] as Appearance[]).concat(defaults, restProps.appearance));

    return (
      <Base
        {...restProps}
        css={compose({
          margin: { x: 'auto' },
          ...(appear(Appearance.Gap)
            ? {
                width: `calc(100% - ${SPACING} * 2)`,
                 [LAYOUT_BREAKPOINT]: {
                  width: `calc(100% - ${LARGE_SPACING} * 2)`,
                },
              }
            : {
                width: '100%',
              }),
          // ...(typeof restProps.layout === 'number' && {
          //   [LAYOUT_BREAKPOINT]: {
          //     display: 'flex',
          //     justifyContent: 'space-between',
          //     /* Spacing seems to be swallow when merging media queries
          //      * Hence add it back when it suppose to have space
          //      */
          //     ...(appear(Appearance.Gap) && {
          //       width: `calc(100% - ${LARGE_SPACING} * 2)`,
          //     }),
          //   },
          // }),
        })}
      />
    );
  });
}

export const Basic = factory<HTMLDivElement>('div');
export const GridWrap = factory<HTMLDivElement>('div', Appearance.Gap);
export const Footer = factory<HTMLDivElement>('footer');
export const Section = factory('section');
export const Article = factory('article');
export const Form = factory<HTMLFormElement>('form');
export const Main = factory('main');

import React from 'react';
import { styled } from '@glitz/react';
import Button, { Appearance as ButtonAppearance } from 'Shared/Button';
import { Search24x24 } from "Shared/Icons/Search";
import * as style from 'Shared/Style';
import { ANIMATION_DURATION } from 'SiteLayout/Header/DesktopHeader';

export const DESKTOP_HEIGHT = 38; // Since this primarily relates to QuickSearch, it's placed here.

export const QUICKSEARCH_INNER = 45;
export const QUICKSEARCH_PADDING = 10;
export const QUICKSEARCH_BORDERS = 2;
export const QUICKSEARCH_HEIGHT = QUICKSEARCH_INNER + QUICKSEARCH_BORDERS;

const IconButton = styled(Button, {
  ...style.transition({ property: ['opacity'], duration: `${ANIMATION_DURATION}ms` }),
});

export const MobileMagnifierIcon = ({
  onClick,
  invisible,
  textColor,
}: {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  invisible?: boolean;
  textColor: string;
}) => (
  <IconButton
    appearance={ButtonAppearance.Bare}
    onClick={onClick}
    css={{
      ...(invisible ? { opacity: 0 } : { opacity: 1 }),      
    }}
  >
    <Search24x24 css={{ color: textColor, }} />
  </IconButton>
);

import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { ButtonTheme, SolidButton } from 'Shared/TextButtons';
import { clearCacheAndUnregisterServiceWorker } from '@polarnopyret/scope';
import { startPage } from 'Shared/known-urls';
import { translate } from '@polarnopyret/scope';
import { H1, Paragraph, ResponsiveSize } from 'Shared/SharedComponents/atoms/Typography';

type StateType = {
  hasError: boolean;
  reRendered: boolean;
  errorAppearedOn: string;
};

type RequiredPropType = {
  reloadCurrentPage?: boolean;
  standalone?: boolean;
};

type PropType = RequiredPropType & StyledProps;

const Center = styled.div({
  padding: {
    y: '48px',
  },
  margin: {
    x: 'auto',
  },
  width: '100%',
  textAlign: 'center',
  maxWidth: '800px',
});

const TextContainer = styled.div({
  marginBottom: '32px',
});

const InformationText = styled(Paragraph, {
  marginTop: '32px'
})

export default styled(
  class ErrorBoundary extends React.Component<PropType, StateType> {
    state = {
      hasError: false,
      errorAppearedOn: '',
      reRendered: false,
    };

    onRetryClick = () => {
      this.setState({ reRendered: true, hasError: false });
    };

    componentWillReceiveProps(nextProps: PropType) {
      if (this.state.hasError && this.state.errorAppearedOn !== window.location.href) {
        this.setState({ hasError: false, reRendered: false });
      }
    }

    componentDidCatch(error: any, info: any) {
      if (this.state.reRendered) {
        clearCacheAndUnregisterServiceWorker().then(() => {
          if (this.props.reloadCurrentPage) {
            window.location.reload();
          } else {
            window.location.href = startPage() || '/'; // fallback if missing.
          }
        });
      } else {
        this.setState({ hasError: true, errorAppearedOn: window.location.href });
      }
    }

    render() {
      if (this.state.hasError) {
        if (this.props.standalone) {
          return (
            <div style={{ textAlign: 'center' }}>
              <div style={{ margin: '50px auto' }}>
                <h1>{translate('/site/errors/Unknown')}</h1>
                <span>{translate('/site/errors/UnknownLoadFailureText')}</span>
              </div>
              <button onClick={this.onRetryClick}>{translate('/site/errors/Retry')}</button>
            </div>
          );
        }
        return (
          <Center css={this.props.compose()}>
            <TextContainer>
              <H1 fontSize={ResponsiveSize.D32_M20}>{translate('/site/errors/Unknown')}</H1>
              <InformationText>{translate('/site/errors/UnknownLoadFailureText')}</InformationText>
            </TextContainer>
            <SolidButton theme={ButtonTheme.BlackToWhite} onClick={this.onRetryClick}>{translate('/site/errors/Retry')}</SolidButton>
          </Center>
        );
      }
      return this.props.children;
    }
  },
);

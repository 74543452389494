import React from 'react';
import connect from 'Shared/connect';
import { styled, StyledProps } from '@glitz/react';
import { StyledDecorator } from '@glitz/react/types/styled/decorator';
import { Style, StyleOrStyleArray } from '@glitz/type';
import { URLX } from '@polarnopyret/scope';
import { isCompact } from 'Shared/Viewport';
import Link, { isDocumentLink } from 'Shared/Link';
import * as style from 'Shared/Style';

export enum ButtonTheme {
  BlackToWhite,
  TransparentWhite,
  TransparentBlack,
  WhiteToBlack,
  WhiteBorderToBlack,
  WhiteToTransparent,
  RedBorder,
  Green,
  WhiteToGreen,
  LightGrey,
  DarkBlue,
  Orange,
  TransparentDisabled,
  None,
  Empty,
}

enum ColorSchema {
  Transparent,
  Black,
  White,
  None,
  Red,
  Green,
  WhiteToGreen,
  LightGrey,
  DarkBlue,
  Orange,
  Empty,
}

export enum ButtonHeight {
  Default,
  Huge,
  Big,
  Small,
  Video,
  Filter,
  Sale
}

type ButtonHeights = {
  [key: number]: {
    desktop: number;
    mobile: number;
  };
};

const buttonHeights = (): ButtonHeights => ({
  [ButtonHeight.Huge]: {
    desktop: 64,
    mobile: 64,
  },
  [ButtonHeight.Big]: {
    desktop: 48,
    mobile: 48,
  },
  [ButtonHeight.Default]: {
    desktop: 48,
    mobile: 40,
  },
  [ButtonHeight.Small]: {
    desktop: 32,
    mobile: 32,
  },
  [ButtonHeight.Video]: {
    desktop: 40,
    mobile: 40,
  },
  [ButtonHeight.Filter]: {
    desktop: 48,
    mobile: 32,
  },
  [ButtonHeight.Sale]: {
    desktop: 60,
    mobile: 50,
  },
});

type ConnectedProps = {
  currentBreakpoint: number;
};

type SharedProps = {
  disabled?: boolean;
  selected?: boolean;
  disableClickEventsOnSelected?: boolean;
  text?: string;
  iconBefore?: React.ReactNode;
  iconAfter?: React.ReactNode;
  upperCase?: boolean;
  theme?: ButtonTheme;
  display?: string;
  fontSize?: number;
  height?: ButtonHeight;
  noHoverEffect?: boolean;
  fullWidth?: boolean;
  autoHeight?: boolean;
  padding?: { x: number };
  css?: StyledDecorator | StyleOrStyleArray<Style>;
  linkButtonCss?: StyledDecorator | StyleOrStyleArray<Style>;
}

export type LinkButtonProps = Pick<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  Exclude<keyof React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>
> & SharedProps & {
  to: URLX | string | { url: string };
};

export type SolidButtonProps = Pick<
  React.HTMLAttributes<HTMLButtonElement>,
  keyof React.HTMLAttributes<HTMLButtonElement>
> & SharedProps & {
  asButton?: boolean;
  strikeThrough?: boolean;
};

const GetColorSchemeCssClasses = function (
  theme: ButtonTheme,
  disabled: boolean,
): string {

  var primarySchema = ColorSchema.None;
  var secondarySchema = ColorSchema.None;

  switch (theme) {
    case ButtonTheme.BlackToWhite:
      primarySchema = ColorSchema.Black;
      break;
    case ButtonTheme.TransparentWhite:
      primarySchema = ColorSchema.Transparent;
      secondarySchema = ColorSchema.White;
      break;
    case ButtonTheme.TransparentBlack:
      primarySchema = ColorSchema.Transparent;
      secondarySchema = ColorSchema.Black;
      break;
    case ButtonTheme.WhiteToBlack:
      primarySchema = ColorSchema.White;
      break;
    case ButtonTheme.WhiteBorderToBlack:
      primarySchema = ColorSchema.White;
      break;
    case ButtonTheme.WhiteToTransparent:
      primarySchema = ColorSchema.White;
      secondarySchema = ColorSchema.Transparent;
      break;
    case ButtonTheme.RedBorder:
      primarySchema = ColorSchema.Red;
      break;
    case ButtonTheme.Green:
      primarySchema = ColorSchema.Green;
      break;
    case ButtonTheme.LightGrey:
      primarySchema = ColorSchema.LightGrey;
      break;
    case ButtonTheme.DarkBlue:
      primarySchema = ColorSchema.DarkBlue;
      break;
    case ButtonTheme.TransparentDisabled:
      primarySchema = ColorSchema.Transparent;
      break;
    case ButtonTheme.Orange:
      primarySchema = ColorSchema.Orange;
      break;
    case ButtonTheme.None:
      primarySchema = ColorSchema.None;
      break;
    case ButtonTheme.Empty:
      primarySchema = ColorSchema.Empty;
      break;
  }

  let cssClassName: string[] = [];

  switch (primarySchema) {
    case ColorSchema.Transparent:
      // transparent to white
      cssClassName.push('transparent');
      if (secondarySchema === ColorSchema.Black) {
        cssClassName.push('black');
      }
      break;
    case ColorSchema.Black:
      cssClassName.push('black');
      break;
    case ColorSchema.Red:
      cssClassName.push('red');
      break;
    case ColorSchema.Green:
      cssClassName.push('green');
      break;
    case ColorSchema.DarkBlue:
      cssClassName.push('dark-blue');
      break;
    case ColorSchema.LightGrey:
      cssClassName.push('light-grey');
      break;
    case ColorSchema.White:
      cssClassName.push('white');
      if (theme === ButtonTheme.WhiteToBlack) {
        cssClassName.push('black');
      }

      if (secondarySchema === ColorSchema.Transparent) {
        cssClassName.push('transparent');
      }
      break;
    case ColorSchema.Orange:
      cssClassName.push('orange');
      break;
    case ColorSchema.None:
      cssClassName.push('none');
      break;
  }

  if (disabled) {
    cssClassName.push('disabled');

    if (primarySchema === ColorSchema.Transparent) {
      cssClassName.push('.transparent');
    }
  }

  return cssClassName.join(' ');
};

const baseStyle: StyleOrStyleArray<Style> = {
  position: 'relative',
  borderRadius: '30px',
  fontWeight: 'bold',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  transitionDuration: '0.4s, 0s',
  transitionTimingFunction: 'ease, ease',
  transitionDelay: '0s, 0s',
  transitionProperty: 'all, background-position',
  boxSizing: 'border-box',
};

const BaseSpan = styled.span(baseStyle);

const BaseButton = styled.button({
  ...baseStyle,
  border: {
    xy: {
      style: 'none',
    }
  },
});

const connected = connect(
  (state) => ({
    currentBreakpoint: state.currentBreakpoint,
  })
);

function getCompiledStyle(props: SharedProps, isMobile: boolean) {
  const {
    iconBefore,
    iconAfter,
    disabled = false,
    upperCase = true,
    display = 'inline-block',
    fontSize = 14,
    padding,
    height = ButtonHeight.Default,
    autoHeight = false,
    selected = false,
    fullWidth,
    css,
    linkButtonCss,
  } = props;

  const cursorDisabled = disabled || selected;
  const itemHeight = isMobile ? buttonHeights()[height].mobile : buttonHeights()[height].desktop;
  const itemPadding = padding?.x ?? (isMobile ? 20 : 24);
  const validatedDisplay = iconAfter || iconBefore ? 'inline-flex' : display;
  const currentCss = linkButtonCss ? linkButtonCss : css;

  const styles: StyleOrStyleArray<Style> = {
    ...(currentCss && { ...currentCss }),
    textTransform: upperCase ? 'uppercase' : 'none',
    alignItems: 'center',
    display: validatedDisplay,
    textAlign: validatedDisplay === 'block' ? `center` : `initial`,
    lineHeight: `${itemHeight}px`,
    padding: {
      left: `${itemPadding}px`,
      right: `${itemPadding}px`,
    },
    fontSize: `${fontSize}px`,
    ...(validatedDisplay === 'inline-flex' && { justifyContent: 'center', }),
    ...(!autoHeight && { height: `${itemHeight}px`, }),
    ...(!cursorDisabled && { cursor: 'pointer', }),
    ...(fullWidth && { width: '100%', }),
  }

  return styles;
}

export const LinkButton = styled(
  connected((props: LinkButtonProps & StyledProps & ConnectedProps) => {
    const { text, children, compose } = props;
    const {
      iconBefore,
      iconAfter,
      disabled = false,
      theme = ButtonTheme.WhiteToTransparent,
      to,
      height = ButtonHeight.Default,
      autoHeight = false,
      selected = false,
      disableClickEventsOnSelected = true,
      target,
      rel,
      fullWidth,
      currentBreakpoint
    } = props;
    const isMobile = isCompact(currentBreakpoint);
    const disableClick = disabled || (disableClickEventsOnSelected && selected);
    const classNames = GetColorSchemeCssClasses(theme, disabled);
    const baseClass = selected ? 'btn selected' : 'btn';
    const isDocument = isDocumentLink(to);
    const linkTarget = isDocument ? '_blank' : target;
    const linkRel = isDocument ? 'noopener' : rel;
    const itemHeight = isMobile ? buttonHeights()[height].mobile : buttonHeights()[height].desktop;
    const commonStyle = getCompiledStyle({ ...props }, isMobile);
    return (
      <Link
        to={to}
        title={props.title ?? props.text}
        css={compose({
          display: 'flex',
          ...(fullWidth && {
            width: '100%',
          }),
          ...props.css,
        })}
        target={linkTarget}
        rel={linkRel}
      >
        <BaseSpan
          onClick={disableClick ? (e) => { e.preventDefault(); } : props.onClick}
          className={`${baseClass} ${classNames}`}
          css={{
            ...commonStyle,
            ...(!autoHeight && { borderRadius: `${itemHeight}px`, }),
          }}
        >
          {iconBefore}
          {text}
          {children}
          {iconAfter}
        </BaseSpan>
      </Link>
    );
  })
);

export const SolidButton = styled(
  connected((props: SolidButtonProps & StyledProps & ConnectedProps) => {
    const { text, children, compose } = props;
    const {
      iconBefore,
      iconAfter,
      disabled = false,
      theme = ButtonTheme.WhiteToTransparent,
      asButton,
      selected = false,
      strikeThrough,
      disableClickEventsOnSelected = true,
      currentBreakpoint,
    } = props;

    const isMobile = isCompact(currentBreakpoint);
    const disableClick = disabled || (disableClickEventsOnSelected && selected);
    const classNames = GetColorSchemeCssClasses(theme, disabled);
    const Element = asButton ? BaseButton : BaseSpan;
    const baseClass = selected ? 'btn selected' : 'btn';
    const commonStyle = getCompiledStyle({ ...props }, isMobile);

    return (
      <Element
        className={`${baseClass} ${classNames}`}
        onClick={disableClick ? (e) => { e.preventDefault(); } : props.onClick}
        onTouchStart={e => {
          if (disableClick) {
            e.preventDefault();
          }
        }}
        onTouchEnd={(e) => {
          if (disableClick) {
            e.preventDefault();
          }
        }}
        css={compose({
          ...commonStyle,
          ...(strikeThrough && { textDecoration: 'line-through' }),
        })}
      >
        {iconBefore}
        {text}
        {children}
        {iconAfter}
      </Element>
    );
  })
);

export const NonInteractiveSolidButton = connected((props: SolidButtonProps & ConnectedProps) => {
  const { text, children } = props;
  const {
    iconBefore,
    iconAfter,
    disabled = false,
    upperCase = true,
    display = 'inline-block',
    fontSize = 14,
    padding,
    height = ButtonHeight.Default,
    currentBreakpoint
  } = props;

  const isMobile = isCompact(currentBreakpoint);
  const itemHeight = isMobile ? buttonHeights()[height].mobile : buttonHeights()[height].desktop;
  const itemPadding = padding?.x ?? (isMobile ? 20 : 24);

  return (
    <BaseSpan
      css={{
        opacity: !disabled ? '100%' : '50%',
        backgroundColor: style.colors.monochrome.white,
        color: style.colors.monochrome.black,
        border: {
          xy: {
            color: style.colors.monochrome.black,
            style: 'solid',
            width: '2px',
          },
        },
        textTransform: upperCase ? 'uppercase' : 'none',
        textDecoration: !disabled ? undefined : 'line-through',
        display: display,
        textAlign: display === 'block' || display === 'inline-block' ? `center` : `initial`,
        cursor: 'default',
        height: `${itemHeight}px`,
        lineHeight: `${itemHeight - 4}px`,
        padding: {
          left: `${itemPadding}px`,
          right: `${itemPadding}px`,
        },
        fontSize: `${fontSize}px`,
      }}
    >
      {iconBefore}
      {text}
      {children}
      {iconAfter}
    </BaseSpan>
  );
});

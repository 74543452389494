import { MainMenuType, Action } from 'Shared/State';
import {
  TOGGLE_MAINMENU,
  SET_MAINMENU_CATEGORY,
  SetMainMenuAction,
  ToggleMainMenuAction,
  SET_SECONDARY_MENUITEM, SetSecondaryMenuAction
} from './action-creators';
import { PAGE_LOAD, UpdateAppShellDataAction, UPDATE_APP_SHELL_DATA } from '@polarnopyret/scope';
import AppShellDataType from 'AppShell/AppShellContextData.type';

export default function(state: MainMenuType = null, action: Action, appShellData: AppShellDataType): MainMenuType {
  state = state || { ...appShellData.header, isOpen: false, selectedCategory: null, selectedSecondaryMenuItem: null, menuTopPosition: 0 };
  switch (action.type) {
    case TOGGLE_MAINMENU: {
      const menuTopPosition = (action as ToggleMainMenuAction).menuTopPosition;
      return {
        ...state,
        isOpen: !state.isOpen,
        menuTopPosition,
      };
    }
    case SET_MAINMENU_CATEGORY: {
      return {
        ...state,
        selectedCategory: (action as SetMainMenuAction).selectedCategory,
        selectedSecondaryMenuItem: (action as SetMainMenuAction).selectedCategory ? null : state.selectedSecondaryMenuItem,
        isOpen: !!(action as SetMainMenuAction).selectedCategory || !!state.selectedSecondaryMenuItem,
      };
    }
    case SET_SECONDARY_MENUITEM: {
      return { 
        ...state, 
        selectedSecondaryMenuItem: (action as SetSecondaryMenuAction).selectedSecondaryMenuItem, 
        selectedCategory: (action as SetSecondaryMenuAction).selectedSecondaryMenuItem ? null : state.selectedCategory,
        isOpen: !!(action as SetSecondaryMenuAction).selectedSecondaryMenuItem || !!state.selectedCategory };
    }
    case UPDATE_APP_SHELL_DATA: {
      const updateAppShellDataAction = action as UpdateAppShellDataAction<AppShellDataType>;

      return {
        ...state,
        ...updateAppShellDataAction.appShellData.header,
      };
    }
    case PAGE_LOAD: {
      if (state.isOpen) {
        return {
          ...state,
          isOpen: false,
        };
      } else {
        return state;
      }
    }
    default:
      return state;
  }
}

import { styled } from '@glitz/react';
import React from 'react';
import { InstantSearchLink, SearchSoftHeading, searchUrl } from './shared';
import { MIN_SEARCH_LENGTH } from 'Search/action-creators';
import * as style from 'Shared/Style';
import { replaceState } from '@polarnopyret/scope';

type SuggestionsPropType = {
  searchText?: string;
  isMobile: boolean;
  heading?: string;
  suggestions?: string[];
};

const InlineGrey = styled.span({
  color: style.colors.monochrome.a11ygrey
})

const InlineBlack = styled.span({
  color: style.colors.monochrome.black
})

const Items = styled.div({
  marginBottom: '40px'
})

const SuggestionList = (props: SuggestionsPropType) => {
  const { heading, searchText, suggestions, isMobile } = props;
  
  if(suggestions === null || (suggestions?.length ?? 0) < 1) {
    return (
      <></>
    );
  }
  
  return (
    <>
      <SearchSoftHeading>{heading}</SearchSoftHeading>
      <Items>
        {suggestions?.map((suggestion, index) => (
          <InstantSearchLink
            key={index}
            to={searchUrl(suggestion, false)}
            onClick={(e) => {
              e.preventDefault();
              replaceState(searchUrl(suggestion, false));
            }}
            css={{ margin: { top: index !== 0 ? '12px' : undefined } }}
          >
            {(searchText ?? '').length < MIN_SEARCH_LENGTH ||
            suggestion.indexOf(searchText as string) === -1 ? (
              suggestion
            ) : (
              //If the search text is long enough, we want to highlight the matching part of the suggestion
              <>
                <InlineGrey>
                  {suggestion.substring(0, suggestion.indexOf(searchText as string))}
                </InlineGrey>
                <InlineBlack>{searchText}</InlineBlack>
                <InlineGrey>
                  {suggestion.substring(suggestion.indexOf(searchText ?? '') + (searchText ?? '').length,)}
                </InlineGrey>
              </>
            )}
          </InstantSearchLink>
        ))}
      </Items>
    </>
  );
};

export default SuggestionList;
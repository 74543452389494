import React from 'react';
import { ESC_KEY } from '@polarnopyret/scope';
import { TransitionMotion, spring, OpaqueConfig } from 'react-motion';
import { styled, StyledProps } from '@glitz/react';
//import freezeScroll from 'Shared/freeze-scroll';
import * as style from 'Shared/Style';

type PlainStyleType<TValue> = {
  opacity: TValue;
};

type StylesType<TValue> = {
  key: string;
  style: PlainStyleType<TValue>;
};

type PropType = React.HTMLAttributes<HTMLDivElement> &
  StyledProps & {
    enabled?: boolean;
    onClose?: () => void;
    zIndex?: number;
  };

type StateType = {
  coverIsPresent: boolean;
};

export let currentOpenOverlay: Overlay = null;

export function closeOpenOverlay() {
  if (currentOpenOverlay) {
    currentOpenOverlay.close();
  }
}

class Overlay extends React.Component<PropType, StateType> {
  static defaultProps: Partial<PropType> = {
    enabled: true,
  };
  state = {
    coverIsPresent: false,
  };
  //unfreezeScroll: () => void;
  componentWillUnmount() {
    this.disable();
  }
  componentDidMount() {
    if (this.props.enabled) {
      this.enable();
    }
  }
  enable() {
    this.setState({ coverIsPresent: true });

    // The key down event must be attached to window and not on `<Root />` for the callback to be
    // called even if active element is `<body />`
    window.addEventListener('keydown', this.keyDown);

    if (currentOpenOverlay !== this) {
      closeOpenOverlay();
    }
    currentOpenOverlay = this;

    // Don't hide overflow if there's a scroll bar. Note
    // that mobile browsers doesn't have scroll bar, so this
    // only applies to mobile browsers.
    // if (window.innerWidth === document.documentElement.clientWidth && this.props.disableScrollFreeze !== true) {
    //   this.unfreezeScroll = freezeScroll();
    // }
  }
  disable() {
    window.removeEventListener('keydown', this.keyDown);

    // if (this.unfreezeScroll) {
    //   this.unfreezeScroll();
    // }
    // this.unfreezeScroll = null;

    if (currentOpenOverlay === this) {
      currentOpenOverlay = null;
    }
  }
  keyDown(e: KeyboardEvent) {
    if (e.keyCode === ESC_KEY && currentOpenOverlay) {
      currentOpenOverlay.close();
      currentOpenOverlay = null;
    }
  }
  componentWillReceiveProps(nextProps: PropType) {
    if (this.props.enabled !== nextProps.enabled) {
      if (nextProps.enabled) {
        this.enable();
      } else {
        this.disable();
      }
    }
    // if (this.props.disableScrollFreeze !== nextProps.disableScrollFreeze) {
    //   if (nextProps.disableScrollFreeze) {
    //     if (this.unfreezeScroll) {
    //       this.unfreezeScroll();
    //     }
    //     this.unfreezeScroll = null;
    //   } else {
    //     freezeScroll();
    //   }
    // }
  }
  close = () => {
    if (this.props.enabled) {
      // if (this.unfreezeScroll) {
      //   this.unfreezeScroll();
      // }
      // this.unfreezeScroll = null;
      this.props.onClose();
    }
  };
  getStyles = (): Array<StylesType<OpaqueConfig>> =>
    this.props.enabled
      ? [
          {
            key: 'cover',
            style: { opacity: spring(0.30) },
          },
        ]
      : [];
  willEnter = (): PlainStyleType<number> => {
    this.setState({ coverIsPresent: true });
    return { opacity: 0 };
  };
  willLeave = (): PlainStyleType<OpaqueConfig> => ({ opacity: spring(0) });
  didLeave = () => {
    this.setState({ coverIsPresent: false });
  };
  render() {
    const { compose, enabled, onClose, zIndex, ...restProps } = this.props;
    return (
      <styled.Div {...restProps} css={compose()}>
        <TransitionMotion
          styles={this.getStyles}
          willEnter={this.willEnter}
          willLeave={this.willLeave}
          didLeave={this.didLeave}
        >
          {(interpolatedStyles: Array<StylesType<number>>) => {
            if (interpolatedStyles[0]) {
              const entry = interpolatedStyles[0];
              return (
                <styled.Div
                  css={{
                    backgroundColor: 'black',
                    position: 'fixed',
                    zIndex: this.props.zIndex || style.ZIndex.Overlay,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    /* height: '100%',
                    width: '100%', */
                    height: '100vh',
                    width: '100vw',
                  }}
                  onClick={this.close}
                  style={{ opacity: entry.style.opacity }}
                />
              );
            }

            return null;
          }}
        </TransitionMotion>
        <styled.Div
          css={{
            ...(this.state.coverIsPresent && {
              position: 'relative',
              height: 'inherit',
              zIndex: this.props.zIndex || style.ZIndex.Overlay,
            }),
          }}
        >
          {this.props.children}
        </styled.Div>
      </styled.Div>
    );
  }
}

export default styled(Overlay, {
  position: 'relative',
});

import React from 'react';
import { styled } from '@glitz/react';
import { AltLogo } from 'Shared/Logotype';
import { startPage } from 'Shared/known-urls';
import A from 'Shared/Generic/A';
import Link from 'Shared/Link';
import { H1, ResponsiveSize } from 'Shared/SharedComponents/atoms/Typography';
import { Left12x12 } from 'Shared/Icons/Arrow';
import * as style from 'Shared/Style';

const Base = styled.div({
  height: '50px',
  ['@media ' + style.mediaMinLarge]: {
    height: '48px',
  },
});

const Logo = styled(AltLogo, {
  display: 'block',
  height: '26px',
});

const CompactLogo = styled(AltLogo, {
  display: 'block',
  height: '20px',
});

const Wrapper = styled.div({
  display: 'flex',
  width: '100%',
  position: 'relative',
  height: 'inherit',
  lineHeight: 0,
  zIndex: 1,
  borderBottom: {
    width: '2px',
    style: 'solid',
    color: style.colors.monochrome.black,
  },
});

const LinkWrap = styled.div({
  paddingLeft: '12px',
  alignSelf: 'center',
  flexBasis: '20%',
  ['@media ' + style.mediaMinLarge]: {
    paddingLeft: '16px',
  },
})

const Home = styled(Link, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  color: style.colors.monochrome.black,
});

const ContinueShopping = styled(A, {
  color: style.colors.monochrome.black,
  textTransform: 'uppercase',
  fontSize: '14px',
  fontWeight: 500,
  ':hover': {
    textDecoration: 'none',
  },
});

const LogoWrap = styled.div({
  flexGrow: 1,
})

const HeadingWrap = styled.div({
  paddingRight: '12px',
  alignSelf: 'center',
  flexBasis: '20%',
  textAlign: 'right',
  ['@media ' + style.mediaMinLarge]: {
    paddingRight: '16px',
  },
})

const LeftIcon = styled(Left12x12, {
  ['@media ' + style.mediaMinLarge]: {
    marginRight: '8px',
    marginTop: '3px'
  },
})

type PropType = {
  isCompact: boolean;
  returnToShopLink: string;
  returnToShopLinkLabel: string;
  pageHeading: string;
};

export default (props: PropType) => {
  return (
    <Base>
      <Wrapper>
        {props.returnToShopLink && (
          <LinkWrap>
            <ContinueShopping to={props.returnToShopLink}>
              <LeftIcon />
                {!props.isCompact && props.returnToShopLinkLabel}
            </ContinueShopping>
          </LinkWrap>
        )}
        <LogoWrap>
          <Home to={startPage()}>
            {props.isCompact && <CompactLogo />}
            {!props.isCompact && <Logo />}
          </Home>
        </LogoWrap>
        <HeadingWrap>
          <H1 fontSize={ResponsiveSize.D14_M14}>{props.pageHeading}</H1>
        </HeadingWrap>
      </Wrapper>
    </Base>
  );
};

import {
  Dispatch as ScopeDispatch,
  LoadFailure as ScopeLoadFailure,
  Page,
  State as Base,
  Store as ScopeStore,
  UnhandledError as ScopeUnhandledError,
} from '@polarnopyret/scope';
import CartViewModel from 'Cart/Models/CartViewModel.type';
import ScopeAppShellDataType from 'AppShell/AppShellContextData.type';
import MainMenuViewModel from 'SiteLayout/MainMenu/MainMenuViewModel.type';
import MainMenuItemType from 'SiteLayout/MainMenu/MainMenuItem.type';
import CartUpdateViewModel from 'Cart/Models/CartUpdateViewModel.type';
import SiteFooterType from 'SiteLayout/Footer/SiteFooter.type';
import SiteHeaderType from 'SiteLayout/Header/SiteHeader.type';
import CurrentUserTypeBase from 'Account/CurrentUserAccountViewModel.type';
import QuickSearchViewModelType from '../Search/QuicksearchViewModel.type';
import EmptySearchViewModelType from '../Search/EmptySearchViewModel.type';
import { AccountBoxScene } from 'SiteLayout/AccountBox/action-creators';
import SecondaryMenuItem from 'SiteLayout/MainMenu/SecondaryMenuItem.type';
import ProductListItemViewModel from 'Product/ProductListing/ProductListItemViewModel.type';
import StoreModel from 'Stores/StoreModel.type';
import MiniWishListViewModel from 'WishList/MiniWishListViewModel.type';
import MiniCartViewModel from 'Cart/Models/MiniCartViewModel.type';

export type PageType = Page & {
  alternateLinks?: { [id: string]: string };
  pageName?: string;
};

export type LoadFailure = ScopeLoadFailure;
export type UnhandledError = ScopeUnhandledError;

export type MainMenuType = MainMenuViewModel & {
  isOpen: boolean;
  selectedCategory: MainMenuItemType;
  menuTopPosition: number;
  selectedSecondaryMenuItem: SecondaryMenuItem;
};

export type CurrentUserType = CurrentUserTypeBase & {
  loginMenuVisible: boolean;
  scene: AccountBoxScene;
  message?: string;
  redirectToAfterLogin?: string;
};

export type HeaderType = {
  content: SiteHeaderType;
  isCollapsed: boolean;
  isVisible: boolean;
};

export type QuickSearchType = {
  isOpen: boolean;
  searchText: string;
  suggestion: string;
  selectedCompletionIndex: number;
  quicksearchLists: QuickSearchViewModelType;
  emptySearchLists: EmptySearchViewModelType;
  originalSearchTerm: string;
};

export type CartDiffType = CartUpdateViewModel & {
  id: string;
};

export type CartType = CartViewModel & {
  isLoading: boolean;
  pendingChanges: CartDiffType[];
};

export type MiniCartType = MiniCartViewModel & {
};

export type FooterType = SiteFooterType;

export type AppShellDataType = ScopeAppShellDataType;

type State = Base<{
  appShellData?: AppShellDataType;
  cart?: CartType;
  miniCart?: MiniCartType;
  mainMenu?: MainMenuType;
  header?: HeaderType;
  quickSearch?: QuickSearchType;
  footer?: FooterType;
  currentUser?: CurrentUserType;
  UI?: UIType;
  wishlist?: WishlistType;
}>;

export type UIType = {
  displayedQuickShopProducts: ProductListItemViewModel[] | null;
  selectedStore: StoreModel | null;
  addedToCartModal: AddedToCartModal | null;
  headerHeight: number;
  displayCartModal: boolean;
  modalIsOpen: boolean;
};

export type AddedToCartModal = {
  code: string;
  message: string;
  miniCart: MiniCartViewModel;
};

export const UIInitialState: UIType = {
  displayedQuickShopProducts: null,
  selectedStore: null,
  addedToCartModal: null,
  headerHeight: 0,
  modalIsOpen: false,
  displayCartModal: false
};

export type WishlistType = MiniWishListViewModel;

export const WishlistInitialState: WishlistType = {
  items: [],
};

export type Store = ScopeStore<State>;
export type Dispatch = ScopeDispatch<State>;
export { Action } from 'redux';

export default State;

import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { Style } from '@glitz/type';
import AspectRatio, { Ratio } from '../AspectRatio';
import Image, { PictureImage, PropType, PicturePropType } from './';

export { Preset } from './';
export { Ratio } from '../AspectRatio';

const genericStyle: Style = {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: '100%',
  height: '100%',
};

const ImageRatioDefault = styled(Image, {
  ...genericStyle,
  maxWidth: '100%',
  maxHeight: '100%',
  margin: { xy: 'auto' },
});

const ImageRatioCustomWide = styled(Image, {
  ...genericStyle,
  minHeight: '100%',
  objectFit: 'cover',
});

const ImageRatioCustom = styled(ImageRatioCustomWide, {
  maxHeight: '100%',
});

const PictureDefault = styled(PictureImage, {
  ...genericStyle,
  maxWidth: '100%',
  maxHeight: '100%',
  margin: { xy: 'auto' },
  display: 'block'
});

const PictureCustomWide = styled(PictureImage, {
  ...genericStyle,
  minHeight: '100%',
  objectFit: 'cover',
  display: 'block'
});

const PictureCustom = styled(PictureCustomWide, {
  maxHeight: '100%',
});

export default styled(
  ({
    compose,
    ratio,
    borderRadius,
    children,
    elementRef,
    wrapperClassName,
    wrapperStyle,
    ...restProps
  }: StyledProps & PropType & { ratio: Ratio; borderRadius?: number; elementRef?: React.Ref<HTMLDivElement>, wrapperClassName?: string, wrapperStyle?: React.CSSProperties }) => {
    let ImageResource;
    switch (ratio) {
      case Ratio.SixteenToNine:
      case Ratio.OneToOneFill:
      case Ratio.Fill:
      case Ratio.FourteenToNinePortrait:
      case Ratio.FourToThree:
      case Ratio.EightToThree:
      case Ratio.FiveToFour:
      case Ratio.TwoToThree:
      case Ratio.ThreeToFour:
      case Ratio.TwelveToTwentyThree:
        ImageResource = ImageRatioCustom;
        break;
      case Ratio.ThreeToTwo:
      case Ratio.FourtyToThirteen:
        ImageResource = ImageRatioCustomWide;
        break;
      default:
        ImageResource = ImageRatioDefault;
        break;
    }

    return (
      <AspectRatio
        className={wrapperClassName}
        elementRef={elementRef}
        ratio={ratio}
        css={compose({
          position: 'relative',
          height: 'inherit',
          borderRadius: borderRadius && borderRadius > 0 ? `${borderRadius}px` : '0px',
        })}
        style={wrapperStyle}
      >
        <ImageResource {...restProps} />
        {children}
      </AspectRatio>
    );
  },
);

export const RatioPicture = styled(
  ({
    compose,
    ratio,
    borderRadius,
    children,
    elementRef,
    wrapperClassName,
    wrapperStyle,
    ...restProps
  }: StyledProps & PicturePropType & { ratio: Ratio; borderRadius?: number; elementRef?: React.Ref<HTMLDivElement>, wrapperClassName?: string, wrapperStyle?: React.CSSProperties }) => {
    let PictureResource;
    switch (ratio) {
      case Ratio.SixteenToNine:
      case Ratio.OneToOneFill:
      case Ratio.Fill:
      case Ratio.FourteenToNinePortrait:
      case Ratio.FourToThree:
      case Ratio.EightToThree:
      case Ratio.FiveToFour:
      case Ratio.TwoToThree:
      case Ratio.ThreeToFour:
      case Ratio.TwelveToTwentyThree:
        PictureResource = PictureCustom;
        break;
      case Ratio.ThreeToTwo:
      case Ratio.FourtyToThirteen:
        PictureResource = PictureCustomWide;
        break;
      default:
        PictureResource = PictureDefault;
        break;
    }

    return (
      <AspectRatio
        className={wrapperClassName}
        elementRef={elementRef}
        ratio={ratio}
        css={compose({
          position: 'relative',
          height: 'inherit',
          borderRadius: borderRadius && borderRadius > 0 ? `${borderRadius}px` : '0px',
        })}
        style={wrapperStyle}
      >
        <PictureResource {...restProps} />
        {children}
      </AspectRatio>
    );
  },
);

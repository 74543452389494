/**
 * @ComponentFor SiteFooter
 */
import { pushState } from '@polarnopyret/scope/index';
import React, { useState } from 'react';
import { styled, StyledProps } from '@glitz/react';
import connect from 'Shared/connect';
import { FooterType } from 'Shared/State';
import { ScaledLogo } from 'Shared/Logotype';
import Viewport from 'Shared/Viewport';
import { SolidButton, LinkButton, ButtonTheme } from 'Shared/TextButtons';
import SiteFooter from 'SiteLayout/Footer/SiteFooter.type';
import { StyledDecorator } from '@glitz/react/types/styled/decorator';
import { StyleOrStyleArray } from '@glitz/type';
import { Plus16x16 } from 'Shared/Icons/Plus';
import { Divider, MenuLinkItemOnClick } from 'SiteLayout/MainMenu';
import Html from 'Shared/Partials/PropertyTypes/Html';
import Link from 'Shared/Link';
import { AccountBoxScene, openAccountBox } from 'SiteLayout/AccountBox/action-creators';
import LinkItem from 'SiteLayout/Shared/LinkItem.type';
import SitePages from 'AppShell/Models/SitePages.type';
import PaymentWidget from './PaymentWidget';
import { Footer, GridWrap } from 'Shared/PageLayout';
import { createGridClasses, createOffsetGridClassLarge } from 'Shared/grid';
import { Paragraph, ResponsiveSize, TextBlockSmall } from 'Shared/SharedComponents/atoms/Typography';
import * as style from 'Shared/Style';

type RequiredProps = {
  footer: FooterType;
  pages: SitePages;
  openAccountBox: () => void;
};

type PropType = RequiredProps & StyledProps;

const ButtonListWrap = styled.div({
  display: 'flex', 
  flexWrap: 'wrap',
  gap: '12px',
  ['@media ' + style.mediaMinLarge]: {
    gap: '16px',
  },

  ['@media ' + style.mediaUpToLarge]: {
    padding: { top: '32px', bottom: '15px' }
  },
})

const ButtonList = styled(
  ({ items, compose }: StyledProps & React.HTMLAttributes<HTMLDivElement> & { items: LinkItem[], isMobile: boolean }) => {
    return (
      <ButtonListWrap css={compose()}>
        {items?.map((link, i: number, a) => (
          <LinkButton
            key={i}
            to={link.url}
            title={link.title}
            onClick={link.actionType !== "link" ? (e) => {
              e.preventDefault();
              MenuLinkItemOnClick(link, openAccountBox);
            } : null}
            target={link.targetNewWindow ? '_blank' : ''}
            rel={link.targetNewWindow ? 'noopener noreferrer' : ''}
            display='inline-flex'
            text={link.title}
            theme={ButtonTheme.WhiteToBlack}
          />
        ))}
      </ButtonListWrap>
    );
  },
);

const DrawerWrap = styled.div({
  overflow: 'hidden',
  paddingTop: '25px',
  paddingBottom: '25px',
})

const DrawerWrapNoTop = styled(DrawerWrap, {
  borderBottom: {
    color: style.colors.monochrome.black,
    width: 2,
    style: 'solid',
  },
})

const DrawerWrapTop = styled(DrawerWrap, {
  border: {
    y: {
      color: style.colors.monochrome.black,
      width: 2,
      style: 'solid',
    },
  },
})

const DrawerHeading = styled.div({
  display: 'flex',
  cursor: 'pointer',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const DrawerContent = styled.div({
  display: 'flex',
  height: 'auto',
  transition: {
    property: 'max-height',
    duration: 300,
  },
  flexDirection: 'column',
  overflow: 'hidden',
})

const DrawerIcon = styled(Plus16x16, {
  transition: {
    property: 'transform',
    duration: 300,
  },
})

const Drawer = ({
  children,
  title,
  uppercaseTitle,
  hideTopDivider,
}: {
  children: React.ReactNode;
  title: string;
  hideTopDivider: boolean;
  uppercaseTitle?: boolean;
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const Wrap = hideTopDivider ? DrawerWrapNoTop : DrawerWrapTop;
  return (
    <Wrap>
      <DrawerHeading onClick={() => setIsOpened(!isOpened)}>
        <TextBlockSmall upperCase={uppercaseTitle}>{title}</TextBlockSmall>
        <DrawerIcon
          css={{
            transform: isOpened ? 'rotate(45deg)' : 'rotate(0deg)',
          }}
        />
      </DrawerHeading>
      <DrawerContent
        css={{
          maxHeight: isOpened ? '100vh' : '0px',
        }}
      >
        {children}
      </DrawerContent>
    </Wrap>
  );
};

const FooterLinksWrap = styled.div({
  ['@media ' + style.mediaMinLarge]: {
    marginTop: '80px',
  },
})

const FooterLinkMobile = styled(Link, {
  fontSize: '16px',
  lineHeight: '130%',
  marginBottom: '16px',
  cursor: 'pointer',
  textDecoration: 'none',
  color: style.colors.monochrome.black,
})

const FooterLinkDesktop = styled(Link, {
  textDecoration: 'none',
  color: style.colors.monochrome.black,
  fontSize: '16px',
  lineHeight: '130%',
  marginBottom: '12px',
})

const FooterLinks = ({
  isCompact,
  footer,
}: {
  isCompact: boolean;
  footer: SiteFooter;
}) => (
  <FooterLinksWrap className={isCompact ? '' : 'grid subgrid-6'}>
    {isCompact
      ? footer.links.map((link, i: number) => (
        <Drawer title={link.title} key={i} hideTopDivider uppercaseTitle={true}>
          {link.linkItems.map((linkItem, i: number) => (
            <FooterLinkMobile
              to={linkItem.url}
              title={linkItem.title}
              key={i}
              css={{
                marginTop: i === 0 ? 32 : 0,
              }}
              {...(linkItem.url.split('')[0] === '/'
                ? {}
                : {
                    target: '_blank',
                    rel: 'noopener noreferrer',
                  })} //Handle external links
            >
              {linkItem.title}
            </FooterLinkMobile>
          ))}
        </Drawer>
      ))
      : footer.links.map((link: any, i: number) => (
        <div key={i} className={createGridClasses(4, 2)}>
          {link.url ? (
            <Link to={link.url} title={link.title} css={{ marginBottom: 28, textTransform: 'uppercase', fontSize: '14px', fontWeight: 500, }}>
              {link.title}
            </Link>
          ) : (
            <TextBlockSmall upperCase css={{ marginBottom: 28, }}>{link.title}</TextBlockSmall>
          )}
          {link.linkItems.map((linkItem: LinkItem, i: number) => (
            <FooterLinkDesktop
              to={linkItem.url}
              title={linkItem.title}
              key={i}
              {...(linkItem.url.split('')[0] === '/'
                ? {}
                : {
                    target: '_blank',
                    rel: 'noopener noreferrer',
                  })} //Handle external links
            >
              {linkItem.title}
            </FooterLinkDesktop>
          ))}
        </div>
      ))}
  </FooterLinksWrap>
);

export type CSSstyledProp = StyleOrStyleArray | StyledDecorator;

const FooterWrap = styled(Footer, {
  borderTop: {
    color: style.colors.monochrome.black,
    width: '2px',
    style: 'solid'
  }
})

const FooterLogoWrap = styled.div({
  padding: { y: 50 },
  ['@media ' + style.mediaMinLarge]: {
    alignItems: 'start',
  },
})

const FooterIcon = styled(ScaledLogo, {
  height: '50px',
  ['@media ' + style.mediaUpToLarge]: {
    width: '100%',
  },
  ['@media ' + style.mediaMinLarge]: {
    height: '60px',
    width: 'auto',
  },
})

const FooterMemberWrap = styled.div({
  ['@media ' + style.mediaUpToLarge]: {
    marginBottom: '80px',
  },
})

const FooterMemberText = styled(Paragraph, {
  marginBottom: '24px',
  marginTop: 0,
})

const FooterHelpLinksTitle = styled(TextBlockSmall, {
  marginBottom: '24px',
})

const FooterContentWrap = styled.div({
  marginTop: '80px',
  marginBottom: '24px',
})

const FooterContentWrapInner = styled.div({
  marginBottom: '80px',
})

const FooterContentText = styled.p({
  fontWeight: 'bold',
  margin: { xy: 0, bottom: '24px' },
  textTransform: 'uppercase',
})

const BottomLinkWrap = styled.div({
  display: 'flex',
  flexDirection: 'row',
  ['@media ' + style.mediaUpToLarge]: {
    order: 1,
  },
})

const BottomLink = styled(Link, {
  lineHeight: '130%',
  fontSize: '16px',
  color: style.colors.monochrome.black,
  textDecoration: 'none',
})

const BottomSectionDivider = styled(Divider, {
  margin: { xy: 0, bottom: '24px' },
  ['@media ' + style.mediaMinLarge]: {
    margin: { xy: 0, bottom: '16px' }
  },
})

const BottomSection = styled.div({
  marginBottom: '16px',
  ['@media ' + style.mediaUpToLarge]: {
    marginBottom: '80px',
    gridGap: '24px',
  },
})

const CopyRightText = styled.p({
  lineHeight: '130%', margin: { xy: 0 }
})

const PageFooter = ({ footer, pages }: PropType) => {
  return (
    <Viewport>
      {(isCompact: boolean) => (
        <FooterWrap>
          <GridWrap>
          <div className="grid">
              <FooterLogoWrap className={createGridClasses(12, 12)}>
                <FooterIcon />
              </FooterLogoWrap>
            </div>
            <div className="grid">
              <FooterMemberWrap className={createGridClasses(12, 4)}>
                <FooterMemberText fontSize={ResponsiveSize.D32_M24}>
                  {footer.memberHeading}
                </FooterMemberText>
                <div>
                  <SolidButton
                    text={footer.memberButtonText}
                    title={footer.memberButtonText}
                    onClick={() => pushState(pages.registerPage.url)}
                    theme={ButtonTheme.WhiteToBlack}
                  />
                </div>
              </FooterMemberWrap>
              <div className={createGridClasses(12, 6, createOffsetGridClassLarge(7))}>
                {isCompact ? (
                  <Drawer title={footer.helpLinks?.title} hideTopDivider={false} uppercaseTitle>
                    <ButtonList isMobile={isCompact} items={footer.helpLinks?.linkItems} />
                  </Drawer>
                ) : (
                  <div>
                    <FooterHelpLinksTitle upperCase>
                      {footer.helpLinks?.title}
                    </FooterHelpLinksTitle>
                    <ButtonList isMobile={isCompact} items={footer.helpLinks?.linkItems} />
                  </div>
                )}
                <FooterLinks isCompact={isCompact} footer={footer} />
                <FooterContentWrap className="grid subgrid-lg-6">
                  <FooterContentWrapInner className={createGridClasses(12, 4)}>
                    <FooterContentText>
                      {footer.contentHeading}
                    </FooterContentText>
                    <Html html={footer.contentText?.html} />
                  </FooterContentWrapInner>
                  {footer.paymentWidgetLink && footer.paymentWidgetLink.url && (
                    <PaymentWidget paymentWidgetLink={footer.paymentWidgetLink} className={createGridClasses(8, 3)} />
                  )}
                </FooterContentWrap>
              </div>
            </div>
            <div>
              <BottomSectionDivider />
              <BottomSection className="grid">
                <CopyRightText className={createGridClasses(12, 6)}>
                  {footer.copyRight ? footer.copyRight.replace('{year}', new Date().getFullYear().toString()) : ''}
                </CopyRightText>
                <BottomLinkWrap className={createGridClasses(12, 6)}>
                  {footer.bottomLinks.map((e, i) => (
                    <BottomLink
                      key={i}
                      css={{
                        marginLeft: i !== 0 ? 12 : 0,
                      }}
                      to={e.url}
                      title={e.title}
                    >
                      {e.title}
                    </BottomLink>
                  ))}
                </BottomLinkWrap>
              </BottomSection>
            </div>
          </GridWrap>
        </FooterWrap>
      )}
    </Viewport>
  );
};

export default connect(
  (state) => ({
    footer: state.footer,
    pages: state.appShellData.pages,
  }),
  (dispatch) => ({
    openAccountBox(scene?: AccountBoxScene) {
      dispatch(openAccountBox(scene));
    },
  }),
)(styled(PageFooter));

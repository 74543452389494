import '../styles/globals.scss';
import React from 'react';
import { GlitzClient } from '@glitz/core';
import { GlitzProvider } from '@glitz/react';
import {
  browserRender,
  initUpdateAppShellDataOnSchedule,
  loadPage,
  switchBreakpoint,
  currentBreakpoint,
  setupResizeListener,
  resolveComponentAndChildComponents,
  isBundleLoadError,
  setPhrases,
  scrollToHashAfterInitialRender,
  addUserLog,
  userLogToString,
  setStoreForCrossWindowEvents,
  onHistory,
  refreshCachedData,
  hasServiceWorker,
  clearCacheAndUnregisterServiceWorker,
  registerServiceWorker,
  getAppShellData,
  saveAppShellData,
} from '@polarnopyret/scope';
import 'Shared/component-registry';
import Container from './SiteLayout/Container';
import State, { PageType, Store, CartType } from 'Shared/State';
import AppShellDataType from 'AppShell/AppShellContextData.type';
import createStore from 'Shared/create-store';
import addToCartFromUrl from './Cart/add-to-cart-from-url';
import { openLoginBoxIfLoginRequired } from 'SiteLayout/AccountBox/action-creators';
import { setKnownUrls } from 'Shared/known-urls';
import { initTagManager, pageLoad as gtmPageLoad, insightsPageView } from './TrackingInformation';
import currentPageIsAppShell from './AppShell/current-page-is-appshell';
import { glitzCoreOptions } from 'Shared/glitz-options';
import ErrorBoundary from 'Shared/ErrorBoundary';
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

let firstLoadIsAppShell = false;

async function render(store: Store, glitz: GlitzClient) {
  const el = document.getElementById('container');

  if (!(window as any).Intl) {
    // Price formatting uses `Intl.NumberFormat` which isn't supported for
    // browsers like IE10 and iOS9
    await import('Shared/intl');
  }

  await browserRender(
    store,
    el,
    <GlitzProvider glitz={glitz}>
      <ErrorBoundary standalone>
        <Container store={store} />
      </ErrorBoundary>
    </GlitzProvider>,
  );

  if (location.hash) {
    await scrollToHashAfterInitialRender(location.hash);
  }
}

function initialRender(appShellData: AppShellDataType, currentPage: PageType, glitz: GlitzClient) {
  let initialLog = 'Started at ' + window.location.href + '.';
  if (window.IS_RENDERED_FROM_CACHE) {
    initialLog += ' Was rendered in Service Worker.';
  }
  addUserLog(initialLog);

  setKnownUrls(appShellData.pages, appShellData.content);
  const store = createStore({ appShellData, currentPage });

  // // Since some errors occur before this code has loaded some errors slip past this check.
  // // Because of this a slightly different version of this is placed in _Layout.cshtml
  // // as well
  // if (window.rg4js) {
  //   window.rg4js('onBeforeSend', (payload: RaygunPayload) => {
  //     function stackTraceHasNoInformation(stackTrace: RaygunStackItem[]) {
  //       if (!stackTrace || stackTrace.length === 0) {
  //         return true;
  //       }
  //       const firstItem = stackTrace[0];
  //       const lastItem = stackTrace[stackTrace.length - 1];
  //       return (
  //         (!firstItem.ColumnNumber && !firstItem.LineNumber) ||
  //         (!firstItem.ColumnNumber && firstItem.LineNumber === 1) ||
  //         // If the only stack item is from window.onerror we want to ignore it
  //         (stackTrace.length === 1 &&
  //           (!firstItem.MethodName || firstItem.MethodName.toLowerCase().includes('onerror'))) ||
  //         // Any errors from global code is not our fault
  //         (lastItem.MethodName || '').toLowerCase().includes('global code') ||
  //         // A bug in Chrome for iOS sometimes propagates errors to us for autofill
  //         (firstItem.MethodName || '').includes('getUnownedAutofillableFormFieldElements_') ||
  //         firstItem.MethodName === '?' ||
  //         (firstItem.FileName || '').includes('googleusercontent.com')
  //       );
  //     }

  //     const ignores = ['InvalidStateError', 'Network request failed', '__gCrWeb'];

  //     for (const ignore of ignores) {
  //       if (payload.Details.Error.Message.indexOf(ignore) !== -1) {
  //         return null;
  //       }
  //     }
  //     if (stackTraceHasNoInformation(payload.Details.Error.StackTrace)) {
  //       return null;
  //     }
  //     if (!payload.Details.UserCustomData) {
  //       payload.Details.UserCustomData = {};
  //     }
  //     const state = store.getState() as State;
  //     payload.Details.UserCustomData.userLog = userLogToString();
  //     payload.Details.UserCustomData.currentState = {
  //       context: state.appShellData?.context,
  //       currentBreakpoint: state.currentBreakpoint,
  //       currentUser: state.currentUser,
  //       spinner: state.spinner,
  //       UI: state.UI,
  //       currentPage: {
  //         componentName: state.currentPage?.componentName,
  //         isPartial: state.currentPage?.isPartial,
  //         isLoading: state.currentPage?.isLoading,
  //         shouldCache: state.currentPage?.shouldCache,
  //         url: state.currentPage.url
  //       }
  //     }

  //     return payload;
  //   });

  //   window.rg4js('groupingKey', (payload: RaygunPayload) => {
  //     return payload.Details.Error.Message;
  //   });
  // }

  initTagManager();
  initAppInsights(appShellData.applicationInsights?.key, appShellData.applicationInsights?.cookieConsent, store);
  setStoreForCrossWindowEvents(store);
  setupResizeListener(store);
  addToCartFromUrl(store);
  openLoginBoxIfLoginRequired(store);
  initUpdateAppShellDataOnSchedule(store);

  const breakpoint = currentBreakpoint();
  if (breakpoint !== store.getState().currentBreakpoint) {
    console.debug('Setting new breakpoint since server guess was incorrect');
    store.dispatch(switchBreakpoint(breakpoint));
  } else {
    console.debug('Server breakpoint guess was correct');
  }

  onHistory(['push', 'pop', 'replace'], e => {
    const loadPromise = store.dispatch(
      loadPage({ url: e.url, options: e.options, stateChangeEvent: e, replaceStateOnRedirect: true }),
    );
    loadPromise.then(() => {
      const state = store.getState() as State;
      const currentPage = state.currentPage;
      if (!currentPage.isPartial) {
        setTimeout(() => {
          gtmPageLoad(currentPage, true, state);
          insightsPageView(page);
        }, 1000);
      }
    });
    return loadPromise;
  });

  // Make sure phrases isn't `undefined`, otherwise
  // `translate(...)` with cause an exception.
  setPhrases(appShellData.languagePhrases);

  const page = (store.getState() as State).currentPage;
  if (!currentPageIsAppShell(page)) {
    gtmPageLoad(page, false, store.getState() as State);
    insightsPageView(page);
  } else {
    firstLoadIsAppShell = true;
  }

  render(store, glitz);

  return store;
}

function initAppInsights(instrumentationKey: string, cookieConsent: boolean, store: Store) {
  if (instrumentationKey && instrumentationKey !== "") {
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: instrumentationKey,
        //disableExceptionTracking: true,
        isCookieUseDisabled: !cookieConsent,
        enableSessionStorageBuffer: true
      }
    });

    appInsights.loadAppInsights();
    var telemetryInitializer = (envelope) => {
      if (envelope.baseType === 'ExceptionData') {
        if (typeof (envelope.baseData.exceptions) === 'undefined'
          && envelope.baseData.message
          && envelope.baseData.message.indexOf('same-origin policy prevents us from getting the details of this exception') >= 0) {
          // Log at 1%.
          var samplingPercentage = 0.01;
          var samplingRandom = Math.random();
          return samplingRandom < samplingPercentage;
        }

        //envelope.data.logtest = 'Client logging';
        if (!envelope.data.UserCustomData) {
          envelope.data.UserCustomData = {};
        }

        const state = store.getState() as State;
        envelope.data.UserCustomData.userLog = userLogToString();
        envelope.data.UserCustomData.context = state.appShellData?.context;
        envelope.data.UserCustomData.currentBreakpoint = state.currentBreakpoint;
        envelope.data.UserCustomData.currentUser = state.currentUser;
        envelope.data.UserCustomData.UI = state.UI;
        envelope.data.UserCustomData.currentPage = {
          componentName: state.currentPage?.componentName,
          isPartial: state.currentPage?.isPartial,
          isLoading: state.currentPage?.isLoading,
          shouldCache: state.currentPage?.shouldCache,
          url: state.currentPage.url
        };
      }
    };
    appInsights.addTelemetryInitializer(telemetryInitializer);

    (window as any).appInsights = appInsights;
  }
}

window.scopeReady.then(() => {
  if (!window.APP_SHELL_DATA || !window.CURRENT_PAGE) {
    if (window.rg4js) {
      window.rg4js('send', {
        error: new Error(
          'Script error happened on ' + window.location.href + ' that caused important globals not to exist',
        ),
        tags: ['handled'],
      });
    }
    if (window.location.href !== '/') {
      // We assume that the error doesn't occur on the start page
      // Give Raygun some time to send the request
      setTimeout(() => {
        window.location.href = '/';
      }, 1000);
    }
  } else {
    if (
      (module as any).hot &&
      (module as any).hot.data &&
      (module as any).hot.data.store &&
      (module as any).hot.data.glitz
    ) {
      const store = (module as any).hot.data.store;
      const glitz = (module as any).hot.data.glitz;

      (module as any).hot.accept();
      (module as any).hot.dispose((data: any) => {
        data.store = store;
        data.glitz = glitz;
      });

      const state = store.getState();

      console.info('HMR updating');
      resolveComponentAndChildComponents([state.currentPage, state.appShellData], state.appShellData.currentTheme).then(
        () => {
          console.info('HMR re-render');
          render(store, glitz);
        },
        e => {
          console.error('HMR reloading due to update error: ', e);
          window.location.reload();
        },
      );
    } else {
      resolveComponentAndChildComponents(
        [window.CURRENT_PAGE, window.APP_SHELL_DATA],
        (window.APP_SHELL_DATA as AppShellDataType).currentTheme,
      ).then(
        () => {
          const glitz = new GlitzClient(glitzCoreOptions);
          const store = initialRender(window.APP_SHELL_DATA, window.CURRENT_PAGE, glitz);

          if ((module as any).hot) {
            (module as any).hot.accept();
            (module as any).hot.dispose((data: any) => {
              data.store = store;
              data.glitz = glitz;
            });
          }

          if (window.IS_RENDERED_FROM_CACHE) {
            persistCartAndWishListChanges(store);
            refreshCachedData(store).then(() => {
              const page = (store.getState() as State).currentPage;
              if (firstLoadIsAppShell) {
                gtmPageLoad(page, false, store.getState() as State);
                insightsPageView(page);
                firstLoadIsAppShell = false;
              }
            });
          }
        },
        e => {
          if (isBundleLoadError(e) && hasServiceWorker) {
            clearCacheAndUnregisterServiceWorker().then(() => {
              window.location.reload();
            });
          }
        },
      );
    }

    registerServiceWorker();

    const oldOnError = window.onerror;
    if (process.env.NODE_ENV !== 'production') {
      // Make device debugging a bit simpler
      window.onerror = function (message, url, line, col, e) {
        const error = document.createElement('div');
        error.style.position = 'fixed';
        error.style.padding = '10px';
        error.style.border = '1px solid red';
        error.style.top = '10px';
        error.style.left = '10px';
        error.style.right = '10px';
        error.style.bottom = '10px';
        error.style.background = 'white';

        const html =
          '<h1>Oh noes!</h1>' +
          '<p>Message: ' +
          message +
          '</p>' +
          '<p>Line: ' +
          line +
          '</p>' +
          '<p>Error: ' +
          JSON.stringify(e, null, 2) +
          '</p>';
        error.innerHTML = html;
        document.body.appendChild(error);
        error.addEventListener('click', () => {
          if (document.body.contains(error)) {
            error.parentElement.removeChild(error);
          }
        });

        if (oldOnError) {
          oldOnError.apply(window, arguments);
        }
      };
    }
  }
});

// The cart is one of the things in the app shell data that the user can
// change and we want to persist those changes. Because if the user reloads
// without network connection to refresh the app shell data, the cart should
// still be correct.
function persistCartAndWishListChanges(store: Store) {
  let currentCart = store.getState().cart;
  store.subscribe(() => {
    const newCart = store.getState().cart;
    if (currentCart !== newCart) {
      saveAppShellDataWithNewCart(newCart);
      currentCart = newCart;
    }
  });
}

let saveTimer: number;
function saveAppShellDataWithNewCart(cart: CartType) {
  clearTimeout(saveTimer);
  saveTimer = setTimeout(() => {
    getAppShellData().then(data => {
      const appShellData = data as AppShellDataType;
      console.info('Persisting change to app shell data');
      appShellData.cart = cart;
      saveAppShellData(appShellData);
    });
  }, 200);
}

// type RaygunStackItem = {
//   LineNumber: number;
//   ColumnNumber: number;
//   ClassName: string;
//   FileName: string;
//   MethodName: string;
// };

// type RaygunPayload = {
//   OccurredOn: Date;
//   Details: {
//     Error: {
//       ClassName: string;
//       Message: string;
//       StackTrace: RaygunStackItem[];
//     };
//     Tags: string[];
//     UserCustomData: any;
//   };
// };

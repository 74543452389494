import React, { ReactNode } from 'react';
import { styled, StyledProps } from '@glitz/react';

type FormProps = {
  isIncomplete: boolean;
  onSubmitCallback: (e: React.FormEvent<HTMLFormElement>) => void;
  children: ReactNode[];
};

export default styled(({ isIncomplete, onSubmitCallback, children, compose }: FormProps & StyledProps) => {
  const onFormKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
    // Prevents form submission when the enter key is pressed in a input field
    // Only targets inputs as submit button should submit and textares should be able to add newlines.
    if (
      isIncomplete &&
      (e.target as HTMLElement).tagName.toLowerCase() === 'input' &&
      (e.keyCode || e.which || e.charCode || e.key || 0) === 13
    ) {
      e.preventDefault();
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onSubmitCallback(e);
  };

  return (
    <styled.Form onKeyPress={onFormKeyPress} onSubmit={onSubmit} css={compose()}>
      {children}
    </styled.Form>
  );
});

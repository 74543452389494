import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import connect from 'Shared/connect';
import MainMenuItemType from 'SiteLayout/MainMenu/MainMenuItem.type';
import { toggleMainMenu } from 'SiteLayout/MainMenu/action-creators';
import { MobileMagnifierIcon, QUICKSEARCH_BORDERS } from 'Search/QuickSearch';
import ActionButtons from 'SiteLayout/Header/ActionButtons';
import { startPage } from 'Shared/known-urls';
import Button, { Appearance as ButtonAppearance } from 'Shared/Button';
import Link from 'Shared/Link';
import * as style from 'Shared/Style';
import { ANIMATION_DURATION } from 'SiteLayout/Header/DesktopHeader';
import { Usp } from 'SiteLayout/Header/USP';
import { QuickSearchInputForm } from 'Search/QuickSearch/QuickSearchForm';
import { showQuickSearch } from 'Search/action-creators';
import ProductListItemViewModel from 'Product/ProductListing/ProductListItemViewModel.type';
import { AltLogo } from 'Shared/Logotype';
import { Line24x2 } from 'Shared/Icons/Line';
import StoreBanner from './../../StoreBanner';

export const HEIGHT: number = 48;

type RequiredPropType = {
  quickSearchIsOpen: boolean;
  transparentAtTop: boolean;
  isScrolled: boolean;
  isHovered: boolean;
  isCollapsed: boolean;
  hideUSPs: boolean;
  mainMenuOpen: boolean;
  isStoreMode: boolean;
};

type ConnectedPropType = {
  toggleMainMenu: (e: React.SyntheticEvent<{}>, item?: MainMenuItemType) => void;
  showQuickSearch: () => void;
  displayedQuickShopProducts: ProductListItemViewModel[];
};

type PropType = RequiredPropType & ConnectedPropType;

const BaseNoBg = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 90px 1fr',
  pointerEvents: 'auto',
  paddingLeft: '13px',
  paddingRight: '13px',
  position: 'relative',
  height: 'inherit',
  lineHeight: 0,
  padding: { x: 12 },
  border: { bottom: { width: QUICKSEARCH_BORDERS, style: 'solid' } },
  ...style.transition({ property: ['color', 'background-color'], duration: `${ANIMATION_DURATION}ms` }),
});

const MenuLeft = styled.div({
  display: 'flex',
  gap: '16px',
})

const MenuButton = styled(Button, {
  display: 'block',
});

const MeunMid = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const Home = styled(Link, {
  display: 'block',
  color: 'inherit',
});

const Logo = styled(AltLogo, {
  margin: { xy: 'auto' },
  display: 'block',
  width: 'auto',
  ...style.transition({ property: ['height'], duration: `${ANIMATION_DURATION}ms` }),
});

const MenuIconWrap = styled.div({
  width: '100%',
  height: '24px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  flexDirection: 'column',
});

const MenuBtnLine = styled(Line24x2, {
  transition: {
    property: 'transform',
    duration: 300,
  },
})

const MobileHeader = styled((props: PropType & StyledProps) => {
  const {
    isScrolled,
    transparentAtTop,
    isHovered,
    hideUSPs,
    quickSearchIsOpen,
    showQuickSearch,
    displayedQuickShopProducts,
    mainMenuOpen,
    isStoreMode
  } = props;

  const baseBackground = !isScrolled && transparentAtTop && !isHovered && !quickSearchIsOpen ? 'transparent' : 'white';
  const fgColors = [style.colors.monochrome.white, style.colors.monochrome.black];
  const fgCondition = !isScrolled && transparentAtTop && !isHovered && !quickSearchIsOpen;
  const foregroundColor = fgCondition ? fgColors[0] : fgColors[1];
  const inputPlaceHolderFocusColor  = fgCondition ? fgColors[0] : style.colors.monochrome.a11ygrey;
  
  return (
    <>
      {isStoreMode && <StoreBanner />}
      {!isStoreMode && !hideUSPs && <Usp delay={5000} isMobile={true} />}
      <BaseNoBg css={{ backgroundColor: baseBackground, color: foregroundColor, }}>
        <MenuLeft>
          <MenuButton
            onClick={(e?: React.MouseEvent<HTMLElement>) => {
              props.toggleMainMenu(e);
            }}
            appearance={ButtonAppearance.Bare}      
          >
            <MenuIconWrap css={{color: foregroundColor}}>
              <MenuBtnLine css={{ ...(mainMenuOpen && { transform: 'rotate(135deg) translateY(-3px) translateX(3px)', }) }}/>
              <MenuBtnLine css={{ ...(mainMenuOpen && { transform: 'rotate(-135deg) translateY(3px) translateX(3px)', }) }}/>
            </MenuIconWrap>
          </MenuButton>
          <MobileMagnifierIcon
            onClick={e => showQuickSearch()}
            invisible={!isScrolled || quickSearchIsOpen}
            textColor={foregroundColor}
          />
        </MenuLeft>
        <MeunMid>
          <Home to={startPage()} css={{...(isScrolled ? { height: 20 } : { height: 24 })}}>
            <Logo
              css={{
                fill: foregroundColor,
                ...(isScrolled ? { height: 20 } : { height: 24 }),
              }}
            />
          </Home>
        </MeunMid>
        <ActionButtons
          withText={false}
          inverseFGColor={fgCondition}
          toggleTopLinks={() => {
            return;
          }}
        />
      </BaseNoBg>
      <QuickSearchInputForm
        isMobile={true}
        placeholderColor={foregroundColor}
        inputPlaceHolderFocusColor={inputPlaceHolderFocusColor}
        fg={foregroundColor}
        bg={baseBackground}
        isScrolled={isScrolled || !!displayedQuickShopProducts}
      />
    </>
  );
});

export default connect(
  state => ({
    displayedQuickShopProducts: state.UI.displayedQuickShopProducts,
  }),
  dispatch => ({
    toggleMainMenu: (e?: React.MouseEvent<HTMLElement>) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      dispatch(toggleMainMenu());
    },
    showQuickSearch() {
      dispatch(showQuickSearch());
    },
  }),
)(MobileHeader);

import { styled } from '@glitz/react';
import React from 'react';
import { SearchSoftHeading } from './shared';
import ProductListItemViewModel from 'Product/ProductListing/ProductListItemViewModel.type';
import { Ratio, Preset, RatioPicture } from 'Shared/Image/Ratio';
import Link from 'Shared/Generic/A';
import * as style from 'Shared/Style';

type RecentProductsPropType = {
  isMobile: boolean;
  heading?: string;
  products?: ProductListItemViewModel[];
};

const RecentProductsHeading = styled(SearchSoftHeading, {
  marginBottom: '12px'
})

const Items = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: '12px',
  marginBottom: '12px',
})

const RecentProductLink = styled(Link, {
  maxWidth: 'calc(33% - 8px)',
  textDecoration: 'none',
  ':hover': {
    textDecoration: 'none',
  },
  borderRadius: '8px',
  padding: { xy: '8px' },
  flex: {
    basis: 0,
    grow: 1,
    shrink: 1,
  },
  backgroundColor: style.colors.monochrome.extraLightGrey,
  cursor: 'pointer',
});

const RecentProductDisplayName = styled.span({
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '100%',
  marginTop: '8px',
  textAlign: 'center',
  letterSpacing: '0.01em',
  color: style.colors.monochrome.black,
  textTransform: 'uppercase',
  display: 'block',
})


const RecentProducts = (props: RecentProductsPropType) => {
  const { heading, products, isMobile } = props;

  if (products === null || (products?.length ?? 0) < 1) {
    return (
      <></>
    );
  }
  return (
    <>
      <RecentProductsHeading>{heading}</RecentProductsHeading>
      <Items>
        {products?.slice && products?.slice(0, 3).map((recentProduct, index) => (
          <RecentProductLink key={index} to={recentProduct.url as string}>
            <RatioPicture src={recentProduct.imageUrls?.[0]} preset={Preset.QuickSearchItem} ratio={Ratio.ThreeToFour} />
            <RecentProductDisplayName>
              {recentProduct.displayName}
            </RecentProductDisplayName>
          </RecentProductLink>
        ))}
      </Items>
    </>
  );
};

export default RecentProducts;
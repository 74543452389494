import { styled } from '@glitz/react';
import { URLX } from '@polarnopyret/scope';
import { searchPageUrl } from 'Shared/known-urls';
import {
  SEARCH_PAGE_LOAD_QUERY_NAME,
  SEARCH_QUERY_NAME,
} from 'Search/action-creators';
import Link from 'Shared/Generic/A';
import * as style from 'Shared/Style';

export const searchUrl = (searchText: string, preview: boolean) => {
  const url = new URLX(searchPageUrl());
  url.searchParams.set(SEARCH_QUERY_NAME, searchText);
  if (preview) {
    url.hiddenParams.set(SEARCH_PAGE_LOAD_QUERY_NAME, String(true));
  }
  return url;
};

export const SearchSoftHeading = styled.h2({
  fontStyle: 'normal',
  fontWeight: 500,
  textTransform: 'uppercase',
  fontSize: '14px',
  letterSpacing: '0.01em',
  margin: { xy: '0px' },
  padding: { xy: '0px' },
  marginBottom: '16px',
})

export const EmptyResult = styled.p({
  fontWeight: 450,
  fontSize: '16px',
  lineHeight: '130%',
  fontStyle: 'normal',
  alignItems: 'center',
  margin: { xy: '0px' },
  padding: { xy: '0px' },
  color: style.colors.monochrome.black,
})

export const InstantSearchLink = styled(Link, {
  display: 'block',
  fontStyle: 'normal',
  fontWeight: 500,
  textTransform: 'uppercase',
  lineHeight: '100%',
  color: style.colors.monochrome.black,
  cursor: 'pointer',
  textDecoration: 'none',
  fontSize: '20px',
  ['@media ' + style.mediaMinLarge]: {
    fontSize: '24px',
  },
})
import { styled } from '@glitz/react';
import React from 'react';
import Link from 'Shared/Generic/A';
import QuickSearchResultItem from 'Search/QuickSearchResultItem.type';
import * as style from 'Shared/Style';

const ArticleCardLink = styled(Link, {
  boxSizing: 'border-box',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '8px',
  padding: { xy: '8px' },
  gap: '8px',
  width: '100%',
  minHeight: '113px',
  backgroundColor: style.colors.monochrome.extraLightGrey,
  color: style.colors.monochrome.black,
  textDecoration: 'none',
  ':hover': {
    textDecoration: 'none',
  },
})

const ArticleCardData = styled.span({
  display: 'flex', 
  flexDirection: 'column'
})

const ArticleCardDataText = styled.span({
  display: 'block',
  fontStyle: 'normal',
  fontWeight: 500,
  textTransform: 'uppercase',
  lineHeight: '100%',
  letterSpacing: '0.01em',
  marginBottom: '8px',
  fontSize: '14px',
  ['@media ' + style.mediaMinLarge]: {
    fontSize: '12px',
  },
})

const ArticleCardDataDescription = styled.span({
  display: 'block',
  marginBottom: '4px',
  fontSize: '14px',
  lineHeight: '130%',
  textTransform: 'initial',
  fontWeight: 500,
})


const QuickSearchResultArticleCard = (props: { entry: QuickSearchResultItem }) => (
  <ArticleCardLink
    to={props.entry.url}
    title={props.entry.text}
  >
    <ArticleCardData>
      <ArticleCardDataText>
        {props.entry.text}
      </ArticleCardDataText>
      <ArticleCardDataDescription>
        {props.entry.description}
      </ArticleCardDataDescription>
    </ArticleCardData>
  </ArticleCardLink>
);

export default QuickSearchResultArticleCard;
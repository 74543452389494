import { styled } from '@glitz/react';
import React from 'react';
import QuickSearchViewModelType from '../../Search/QuicksearchViewModel.type';

type SnippetPropType = {
  quicksearchLists?: QuickSearchViewModelType;
  isMobile: boolean;
};
const SnippetResultText = styled.p({
  fontWeight: 450,
  fontSize: '16px',
  lineHeight: '130%',
})

const SnippetsWrapper = styled.div({
  marginBottom: '36px',
  display: 'flex',
  flexDirection: 'column',
  gap: '6px'
})

const MobileSnippetResult = styled(SnippetResultText, {
  marginBottom: '40px'
})

const SnippetList = (props: SnippetPropType) => {
  const { quicksearchLists, isMobile } = props;
  
  const itemCount = quicksearchLists?.contentResultSnippets?.length ?? 0;
  
  if(itemCount === 0) {
    return (
      <></>
    );
  }
  
  if(isMobile) {
    return (
      <>
      {quicksearchLists?.contentResultSnippets[0] && (
        <MobileSnippetResult>
          {quicksearchLists.contentResultSnippets[0]}
        </MobileSnippetResult>
      )}
      </>
    );  
  }
  return (
    <SnippetsWrapper>
      {quicksearchLists?.contentResultSnippets.map((result, index) => {
        return (
          <SnippetResultText key={index}>
            {result}
          </SnippetResultText>
        );
      })}
    </SnippetsWrapper>
  );
};

export default SnippetList;
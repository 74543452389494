import React from 'react';
import { translate } from '@polarnopyret/scope';
import connect from 'Shared/connect';
import { styled } from '@glitz/react';
import * as style from 'Shared/Style';
import Link from 'Shared/Link';

const Wrap = styled.div({
  backgroundColor: style.colors.brandColors.blue,
  display: 'flex',
  overflow: 'hidden',
  flexDirection: 'column',
  overflowY: 'hidden',
  height: '28px', 

  ['@media ' + style.mediaMinLarge]: {
    height: '32px', 
  },
})
const LinkWrap = styled.div({
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
})

const CurrentStoreLink = styled(Link, {
  color: style.colors.monochrome.white,
  alignItems: 'center',
  height: '100%',
  display: 'flex',
  lineHeight: 1,
  textDecoration: 'none',
});

const StoreBanner = connect((state) => ({
  currentStore: state.appShellData.context.activeStoreName,
  storeSelectionPageUri: state.appShellData.pages.storeSelectionPage,
}))(
  (props: {
    
    currentStore: string;
    storeSelectionPageUri: Scope.UrlViewModel;
    
  }) => {
    const { currentStore, storeSelectionPageUri } = props;
    return (
      <Wrap>
        <LinkWrap>
          <CurrentStoreLink to={storeSelectionPageUri}>
            {translate('/site/shared/loggedinas')} {currentStore}
          </CurrentStoreLink>
        </LinkWrap>
      </Wrap>
    );
})

export default StoreBanner;


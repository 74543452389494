import React, { useEffect, useState } from 'react';
import { styled } from '@glitz/react';
import { pushState } from '@polarnopyret/scope';

import connect from 'Shared/connect';
import { Motion, spring } from 'react-motion';
import MainMenuItemType from './MainMenuItem.type';
import { MainMenuType } from 'Shared/State';
import { setSelectedSecondaryMenuItem, toggleMainMenu } from 'SiteLayout/MainMenu/action-creators';
import Overlay from 'Shared/Overlay';
import * as style from 'Shared/Style';
import Link from '../../Shared/Link';
import SiteHeader from 'SiteLayout/Header/SiteHeader.type';
import { Right12x12, Right16x16 } from 'Shared/Icons/Arrow';

import SecondaryMenuItem from 'SiteLayout/MainMenu/SecondaryMenuItem.type';
import { margin } from '.';
import { ScrollableFade, ModalBase } from 'Shared/StyledComponents';
import {
  ResponsiveSize,
  Text,
  H2
} from 'Shared/SharedComponents/atoms/Typography';

type RequiredPropType = {};

type ConnectedPropType = {
  mainMenu: MainMenuType;
  toggleMainMenu: (item?: MainMenuItemType) => void;
  setSelectedSecondaryMenuItem: (item: SecondaryMenuItem) => void;
  header: SiteHeader;
};

type PropType = RequiredPropType & ConnectedPropType & { headerHeight: number };

type PlainStyleType<TValue> = {
  translateY: TValue;
  opacity: TValue;
};

type StylesType<TValue> = PlainStyleType<TValue>;

const MenuLink = styled(Link, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'left',
  cursor: 'pointer',
  textDecoration: 'none',
  color: style.colors.monochrome.black,
  borderBottom: {
    width: '2px',
    style: 'solid',
    color: style.colors.monochrome.black
  }
})

const HoverMenuLink = styled(MenuLink, {
  justifyContent: 'space-between',
  transition: {
    delay: 0,
    duration: '0.2s',
    property: 'padding-right',
    timingFunction: 'ease-in'
  },
  ':hover': {
    paddingRight: '8px'
  },
})

const MenuLinkText = styled.span({
  fontWeight: 500,
  textTransform: 'uppercase',
  fontSize: '16px',
  lineHeight: '100%',
  textAlign: 'left',
  display: 'inline-block',
  padding: { x: 0, y: '16px' },
})

const Heading = styled(H2, {
  textAlign: 'left',
  lineHeight: 1,
  margin: { x: 0, bottom: '20px', top: 0 },
})

const Label = styled(Text, {
  fontWeight: 450,
  textAlign: 'left',
  margin: { x: 0, bottom: 6, top: 0 },
})

const ShowAllLinkText = styled(MenuLinkText, {
  fontSize: '14px', 
  padding: { xy: 0 }, 
  paddingBottom: '12px',
})

export const SecondaryRightmost = styled(ModalBase, {
  right: margin,
  left: 'initial',
  ...style.transition({ property: ['transform', 'opacity'], duration: '300ms' }),
});

const DesktopSecondaryMenu = (props: PropType) => {
  const { mainMenu, headerHeight, header } = props;
  const [currentHoveredItem, setCurrentHoveredItem] = useState(0);
  const [lastHoveredItem, setLastHoveredItem] = useState(-1);
  const isOpen = mainMenu.selectedSecondaryMenuItem && props.mainMenu.isOpen;
  const [interMenuCategory, setInterMenuCategory] = useState(0);

  useEffect(() => {
    setInterMenuCategory(0);
    setCurrentHoveredItem(0);
    setLastHoveredItem(-1);
  }, [mainMenu.selectedSecondaryMenuItem]);
  useEffect(() => {
    setCurrentHoveredItem(0);
    setLastHoveredItem(-1);
  }, [interMenuCategory, mainMenu.selectedSecondaryMenuItem, props.mainMenu.isOpen]);

  const motionStyle = {
    //translateY: spring(mainMenu.isOpen ? 0 : -30),
    opacity: spring(mainMenu.isOpen ? 1 : 0),
  };

  return (
    <Overlay enabled={isOpen} onClose={() => props.setSelectedSecondaryMenuItem(null)} zIndex={style.ZIndex.Menu}>
      <Motion style={motionStyle}>
        {(interpolatedStyle: StylesType<number>) => (
          /*use maxheight instead*/
          <>
            <SecondaryRightmost
              css={{
                top: headerHeight + margin,
                height: `calc(100vh - ${headerHeight}px - ${margin * 2}px)`,
                transform: !isOpen ? 'translateY(0px) translateX(calc(200% + 8px))' : 'translateY(0px) translateX(0%)',
              }}
            >
              <ScrollableFade>
                {mainMenu.selectedSecondaryMenuItem && (
                  <>
                    {mainMenu.selectedSecondaryMenuItem.label && (
                      <Label>{mainMenu.selectedSecondaryMenuItem.label}</Label>
                    )}
                    <Heading upperCase fontSize={ResponsiveSize.DS40_MS40}>
                      {mainMenu.selectedSecondaryMenuItem.name}
                    </Heading>
                    {mainMenu.selectedSecondaryMenuItem.showAllText && (
                      <MenuLink
                        to={mainMenu.selectedSecondaryMenuItem.url}
                        title={mainMenu.selectedSecondaryMenuItem.showAllText}
                        onClick={e => {
                          e.preventDefault();
                          pushState(mainMenu.selectedSecondaryMenuItem.url);
                        }}
                      >
                        <ShowAllLinkText>
                          {mainMenu.selectedSecondaryMenuItem.showAllText}
                        </ShowAllLinkText>
                        <Right12x12 css={{ margin: { left: 4 } }} />
                      </MenuLink>
                    )}
                    {mainMenu.selectedSecondaryMenuItem.children.map((child, i, a) => (
                      <HoverMenuLink
                        key={i + '-' + child.name}
                        to={child.url}
                        title={child.name}
                        onMouseEnter={() => {
                          if (currentHoveredItem !== i && child.imageUrl) {
                            setLastHoveredItem(currentHoveredItem);
                            setCurrentHoveredItem(i);
                          }
                        }}
                      >
                        <MenuLinkText>
                          {child.name}
                        </MenuLinkText>
                        {child.url && (
                          <Right16x16 />
                        )}
                      </HoverMenuLink>
                    ))}
                  </>
                )}
              </ScrollableFade>
            </SecondaryRightmost>
            {(
              mainMenu.selectedSecondaryMenuItem ?? header.secondaryMenuItems?.find(e => e.children?.length !== 0)
            )?.children.map(
              (child, i, a) =>
                child.imageUrl && (
                  <SecondaryRightmost
                    key={i}
                    css={{
                      top: headerHeight + margin,
                      height: `calc(100vh - ${headerHeight}px - ${margin * 2}px)`,
                      transform: !isOpen
                        ? `translateY(0px) translateX(calc(100% + ${margin}px))`
                        : `translateY(0px) translateX(calc(-100% - ${margin}px))`,
                      backgroundImage: `url(${child.imageUrl})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      opacity: i === currentHoveredItem ? 1 : i === lastHoveredItem ? 0.999 : 0, //Hack to ensure transition occurs. It works.
                      zIndex: i === currentHoveredItem ? 1 : undefined,
                    }}
                    //@ts-ignore
                    onTransitionEnd={() => {
                      if (currentHoveredItem === i) {
                        setLastHoveredItem(-1);
                      }
                    }}
                  />
                ),
            )}
          </>
        )}
      </Motion>
    </Overlay>
  );
};

export default connect(
  state => ({
    mainMenu: state.mainMenu,
    header: state.appShellData.header,
  }),
  dispatch => ({
    toggleMainMenu: (item: MainMenuItemType) => {
      dispatch(toggleMainMenu(item));
    },
    setSelectedSecondaryMenuItem(item: SecondaryMenuItem) {
      dispatch(setSelectedSecondaryMenuItem(item));
    },
  }),
)(DesktopSecondaryMenu);

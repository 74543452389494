import {
  MiniCartAction,
  MINI_CART_LOAD_SUCCESS,
} from './action-creators';
import { Action, CartType, MiniCartType } from 'Shared/State';

export default function (
  state: MiniCartType = null,
  action: Action,
): MiniCartType {
  switch (action.type) {
    case MINI_CART_LOAD_SUCCESS: {
      return Object.assign({}, (action as MiniCartAction).cart);
    }
    default:
      return state;
  }
}

import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import connect from 'Shared/connect';
import { formatPrice } from 'Shared/number-format';
import { LanguageCodes, translate } from '@polarnopyret/scope';
import * as style from 'Shared/Style';

type RequiredPropType = {
  sellingPrice: number;
  listPrice?: number;
  comparePrice?: number;
  currentPriceHigh?: number;
  currentPriceLow?: number;
  originalPriceHigh?: number;
  originalPriceLow?: number;
  comparePriceHigh?: number;
  comparePriceLow?: number;
  fontSize: number;
  fontSizePrevPrice?: number;
  fontWeight?: number;
  vertical?: boolean;
  spaceBetween?: boolean;
  className?: string;
  microData?: boolean;
  explicitCulture?: string;
  explicitCurrency?: string;
  noStyle?: boolean;
  subtraction?: boolean;
  displayPriceLabels?: boolean;
  rowBreakMainPrice?: boolean;
};

type PropType = RequiredPropType &
  StyledProps & {
    culture: string;
    currency: string;
    countryCode: string;
  };

const PrevPrice = styled.span({
  color: style.colors.monochrome.mediumGrey
});

export default styled(
  connect(state => ({
    culture: state.appShellData.culture,
    currency: state.appShellData.market.currency,
    countryCode: state.appShellData.context.countryCode,
  }))(
    class extends React.PureComponent<PropType> {
      render() {
        const {
          compose,
          sellingPrice,
          listPrice,
          comparePrice = 0,
          currentPriceLow,
          currentPriceHigh,
          originalPriceLow,
          originalPriceHigh,
          comparePriceLow = 0,
          comparePriceHigh = 0,
          microData,
          vertical,
          spaceBetween,
          noStyle,
          subtraction,
          fontSize,
          fontSizePrevPrice = fontSize,
          fontWeight = 500,
          displayPriceLabels = false,
          rowBreakMainPrice = false,
        } = this.props;

        const culture = this.props.explicitCulture || this.props.culture;
        const currency = this.props.explicitCurrency || this.props.currency;
        let hasDiscount = typeof listPrice === 'number' && sellingPrice < listPrice;
        let hasCompare = typeof listPrice === 'number' && comparePrice > 0 && comparePrice !== listPrice;

        const baseProps: React.HTMLAttributes<HTMLSpanElement> = {};
        const priceProps: React.HTMLAttributes<HTMLSpanElement> = {};

        let priceRender = formatPrice(sellingPrice, currency, culture);
        let priceRenderDiscount = formatPrice(listPrice, currency, culture);
        let priceRenderCompare = comparePrice > 0 && formatPrice(comparePrice, currency, culture);

        if (typeof currentPriceLow === 'number' && typeof currentPriceHigh === 'number') {
          hasDiscount = false;
          hasCompare = false;
          if (typeof originalPriceLow === 'number' && typeof originalPriceHigh === 'number') {
            if (currentPriceLow < originalPriceLow || currentPriceHigh < originalPriceHigh) {
              hasDiscount = true;
            }

            if (comparePriceLow > 0 && comparePriceLow !== originalPriceLow && comparePriceHigh > 0 && comparePriceHigh !== originalPriceHigh) {
              hasCompare = true;
            }
          }

          priceRender = formatPrice(currentPriceLow, currency, culture, ([LanguageCodes.Finnish, LanguageCodes.German] as String[]).includes(culture)) + " - " + formatPrice(currentPriceHigh, currency, culture);
          priceRenderDiscount = formatPrice(originalPriceLow, currency, culture, ([LanguageCodes.Finnish, LanguageCodes.German] as String[]).includes(culture)) + " - " + formatPrice(originalPriceHigh, currency, culture);
          priceRenderCompare = comparePrice > 0 && formatPrice(comparePriceLow, currency, culture, ([LanguageCodes.Finnish, LanguageCodes.German] as String[]).includes(culture)) + " - " + formatPrice(comparePriceHigh, currency, culture);
        }

        if (microData) {
          baseProps.itemProp = 'offers';
          baseProps.itemType = 'http://schema.org/Offer';
          baseProps.itemScope = true;
        }

        return (
          <styled.Span
            {...baseProps}
            css={compose({
              ...(vertical
                ? {
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'right',
                }
                : {
                  display: 'inline-block',
                }),
              fontSize: `${fontSize}px`,
            })}
          >
            {microData && <meta itemProp="priceCurrency" content={currency} />}
            <styled.Span
              className="sg-reduced-prices"
              {...priceProps}
              css={compose({
                ...(!noStyle && {
                  fontWeight: fontWeight,
                }),
                display: 'inline-block',
                ...(rowBreakMainPrice && (hasCompare || hasDiscount) && {
                  display: 'block',
                }),
                ...(vertical &&
                  spaceBetween && {
                  marginBottom: 8,
                  lineHeight: 1,
                }),
                ...(((hasDiscount || hasCompare) && !vertical) && {
                  marginRight: '8px',
                }),
              })}
            >
              <meta itemProp="price" content={sellingPrice?.toString()} />
              {subtraction && '-'}{priceRender}
            </styled.Span>
            {hasCompare && (
              <PrevPrice
                css={compose({
                  fontWeight: fontWeight === 500 ? 400 : fontWeight,
                  ...(vertical && {
                    display: 'block',
                  }),
                  lineHeight: spaceBetween ? 1 : '100%',
                  textDecoration: 'line-through',
                  ...((hasDiscount && !vertical) && {
                    marginRight: '8px',
                  }),
                  fontSize: `${fontSizePrevPrice}px`,
                })}
              >
                {subtraction && '- '}{displayPriceLabels && (translate('/site/price/abbr/previous') + ' ')}{priceRenderCompare}
              </PrevPrice>
            )}
            {hasDiscount && (
              <PrevPrice
                css={compose({
                  fontWeight: fontWeight === 500 ? 400 : fontWeight,
                  ...(vertical && {
                    display: 'block',
                  }),
                  lineHeight: spaceBetween ? 1 : '100%',
                  textDecoration: 'line-through',
                  fontSize: `${fontSizePrevPrice}px`,
                })}
              >
                {subtraction && '- '}{displayPriceLabels && (translate('/site/price/abbr/original') + ' ')}{priceRenderDiscount}
              </PrevPrice>
            )}
          </styled.Span>
        );
      }
    },
  ),
);

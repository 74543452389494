import React from 'react';
import { styled, StyledProps } from '@glitz/react';

export enum Ratio {
  OneToOne,
  OneToOneFill,
  ThreeToTwo,
  FourToThree,
  FiveToFour, // brothers product images
  EightToThree,
  NineToSixteen,
  SixteenToNine,
  FourteenToNinePortrait,
  FourtyToThirteen,
  Fill,
  ThreeToFour,
  TwoToThree,
  TwelveToTwentyThree,
  TwentyToThirteen,
}

type PropType = {
  ratio: Ratio;
  elementRef?: React.Ref<HTMLDivElement>;
};

export function calculateTopPadding(ratio: Ratio) {
  switch (ratio) {
    case Ratio.OneToOneFill:
    case Ratio.OneToOne:
      return '100%';
    case Ratio.ThreeToTwo:
      return `${(2 / 3) * 100}%`;
    case Ratio.FourToThree:
      return `${(3 / 4) * 100}%`;
    case Ratio.FiveToFour:
      return `${(5 / 4) * 100}%`;
    case Ratio.EightToThree:
      return `${(3 / 8) * 100}%`;
    case Ratio.SixteenToNine:
      return `${(9 / 16) * 100}%`;
    case Ratio.NineToSixteen:
      return `${(16 / 9) * 100}%`;
    case Ratio.FourteenToNinePortrait:
      return `${(14 / 9) * 100}%`;
    case Ratio.Fill:
      return `${(9 / 8) * 100}%`;
    case Ratio.FourtyToThirteen:
      return `${(13 / 40) * 100}%`;
    case Ratio.ThreeToFour:
      return `${(4 / 3) * 100}%`;
    case Ratio.TwoToThree:
      return `${(3 / 2) * 100}%`;
    case Ratio.TwelveToTwentyThree:
      return `${(23 / 12) * 100}%`;
    case Ratio.TwentyToThirteen:
      return `${(13 / 20) * 100}%`;
    default:
      return 0;
  }
}

export function calculateRatioHeight(ratio: Ratio) {
  switch (ratio) {
    case Ratio.OneToOneFill:
    case Ratio.OneToOne:
      return '100%';
    case Ratio.ThreeToTwo:
      return `${(2 / 3) * 100}%`;
    case Ratio.FourToThree:
      return `${(3 / 4) * 100}%`;
    case Ratio.FiveToFour:
      return `${(5 / 4) * 100}%`;
    case Ratio.EightToThree:
      return `${(3 / 8) * 100}%`;
    case Ratio.SixteenToNine:
      return `${(9 / 16) * 100}%`;
    case Ratio.FourteenToNinePortrait:
      return `${(14 / 9) * 100}%`;
    case Ratio.Fill:
      return `${(9 / 8) * 100}%`;
    case Ratio.FourtyToThirteen:
      return `${(13 / 40) * 100}%`;
    case Ratio.ThreeToFour:
      return `${(4 / 3) * 100}%`;
    case Ratio.TwoToThree:
      return `${(3 / 2) * 100}%`;
    case Ratio.TwelveToTwentyThree:
      return `${(23 / 12) * 100}%`;
    case Ratio.TwentyToThirteen:
      return `${(13 / 20) * 100}%`;
    default:
      return 0;
  }
}

export default styled(
  ({
    compose,
    ratio = Ratio.OneToOne,
    elementRef,
    ...restProps
  }: StyledProps & React.HTMLAttributes<HTMLDivElement> & PropType) => {
    let paddingTop;
    switch (ratio) {
      case Ratio.OneToOneFill:
      case Ratio.OneToOne:
        paddingTop = '100%';
        break;
      case Ratio.ThreeToTwo:
        paddingTop = `${(2 / 3) * 100}%`;
        break;
      case Ratio.FourToThree:
        paddingTop = `${(3 / 4) * 100}%`;
        break;
      case Ratio.FiveToFour:
        paddingTop = `${(5 / 4) * 100}%`;
        break;
      case Ratio.EightToThree:
        paddingTop = `${(3 / 8) * 100}%`;
        break;
      case Ratio.SixteenToNine:
        paddingTop = `${(9 / 16) * 100}%`;
        break;
      case Ratio.FourteenToNinePortrait:
        paddingTop = `${(14 / 9) * 100}%`;
        break;
      case Ratio.Fill:
        paddingTop = `${(9 / 8) * 100}%`;
        break;
      case Ratio.FourtyToThirteen:
        paddingTop = `${(13 / 40) * 100}%`;
        break;
      case Ratio.ThreeToFour:
        paddingTop = `${(4 / 3) * 100}%`;
        break;
      case Ratio.TwoToThree:
        paddingTop = `${(3 / 2) * 100}%`;
        break;
      case Ratio.TwelveToTwentyThree:
        paddingTop = `${(23 / 12) * 100}%`;
        break;
      default:
    }
    return (
      <styled.Div
        ref={elementRef}
        {...restProps}
        css={compose({
          overflow: 'hidden',
          ':before': {
            content: '""',
            float: 'left',
            paddingTop,
          },
        })}
      />
    );
  },
);

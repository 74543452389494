import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { Style } from '@glitz/type';
import { LinkProps as LinkPropType } from '@polarnopyret/scope';
import Link from 'Shared/Link';
import * as style from 'Shared/Style';
import appearanceFunc, { AppearanceType } from 'Shared/appearance';
export enum Appearance {
  Link,
  Bare,
  Full,
}

type BodyPropType = {
  appearance?: AppearanceType<Appearance>;
  inheritTextAlign?: boolean;
};

export type ButtonLinkPropType = LinkPropType & BodyPropType;

export type ButtonNotLinkPropType = React.ButtonHTMLAttributes<HTMLButtonElement> & BodyPropType;

// Should be union typed when this is within reach:
// https://github.com/Microsoft/TypeScript/issues/13526
export type PropType = Partial<ButtonLinkPropType> & ButtonNotLinkPropType;

function isLink(props: ButtonLinkPropType | ButtonNotLinkPropType): props is ButtonLinkPropType {
  return 'to' in props;
}

const ButtonLink = styled(
  ({
    compose,
    appearance,
    children,
    ...restProps
  }: StyledProps & ButtonLinkPropType) => (
    <Link
      {...restProps}
      css={compose()}
      // tslint:disable-next-line:jsx-no-lambda
      onClick={e => {
        if (restProps.onClick) {
          restProps.onClick(e);
        }
      }}
    >
      {children}
    </Link>
  ),
);

const ButtonNotLink = styled(
  ({
    compose,
    type = 'button',
    appearance,
    children,
    ...restProps
  }: StyledProps & ButtonNotLinkPropType) => (
    <styled.Button
    {...restProps}
    type={type}
    css={compose()}
    // tslint:disable-next-line:jsx-no-lambda
    onClick={e => {
      if (restProps.onClick) {
        restProps.onClick(e);
      }
    }}
  >
    {children}
  </styled.Button>
  ),
);

export default styled(function Button(props: PropType & StyledProps) {
  const { compose, inheritTextAlign, ...restProps } = props;
  const appear = appearanceFunc(restProps.appearance);
  
  const css: Style = {
    font: {
      family: 'inherit',
      size: 'inherit',
      style: 'inherit',
      variant: 'inherit',
      weight: 'bold',
    },
    lineHeight: 'inherit',
    border: { xy: { width: 0 } },
    display: 'inline-block',
    padding: { xy: 0 },
    userSelect: 'none',
    textDecoration: 'inherit',
    textAlign: inheritTextAlign ? 'inherit' : 'center',
    overflow: 'hidden',
    ...(!restProps.disabled && {
      cursor: 'pointer',
    }),
    ...(appear(Appearance.Bare) || appear(Appearance.Link)
      ? {
        color: 'inherit',
        backgroundColor: ['transparent', 'initial'],
        fontWeight: 'normal',
        ...(appear(Appearance.Link) && {
          textDecoration: 'none',
          color: style.colors.linkColors.default,
          ':hover': {
            textDecoration: 'underline',
          },
          ':focus': {
            textDecoration: 'underline',
          },
        }),
      }
      : {
        ...style.transition({ property: ['color', 'background', 'opacity'] }),
        minWidth: '150px',
        textTransform: 'uppercase',
      }),
    // Don't apply other themes if it's disabled
    ...(restProps.disabled && !appear(Appearance.Bare)
      ? {
        color: style.colors.monochrome.mediumGrey,
        cursor: 'default',
      }
      : {
        
      }),
    ...(appear(Appearance.Full) && {
      minWidth: '100%',
    }),
  };

  return (
    isLink(restProps) ? (
      <ButtonLink {...restProps} css={compose(css)} />
    ) : (
      <ButtonNotLink {...restProps} css={compose(css)} />
    )
  )
});
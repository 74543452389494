import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { styled } from '@glitz/react';
import { Style, StyleOrStyleArray } from '@glitz/type';
import * as style from 'Shared/Style';
import { SwipeLeft, SwipeLeftAlt2, SwipeRight, SwipeRightAlt2 } from './SwiperNav';
import Swiper, { FreeMode, Parallax, EffectCoverflow } from 'swiper';
import { HeadingLabel, ResponsiveSize } from 'Shared/SharedComponents/atoms/Typography';
import { CircleUnfilled8x8, CircleFilled8x8 } from 'Shared/Icons/Circle';
import { Right24x40 as RightSwirl } from 'Shared/Icons/ArrowSwirl';
import { iOSVersion, isIOS } from '@polarnopyret/scope';

export enum SlideEffect {
  Slide = 'slide',
  CoverFlow = 'coverflow',
}

type PropType = {
  isCompact: boolean;
  slidesPerView: number | "auto";
  spaceBetween: number;
  children?: ReactNode | undefined;
  listHeading?: string;
  showNav?: boolean;
  centerNav?: boolean;
  showSwirl?: boolean;
  navColor?: string;
  alternativeNav?: boolean;
  gridIndent?: boolean;
  navIndentLeft?: boolean;
  additionalMargin?: string;
  pagination?: boolean;
  pagingItemCount?: number;
  initialSlide?: number;
  slidesPerGroup?: number;
  slidesPerGroupSkip?: number;
  paginationContainerCss?: StyleOrStyleArray<Style>;
  pagingIconColor?: string;
  overflowHidden?: boolean;
  centerSlides?: boolean;
  freeMode?: boolean;
  rewind?: boolean;
  loop?: boolean;
  grabCursor?: boolean;
  height?: string;
  cssMode?: boolean;
  parallax?: boolean;
  vertical?: boolean;
  onSlideChange?: (swiper: any) => any;
  onIndexChange?: (swiper: any) => any;
  onInit?: (swiper: any) => any;
  sliderRef?: React.MutableRefObject<any>;
  slideEffect?: SlideEffect;
  coverFlowParams?: {
    rotate: number;
    stretch: number;
    depth: number;
    modifier: number;
    slideShadows: boolean;
  }
};

const FlexWrap = styled.div({
  display: 'flex',
});

const TopWrap = styled(FlexWrap, {
  justifyContent: 'space-between',
})

const HeadingText = styled(HeadingLabel, {
  marginBottom: '16px',
})

const PagingContainer = styled(FlexWrap, {
  height: '12px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '12px'
})

const CircleFilled = styled(CircleFilled8x8, {
  transition: {
    timingFunction: 'linear',
    duration: '0.1s',
    property: 'transform fill color',
  },
})

const CircleUnfilled = styled(CircleUnfilled8x8, {
  transition: {
    timingFunction: 'linear',
    duration: '0.1s',
    property: 'transform fill color',
  },
})

const SwiperLeftAlt2Wrap = styled.div({
  marginRight: '16px'
})

const Swirl = styled(RightSwirl, {
  margin: { x: '16px' }
})

const SwiperList = (props: PropType) => {
  const {
    isCompact,
    showNav = true,
    centerNav = false,
    alternativeNav = false,
    gridIndent = true,
    navIndentLeft = false,
    additionalMargin,
    pagination = false,
    pagingItemCount = 0,
    paginationContainerCss,
    pagingIconColor = style.colors.monochrome.black,
    overflowHidden = false,
    freeMode = true,
    rewind = false,
    loop = false,
    height,
    grabCursor = true,
    cssMode = false,
    initialSlide = 0,
    parallax = true,
    vertical = false,
    showSwirl = false,
    navColor,
    slideEffect = SlideEffect.Slide,
    centerSlides = false,
    coverFlowParams = {
      rotate: 0,
      stretch: 0,
      depth: 40,
      modifier: 8,
      slideShadows: false,
    }
  } = props;
  const color = navColor ? navColor : (props.slidesPerView === 1 || alternativeNav ? style.colors.monochrome.black : style.colors.monochrome.white);
  const swiperReference = props.sliderRef ? props.sliderRef : useRef(null);
  const swiperContainerRef = useRef(null);

  const [prevDisabled, setPrevDisabled] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [currentIndex, setSlideIndex] = useState(0);

  const validateSlideIndex = !isCompact && typeof (props.slidesPerView) === 'number' && props.slidesPerView % 1 !== 0;

  let swipeLeftMargin = gridIndent ? '-8.33vw' : '16px';
  let swipeRightMargin = gridIndent ? '0px' : '16px';
  if (additionalMargin) {
    swipeLeftMargin = `calc(${swipeLeftMargin}` + ` + ${additionalMargin})`;
    swipeRightMargin = `calc(${swipeRightMargin}` + ` + ${additionalMargin})`;
  } else if (gridIndent && navIndentLeft) {
    swipeLeftMargin = `calc(${swipeLeftMargin} + 16px)`;
  }

  let cssModeState = cssMode;

  try {
    //const iOSver = iOSVersion().toString();
    // if cssMode is true and not on iphone ios-version 16.0.1 set cssModeState to false
    //if (cssMode && iOSver !== '16.0.1') {
    if (cssMode && !isIOS()) {
      cssModeState = false;
    }
  }
  catch (e) {
    cssModeState = false;
  }

  const validateSwiper = (swiper: any) => {
    const slideCount = swiper.slides.length;
    const visibleSlideCount = swiper.visibleSlidesIndexes?.length ?? 0;

    if (slideCount > visibleSlideCount && !swiper.isEnd && swiper.visibleSlidesIndexes?.includes((swiper.slidesSizesGrid.length - 1))) {
      return true;
    }

    return false;
  }

  const slideChange = (swiper: any) => {
    if (swiper) {
      if (showNav) {
        if (validateSlideIndex && validateSwiper(swiper)) {
          // We are at the last visible slide, fix the index to show the complete slide.
          swiper.slideTo(swiper.activeIndex + props.slidesPerGroup);
          return;
        }

        setPrevDisabled(swiper.isBeginning);
        setNextDisabled(swiper.isEnd);
      }
      setSlideIndex(swiper.activeIndex);
    }
  }

  const reachBeginning = (swiper: any) => {
    if (showNav && swiper) {
      setPrevDisabled(true);
      setNextDisabled(false);
    }
  }

  const reachEnd = (swiper: any) => {
    if (showNav && swiper) {
      setPrevDisabled(false);
      setNextDisabled(true);
    }
  }

  const update = (swiper: any) => {
    if (swiper) {
      setPrevDisabled(swiper.isBeginning);
      setNextDisabled(swiper.isEnd);
    }
  }

  useEffect(() => {
    swiperReference.current = new Swiper(swiperContainerRef.current, {
      slidesPerView: props.slidesPerView,
      slidesPerGroup: props.slidesPerGroup ? props.slidesPerGroup : 1,
      spaceBetween: props.spaceBetween,
      speed: 600,
      freeMode: freeMode ? {
        enabled: true,
        sticky: false,
      } : false,
      coverflowEffect: slideEffect === SlideEffect.CoverFlow ? coverFlowParams : null,
      modules: [FreeMode, Parallax, EffectCoverflow],
      effect: slideEffect,
      parallax: parallax,
      grabCursor: grabCursor,
      rewind: rewind,
      loop: loop,
      cssMode: cssModeState,
      initialSlide: initialSlide,
      centeredSlides: centerSlides,
      direction: vertical ? 'vertical' : 'horizontal',
      on: {
        init: props.onInit,
        activeIndexChange: props.onIndexChange,
        slideChange: props.onSlideChange ? props.onSlideChange : slideChange,
        reachEnd: reachEnd,
        reachBeginning: reachBeginning,
        update: update,
      },
    });
  }, []);

  const changeSlide = (isNext: boolean) => {
    if (isNext) {
      swiperReference.current?.slideNext();
    } else {
      if (validateSlideIndex && nextDisabled) {
        let index = swiperReference.current?.activeIndex - props.slidesPerGroup;
        if (index < 1) {
          index = 1;
        }
        setSlideIndex(index);
        swiperReference.current?.slideTo(index);
      } else {
        swiperReference.current?.slidePrev();
      }
    }
  };

  const slideToIndex = (index: number) => {
    setSlideIndex(index);
    swiperReference.current?.slideTo(index);
  };

  const listHeading = !props.listHeading && ((showNav && alternativeNav && !centerNav) || showSwirl) ? '&nbsp;' : props.listHeading;

  return (
    <>
      {(props.listHeading || alternativeNav) && (
        <TopWrap
          css={{
            ...(showSwirl && {
              alignItems: 'end',
            }),
            ...(centerNav && {
              justifyContent: 'center',
              marginBottom: '24px'
            })
          }}
        >
          {listHeading && <HeadingText fontSize={ResponsiveSize.D14_M14} dangerouslySetInnerHTML={{ __html: listHeading }} />}
          {showNav && alternativeNav && (
            <FlexWrap>
              <SwiperLeftAlt2Wrap>
                <SwipeLeftAlt2
                  isCompact={isCompact}
                  previousDisabled={prevDisabled}
                  color={color}
                  onClick={() => changeSlide(false)}
                />
              </SwiperLeftAlt2Wrap>
              <div>
                <SwipeRightAlt2
                  isCompact={isCompact}
                  nextDisabled={nextDisabled}
                  color={color}
                  onClick={() => changeSlide(true)}
                />
              </div>
            </FlexWrap>
          )}
          {showSwirl && <Swirl />}
        </TopWrap>
      )}

      <styled.Div ref={swiperContainerRef} className="swiper"
        css={{
          ...(overflowHidden && {
            overflow: 'hidden'
          }),
          ...(height && {
            height: height,
          }),
          position: showNav && !alternativeNav ? 'relative' : 'static',
        }}
      >
        <div className="swiper-wrapper">{props.children}</div>
        {showNav && !alternativeNav && (
          <>
            <SwipeLeft
              isCompact={isCompact}
              color={color}
              sideMargin={swipeLeftMargin}
              previousDisabled={prevDisabled}
              onClick={() => changeSlide(false)}
            />
            <SwipeRight
              isCompact={isCompact}
              color={color}
              sideMargin={swipeRightMargin}
              nextDisabled={nextDisabled}
              onClick={() => changeSlide(true)}
            />
          </>
        )}
        {pagination && pagingItemCount > 1 && (
          <styled.Div
            css={{ ...paginationContainerCss }}
          >
            <PagingContainer>
              {Array.from(Array(pagingItemCount).keys()).map(index => {
                const CircleIcon = index === currentIndex ? CircleFilled : CircleUnfilled;
                return (
                  <CircleIcon
                    key={index}
                    onClick={() => slideToIndex(index)}
                    css={{
                      transform: index === currentIndex ? 'scale(1.5)' : 'scale(1)',
                      color: pagingIconColor,
                    }}
                  />
                )
              })
              }
            </PagingContainer>
          </styled.Div>
        )}
      </styled.Div>
    </>
  );
};

export default SwiperList;

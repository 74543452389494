import React, { useCallback, useEffect, useRef, useState } from 'react';
import { QuickSearchType } from 'Shared/State';
import { currentUrl, equalsUrl, pushState, URLX } from '@polarnopyret/scope/index';
import SearchPageViewModelType from 'Search/SearchPageViewModel.type';
import connect from 'Shared/connect';
import { loadPage } from '@polarnopyret/scope';
import {
  hideQuickSearch,
  loadQuickSearch,
  SEARCH_PAGE_LOAD_QUERY_NAME,
  SEARCH_QUERY_NAME,
  DELAY_SEARCH_IN_MS,
  showQuickSearch,
  loadEmptySearch,
  updateSelectedCompletion,
} from 'Search/action-creators';
import currentPageIsSearch from 'Search/current-page-is-search';
import { styled } from '@glitz/react';
import { inputStyle } from 'Shared/Fields/Text';
import * as style from 'Shared/Style';
import { ANIMATION_DURATION } from 'SiteLayout/Header/DesktopHeader';
import * as searchHistory from 'Search/search-history';
import { Search24x24, Search16x16 } from 'Shared/Icons/Search';

export const MOBILE_QUICKSEARCH_FORM_HEIGHT = 51;

type ConnectedPropType = {
  currentBreakpoint: number;
  currentPageIsSearch: boolean;
  quickSearch?: QuickSearchType;
  searchPageUrl: string;
  placeHolder: string;
};

type ConnectedDispatchType = {
  loadPage: (url: URLX, showSpinner: boolean, shouldUseResult?: (page: SearchPageViewModelType) => boolean) => void;
  loadQuickSearch: (searchText: string) => void;
  hideQuickSearch: (setFirstSuggestionAsSearchText?: boolean) => void;
  showQuickSearch: () => void;
  loadEmptySearch: (executeSearch: boolean) => void;
  updateSelectedCompletion: (index: number) => void;
};

export function useDebounce(effect: () => void, dependencies: React.DependencyList, delay: number) {
  const callback = useCallback(effect, dependencies);

  useEffect(() => {
    const timeout = setTimeout(callback, delay);
    return () => clearTimeout(timeout);
  }, [callback, delay]);
}

const MobileWrap = styled.div({
  display: 'flex', alignItems: 'center'
})

const MobileSeparator = styled.hr({
  position: 'relative',
  height: '2px',
  margin: { xy: 0 },
  border: { y: { width: 0 }, x: { width: 0 } },
  ...style.transition({ property: ['margin', 'background-color'], duration: `${ANIMATION_DURATION}ms` }),
})

const MobileInput = styled.input({
  ...inputStyle,
  paddingLeft: '0px',
  textAlign: 'center',
  ':focus': {
    textAlign: 'left',
    paddingLeft: '16px',
  },
  '::placeholder': {
    ...style.transition({ property: ['color'], duration: `${ANIMATION_DURATION}ms` }),
  },
  fontWeight: 450,
})

const MobileSearchIcon = styled(Search24x24, {
  marginLeft: '12px'
})

const DesktopInput = styled.input({
  ...inputStyle,
  '::placeholder': {
    ...style.transition({ property: ['color'], duration: `${ANIMATION_DURATION}ms` }),
  },
  ':focus': {
    color: style.colors.monochrome.black,
  },
  paddingLeft: '12px',
  paddingRight: '12px',
  fontWeight: 450,
})

const DesktopWrap = styled.div({
  display: 'flex', 
  alignItems: 'center',
  height: '100%',
})

const DesktopSearchIcon = styled(Search16x16, {
  cursor: 'pointer'
})

export const QuickSearchInputForm = connect(
  (state): ConnectedPropType => ({
    currentBreakpoint: state.currentBreakpoint,
    currentPageIsSearch: currentPageIsSearch(state.currentPage),
    quickSearch: state.quickSearch,
    searchPageUrl: state.appShellData.pages.searchPage?.url,
    placeHolder: state.appShellData.header.quickSearchPlaceHolder,
  }),
  (dispatch): ConnectedDispatchType => ({
    loadPage(url: URLX, showSpinner: boolean, shouldUseResponse?: (page: SearchPageViewModelType) => boolean) {
      dispatch(loadPage({ url, options: { hideSpinner: !showSpinner }, shouldUseResponse }));
    },
    loadQuickSearch(searchText: string) {
      dispatch(loadQuickSearch(searchText));
    },
    hideQuickSearch(setFirstSuggestionAsSearchText = true) {
      dispatch(hideQuickSearch(setFirstSuggestionAsSearchText));
    },
    showQuickSearch() {
      dispatch(showQuickSearch());
    },
    loadEmptySearch(executeSearch: boolean) {
      dispatch(loadEmptySearch(executeSearch));
    },
    updateSelectedCompletion(index: number) {
      dispatch(updateSelectedCompletion(index));
    },
  }),
)((props: any) => {
  const {
    loadPage,
    loadQuickSearch,
    hideQuickSearch,
    showQuickSearch,
    loadEmptySearch,
    updateSelectedCompletion,
    quickSearch,
    searchPageUrl,
    placeHolder,
    placeholderColor,
    inputPlaceHolderFocusColor,
    fg,
    bg,
    isScrolled,
    isMobile,
  } = props;

  // mobile props needed, fg, bg, isScrolled

  const inputRef = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useState('');

  useDebounce(
    () => {
      loadQuickSearch(searchValue);
    },
    [searchValue],
    DELAY_SEARCH_IN_MS,
  );

  useEffect(() => {
    setSearchValue(quickSearch.searchText);
    if (inputRef?.current) inputRef.current.value = quickSearch.searchText;
  }, [quickSearch?.searchText]);

  const onInputFocus = () => {
    showQuickSearch();
    loadEmptySearch(true);
  };

  const searchUrl = (searchText: string, preview: boolean) => {
    const url = new URLX(searchPageUrl);
    url.searchParams.set(SEARCH_QUERY_NAME, searchText);
    if (preview) {
      url.hiddenParams.set(SEARCH_PAGE_LOAD_QUERY_NAME, String(true));
    }
    return url;
  };

  const loadResults = (useSuggestion: boolean) => {
    const query = useSuggestion ? quickSearch.suggestion || quickSearch.searchText : quickSearch.searchText;

    searchHistory.add(query);

    const newUrl = searchUrl(query, true);

    // Note that we perform the page load regardless of whether the same result is already loaded.
    // This is because we want to load it without the preview parameter to notify eSales of the actual search.
    if (equalsUrl(currentUrl(), newUrl)) {
      loadPage(newUrl, false);
    } else {
      go(query);
    }

    inputRef.current && inputRef.current.blur();
  };

  const go = (searchText: string) => {
    if (searchText.length) {
      const newUrl = searchUrl(searchText, false);
      if (!equalsUrl(currentUrl(), newUrl)) {
        pushState(newUrl);
      }
    }
  };

  const gotoSearch = (e: React.KeyboardEvent<HTMLElement>) => {
    if ((e.target as HTMLElement).tagName.toLowerCase() === 'input' && (e.keyCode || e.which || e.charCode || e.key || 0) === 13) {
      loadResults(false);  
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    hideQuickSearch(false);
    loadResults(false);
  };

  if (isMobile) {
    useEffect(() => {
      inputRef.current && quickSearch?.isOpen && inputRef.current.focus();
    }, [quickSearch?.isOpen]);
  }

  return (
    <>
      {isMobile ? (
        <styled.Form
          css={{
            backgroundColor: bg,
            clipPath: quickSearch?.isOpen || !isScrolled ? 'inset(0% 0% 0% 0%)' : 'inset(0% 0% 100% 0%)',
            ...style.transition({ property: ['color', 'clip-path'], duration: `${ANIMATION_DURATION}ms` }),
          }}
          onSubmit={onSubmit}
        >
          <MobileWrap>
            <MobileSearchIcon
              css={{ color: placeholderColor }}
              onClick={() => {
                inputRef.current && inputRef.current.focus();
              }}
            />
            <MobileInput
              css={{
                ':focus': {
                  '::placeholder': {
                    color: inputPlaceHolderFocusColor,
                  },
                },
                '::placeholder': {
                  color: fg,
                },
              }}
              ref={inputRef}
              id="test-search-input"
              type="search"
              name="q"
              autoComplete="off"
              placeholder={placeHolder}
              onChange={e => {
                setSearchValue(e.target.value);
              }}
              onFocus={onInputFocus}
            />
          </MobileWrap>
          <MobileSeparator
            css={{
              backgroundColor: fg,
            }}
          />
        </styled.Form>
      ) : (
        <form onSubmit={onSubmit}>
          <DesktopWrap>
            <DesktopSearchIcon
              css={{ color: placeholderColor, }}
              onClick={() => {
                inputRef.current && inputRef.current.focus();
              }}
            />
            <DesktopInput
              css={{
                color: placeholderColor,
                '::placeholder': {
                  color: placeholderColor,
                },
                ':focus': {
                  '::placeholder': {
                    color: inputPlaceHolderFocusColor,
                  },
                },
              }}
              ref={inputRef}
              id="test-search-input"
              type="search"
              name="q"
              autoComplete="off"
              placeholder={placeHolder}
              onChange={e => {
                setSearchValue(e.target.value);
              }}
              onKeyPress={(e) => gotoSearch(e)}
              onFocus={onInputFocus}
            />
          </DesktopWrap>
        </form>
      )}
    </>
  );
});

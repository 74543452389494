import { Link, URLX } from '@polarnopyret/scope';
import { styled, applyClassName } from '@glitz/react';

export default styled(applyClassName(Link));

export const isPdfLink = (url: string | URLX | Scope.UrlViewModel) => {
  if (typeof url === 'string') {
    return url.includes('.pdf');
  } else if ((url as URLX).pathname) {
    return (url as URLX).pathname.includes('.pdf');
  } else {
    return (url as Scope.UrlViewModel).url.includes('.pdf');
  }
};

export const isDocumentLink = (url: string | URLX | Scope.UrlViewModel) => {
  if (typeof url === 'string') {
    return containsDocumentType(url);
  } else if ((url as URLX).pathname) {
    return containsDocumentType((url as URLX).pathname);
  } else {
    return containsDocumentType((url as Scope.UrlViewModel).url);
  }
};

const containsDocumentType = function (path: string) {
  const extensions = ['.pdf', '.xls', '.xlsx', '.doc', '.docx'];
  let result = false;
  extensions.forEach(ext => {
    if (path.includes(ext)) {
      result = true;
    }
  })

  return result;
}
import { isBrowser, getLocalStorageItem, setLocalStorageItem } from '@polarnopyret/scope';

const LOCALSTORAGE_KEY = 'search_history';
const HISTORY_LENGTH = 30;

const searchHistory = deserialize();

export function find(searchWords: string[]) {
  searchWords = searchWords.filter(w => w.trim().length > 1);
  if (!searchWords.length) {
    return searchHistory.slice();
  }

  return searchHistory.slice().filter(item => {
    return !!searchWords
      .filter(w => w.length > 1)
      .find(searchWord => item.toLowerCase().indexOf(searchWord.toLowerCase()) !== -1);
  });
}

export function add(item: string) {
  item = item.trim();
  if (item === '' || searchHistory.find(s => s === item)) {
    return;
  }
  searchHistory.unshift(item);
  if (searchHistory.length > HISTORY_LENGTH) {
    searchHistory.splice(HISTORY_LENGTH);
  }
  serialize();
}

function serialize() {
  if (!isBrowser()) {
    return;
  }
  setLocalStorageItem(LOCALSTORAGE_KEY, searchHistory);
}

function deserialize() {
  if (!isBrowser()) {
    return [];
  }
  return (getLocalStorageItem(LOCALSTORAGE_KEY) || []) as string[];
}

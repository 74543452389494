import {
  appShellReducer,
  breakpointReducer,
  currentPageReducer,
  spinnerReducer,
  themeReducer,
} from '@polarnopyret/scope';
import checkoutPageReducer from 'Checkout/Pages/Checkout/reducer';
import variationPageReducer from 'Product/reducer';
import cartReducer from 'Cart/reducer';
import miniCartReducer from 'Cart/miniCartReducer';
import mainMenuReducer from 'SiteLayout/MainMenu/reducer';
import quickSearchReducer from 'Search/reducer';
import searchPageReducer from 'Search/page-reducer';
import footerReducer from 'SiteLayout/Footer/reducer';
import headerReducer from 'SiteLayout/Header/reducer';
import currentUserReducer from 'Account/reducer';
import StateType, { Action } from './State';
import deepFreeze from './deep-freeze';
import uiReducer from 'Shared/uiReducer';
import wishlistReducer from 'WishList/wishlistReducer';
import { PageType } from 'Shared/State';

export default (state: StateType, action: Action): StateType => {
  const appShellData = appShellReducer(state.appShellData, action);
  // We deep freeze the state to make sure we don't accidentally mutate it
  // Note that freezing objects is quite expensive, so we only do it in
  // dev builds
  const currentPageState = pagingReducer(searchPageReducer(
    checkoutPageReducer(
      variationPageReducer(currentPageReducer(state.currentPage, action, appShellData), state.currentPage, action),
      action,
    ),
    action,
  ), action);

  return deepFreeze({
    appShellData,
    currentBreakpoint: breakpointReducer(state.currentBreakpoint, action, appShellData),
    currentPage: currentPageState,
    cart: cartReducer(state.cart, action, currentPageState, appShellData),
    miniCart: miniCartReducer(state.miniCart, action),
    mainMenu: mainMenuReducer(state.mainMenu, action, appShellData),
    header: headerReducer(state.header, action, appShellData),
    quickSearch: quickSearchReducer(state.quickSearch, action, currentPageState),
    footer: footerReducer(state.footer, action, appShellData),
    currentUser: currentUserReducer(state.currentUser, action, appShellData, currentPageState),
    currentTheme: themeReducer(state.currentTheme, state.appShellData, action),
    spinner: spinnerReducer(state.spinner, action),
    UI: uiReducer(state.UI, action),
    wishlist: wishlistReducer(state.wishlist || state.appShellData.wishList, action),
  });
};

export const PAGING_SUCCESS = 'PAGING_SUCCESS';
export type PagingAction = Action & { canonicalUrl: string };

export function updateCanonicalUrl(canonicalUrl: string) {
  return {
    type: PAGING_SUCCESS,
    canonicalUrl: canonicalUrl,
  } as PagingAction;
}

function pagingReducer (state: PageType = null, action: Action): PageType {
  const currentPage = state as PageType;
  switch (action.type) {
    case PAGING_SUCCESS: {
      const pagingAction = action as PagingAction;
      return Object.assign({}, currentPage, {
        canonicalUrl: pagingAction.canonicalUrl,
      });
    }
    default:
      return currentPage;
  }
}

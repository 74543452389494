import {
  get,
  post,
  URLX,
  addUserLog,
  onCrossWindow,
  emitCrossWindow,
  LoadFailureAction,
  LINK_IDENTIFIER_HEADER,
  REQUEST_ACTION_HEADER,
  ADD_TO_CART_ACTION,
} from '@polarnopyret/scope';
import deepmerge from 'deepmerge';
import CartViewModel from '../Cart/Models/CartViewModel.type';
import State, { Action, Dispatch, CartType, CartDiffType } from 'Shared/State';
import { batchActions } from 'redux-batched-actions';
import { addToCart as gtmAddToCart, removeFromCart as gtmRemoveFromCart } from '../TrackingInformation';
import { CartEventLocation } from '../TrackingInformation/data-layer-actions';
import MiniCartViewModel from 'Cart/Models/MiniCartViewModel.type';
import CartItemViewModel from 'Cart/Models/CartItemViewModel.type';
import { SET_ADDED_TO_CART_MODAL, SetAddedToCartModalAction } from 'Shared/uiReducer';

export const CART_LOAD = 'CART_LOAD';
export const CART_LOAD_SUCCESS = 'CART_LOAD_SUCCESS';
export const MINI_CART_LOAD_SUCCESS = 'MINI_CART_LOAD_SUCCESS';
export const CART_LOAD_FAILURE = 'CART_LOAD_FAILURE';
export const CART_INVALID_QUANTITY = 'CART_INVALID_QUANTITY';
export const CART_OPTIMISTIC_UPDATE = 'CART_OPTIMISTIC_UPDATE';
export const CART_CHANGES_ACCEPTED = 'CART_CHANGES_ACCEPTED';
export const CART_CLEAR_RECOMMENDATIONS = 'CART_CLEAR_RECOMMENDATIONS';

export type CartAction = Action & {
  cart: CartViewModel;
};

export type MiniCartAction = Action & {
  cart: MiniCartViewModel;
};

export type CartOptimisticUpdateAction = Action & {
  diff: CartDiffType;
};

export type CartChangesAcceptedAction = Action & {
  changes: CartDiffType[];
};

export function rejectInvalidQuantity(code: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: CART_INVALID_QUANTITY,
      code,
    } as Action);
    return Promise.reject(null);
  };
}

export function addToCart(
  code: string,
  quantity: number,
  ticket: string,
  location: CartEventLocation,
  currentCategory: string,
  colorProductCode: string,
  isCheckoutPage: boolean = false,
  showModal?: boolean,
) {
  if (!isValidQuantity(quantity)) {
    return rejectInvalidQuantity(code);
  }
  return sendCartRequest(
    cart => {
      const existingItem = cart?.items?.find(i => i.code === code);
      const actualQuantity = (existingItem && existingItem.quantity + quantity) || quantity;
      const key = formatLineItemId(existingItem);

      return {
        items: {
          [key]: {
            lineItemId: existingItem ? existingItem.lineItemId : 0,
            newQuantity: actualQuantity,
            previousQuantity: existingItem ? existingItem.quantity : 0,
            code,
            currentCategory,
            colorProductCode,
          },
        },
      } as CartDiffType;
    },
    location,
    ticket,
    isCheckoutPage,
    showModal,
    code
  );
}

export function updateCartItemQuantity(lineId: number, code: string, quantity: number, location: CartEventLocation) {
  if (!isValidQuantity(quantity)) {
    return rejectInvalidQuantity(code);
  }
  return sendCartRequest(cart => {
    const existingItem = cart.items?.find(i => i.lineItemId === lineId);
    const key = formatLineItemId(existingItem);
    return {
      id: key,
      items: { [key]: { lineItemId: lineId, newQuantity: quantity, previousQuantity: existingItem?.quantity ?? 0, code } },
    } as CartDiffType;
  }, location);
}

export function removeCartItem(lineId: number, code: string, location: CartEventLocation) {
  return sendCartRequest(cart => {
    const existingItem = cart.items?.find(i => i.lineItemId === lineId);
    const key = formatLineItemId(existingItem);
    return { items: { [key]: { lineItemId: lineId, code, newQuantity: 0, previousQuantity: existingItem?.quantity ?? 0 } } } as CartDiffType;
  }, location);
}

let currentCartUpdate = Promise.resolve(null);

onCrossWindow(['cart-modified', 'checkout-modified'], store => {
  store.dispatch({
    type: CART_LOAD,
  } as Action);

  const updateCart = () => {
    const url = new URLX('/cart');
    get(url)
      .then(r => r.json())
      .then((json: CartViewModel) => {
        store.dispatch({
          type: CART_LOAD_SUCCESS,
          cart: json,
        } as CartAction);
      })
      .catch(e => {
        console.error(e);
        store.dispatch({ type: CART_LOAD_FAILURE, error: e, url } as LoadFailureAction);

        return Promise.reject(e);
      });
  };
  currentCartUpdate = currentCartUpdate.then(updateCart, updateCart);
});

let pendingTickets: string[] = [];

function sendCartRequest(
  diffOrCreator: CartDiffType | ((cart: CartType) => CartDiffType),
  location: CartEventLocation,
  ticket: string = null,
  isCheckoutPage: boolean = false,
  showModal?: boolean,
  code?: string,
  loadFull: boolean = true,
) {
  if (ticket) {
    pendingTickets.push(ticket);
  }
  return (dispatch: Dispatch, getState: () => State) => {
    let diff: CartDiffType;
    if (typeof diffOrCreator === 'function') {
      diff = (diffOrCreator as (cart: CartType) => CartDiffType)(getState().cart);
    } else {
      diff = diffOrCreator as CartDiffType;
    }
    diff.id = Math.random().toString();

    dispatch(
      batchActions([
        {
          type: CART_OPTIMISTIC_UPDATE,
          diff,
        } as CartOptimisticUpdateAction,
        {
          type: CART_LOAD,
        } as Action,
      ]),
    );

    const sendNextRequest = (): Promise<any> => {
      const state = getState().cart;
      const currentPendingChanges = state.pendingChanges;
      if (!currentPendingChanges.length) {
        return Promise.resolve();
      }

      let totalDiff: { items?: Object; id?: number; isCheckoutPage?: boolean } = {};
      currentPendingChanges.forEach(r => (totalDiff = deepmerge(totalDiff, r)));

      if (isCheckoutPage) {
        totalDiff.isCheckoutPage = true;
      }

      let headers: { [name: string]: string };
      if (pendingTickets.length) {
        const tickets = pendingTickets.join(',');
        pendingTickets = [];

        headers = {
          [LINK_IDENTIFIER_HEADER]: tickets,
          [REQUEST_ACTION_HEADER]: ADD_TO_CART_ACTION,
        };
      }

      const cartUrl = loadFull ? '/cart/update?type=100' : '/cart/update';
      const url = new URLX(cartUrl);

      return post(url, totalDiff, headers)
        .then(r => r.json())
        .then((json: MiniCartViewModel) => {
          const { cartViewModel } = json;
          if (cartViewModel.cartUpdateResult && cartViewModel.cartUpdateResult.addedItems) {
            if (cartViewModel.cartUpdateResult.addedItems.length > 0) {
              gtmAddToCart(cartViewModel.cartUpdateResult.addedItems, location);
            }
          } else {
            console.error('cartViewModel.cartUpdateResult ->', cartViewModel.cartUpdateResult);
          }
          if (cartViewModel.cartUpdateResult && cartViewModel.cartUpdateResult.removedItems) {
            if (cartViewModel.cartUpdateResult.removedItems.length > 0) {
              gtmRemoveFromCart(cartViewModel.cartUpdateResult.removedItems, location);
            }
          } else {
            console.error('cartViewModel.cartUpdateResult ->', cartViewModel.cartUpdateResult);
          }
          dispatch(
            batchActions(
              showModal
                ? [
                  {
                    type: CART_CHANGES_ACCEPTED,
                    changes: currentPendingChanges,
                  } as CartChangesAcceptedAction,
                  {
                    type: CART_LOAD_SUCCESS,
                    cart: cartViewModel,
                  } as CartAction,
                  {
                    type: SET_ADDED_TO_CART_MODAL,
                    code: code,
                    //message: cartViewModel.cartSumInformation,
                    miniCart: json,
                  } as SetAddedToCartModalAction,
                ]
                : [
                  {
                    type: CART_CHANGES_ACCEPTED,
                    changes: currentPendingChanges,
                  } as CartChangesAcceptedAction,
                  {
                    type: CART_LOAD_SUCCESS,
                    cart: cartViewModel,
                  } as CartAction,
                  {
                    type: MINI_CART_LOAD_SUCCESS,
                    cart: json,
                  } as MiniCartAction,
                ],
            ),
          );

          return json.updateResult;
          //emitCrossWindow('cart-modified');
        })
        .catch(e => {
          console.error(e);
          dispatch({ type: CART_LOAD_FAILURE, error: e, url } as LoadFailureAction);
          return Promise.reject(e);
        });
    };
    currentCartUpdate = currentCartUpdate.then(sendNextRequest, sendNextRequest);
    return currentCartUpdate;
  };
}

export function isValidQuantity(quantity: number, maxQuantity?: number) {
  if (typeof quantity !== 'number') {
    return false;
  }
  return quantity >= 0 && quantity <= (maxQuantity || 99);
}

export function clearRecommendations() {
  return {
    type: CART_CLEAR_RECOMMENDATIONS,
  } as Action;
}

export function getMinicart() {
  return fetch('/cart?type=100')
    .then(r => r.json())
    .then((json: MiniCartViewModel) => {
      return json;
    })
    .catch(e => {
      console.error(e);
      return Promise.reject(e);
    });
}

export function formatLineItemId(item: CartItemViewModel) {
  if (item) {
    return 'line' + item.lineItemId;
  }

  return 'line-0';
}
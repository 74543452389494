import { URLX } from '@polarnopyret/scope';

const getImageBreakPoint = () => {
  if (!__BROWSER__) {
    return 2;
  }

  if (window.matchMedia('(max-width: 480px)').matches) {
    return 0;
  }

  if (window.matchMedia('(max-width: 1024px)').matches) {
    return 1;
  }

  return 2;
};

// export enum Size {
//   Thumb,
//   Tiny,
//   Small,
//   Medium,
//   Large,
//   Huge,
//   Gigantic,
//   Original,
// }

enum ResizePreset {
  ListItemTiny,
  ListItemLarge,
  QuickSearchItemTiny,
  QuickSearchItemLarge,
  CmsListItemTiny,
  CmsListItemLarge,
  MagazineListItemTiny,
  MagazineListItemLarge,
  MagazineListItemThumb,
  MagazineTopItemTiny,
  MagazineTopItemLarge,
  SquareListItemTiny,
  SquareListItemLarge,
  FullWidthTiny,
  FullWidthMedium,
  FullWidthLarge,
  HalfWidthTiny,
  HalfWidthMedium,
  HalfWidthLarge,
  EditorTiny,
  EditorMedium,
  EditorLarge,
  ItemTiny,
  ItemMedium,
  ItemLarge,
  ItemZoomTiny,
  ItemZoomLarge,
  ItemThumb,
  ItemThumbLarge,
}

export enum Preset {
  ListItem,
  QuickSearchItem,
  FullWidth,
  HalfWidth,
  Editor,
  CmsListItem,
  MagazineListItem,
  MagazineListItemThumb,
  MagazineTopItem,
  SquareListItem,
  Item,
  ItemZoom,
  ItemThumb,
  ItemThumbLarge,
}
interface PresetMapping {
  bp: number;
  preset: ResizePreset;
}

type CompiledSizeTypes = { [key: number]: PresetMapping[] };
//type SizesType = { [key: number]: number };
type PresetsType = { [key: number]: string };

// export const getSizes = (): SizesType => ({
//   [Size.Thumb]: 160,
//   [Size.Tiny]: 320,
//   [Size.Small]: 640,
//   [Size.Medium]: 960,
//   [Size.Large]: 1280,
//   [Size.Huge]: 1920,
//   [Size.Gigantic]: 3200,
// });

const resizeTypes = (): CompiledSizeTypes => ({
  [Preset.QuickSearchItem]: [
    { bp: 0, preset: ResizePreset.QuickSearchItemTiny },
    { bp: 2, preset: ResizePreset.QuickSearchItemLarge },
  ],
  [Preset.ListItem]: [
    { bp: 0, preset: ResizePreset.ListItemTiny },
    { bp: 2, preset: ResizePreset.ListItemLarge },
  ],
  [Preset.FullWidth]: [
    { bp: 0, preset: ResizePreset.FullWidthTiny },
    { bp: 1, preset: ResizePreset.FullWidthMedium },
    { bp: 2, preset: ResizePreset.FullWidthLarge },
  ],
  [Preset.HalfWidth]: [
    { bp: 0, preset: ResizePreset.HalfWidthTiny },
    { bp: 1, preset: ResizePreset.HalfWidthMedium },
    { bp: 2, preset: ResizePreset.HalfWidthLarge },
  ],
  [Preset.Editor]: [
    { bp: 0, preset: ResizePreset.EditorTiny },
    { bp: 1, preset: ResizePreset.EditorMedium },
    { bp: 2, preset: ResizePreset.EditorLarge },
  ],
  [Preset.MagazineTopItem]: [
    { bp: 0, preset: ResizePreset.MagazineTopItemTiny },
    { bp: 2, preset: ResizePreset.MagazineTopItemLarge },
  ],
  [Preset.MagazineListItem]: [
    { bp: 0, preset: ResizePreset.MagazineListItemTiny },
    { bp: 2, preset: ResizePreset.MagazineListItemLarge },
  ],
  [Preset.CmsListItem]: [
    { bp: 0, preset: ResizePreset.CmsListItemTiny },
    { bp: 2, preset: ResizePreset.CmsListItemLarge },
  ],
  [Preset.MagazineListItemThumb]: [
    { bp: 0, preset: ResizePreset.MagazineListItemThumb },
  ],
  [Preset.SquareListItem]: [
    { bp: 0, preset: ResizePreset.SquareListItemTiny },
    { bp: 2, preset: ResizePreset.SquareListItemLarge },
  ],
  [Preset.Item]: [
    { bp: 0, preset: ResizePreset.ItemTiny },
    { bp: 1, preset: ResizePreset.ItemMedium },
    { bp: 2, preset: ResizePreset.ItemLarge },
  ],
  [Preset.ItemZoom]: [
    { bp: 0, preset: ResizePreset.ItemZoomTiny },
    { bp: 2, preset: ResizePreset.ItemZoomLarge },
  ],
  [Preset.ItemThumb]: [
    { bp: 0, preset: ResizePreset.ItemThumb },
  ],
  [Preset.ItemThumbLarge]: [
    { bp: 0, preset: ResizePreset.ItemThumbLarge },
  ],
});

export const getPresets = (): PresetsType => ({
  [ResizePreset.QuickSearchItemTiny]: "qs-item-tiny",
  [ResizePreset.QuickSearchItemLarge]: "qs-item-large",
  [ResizePreset.ListItemTiny]: "list-item-tiny",
  [ResizePreset.ListItemLarge]: "list-item-large",
  [ResizePreset.CmsListItemTiny]: "cms-list-item-tiny",
  [ResizePreset.CmsListItemLarge]: "cms-list-item-large",
  [ResizePreset.MagazineListItemTiny]: "mz-list-item-tiny",
  [ResizePreset.MagazineListItemLarge]: "mz-list-item-large",
  [ResizePreset.MagazineListItemThumb]: "mz-list-item-thumb",
  [ResizePreset.MagazineTopItemTiny]: "mz-top-item-tiny",
  [ResizePreset.MagazineTopItemLarge]: "mz-top-item-large",
  [ResizePreset.SquareListItemTiny]: "sq-list-item-tiny",
  [ResizePreset.SquareListItemLarge]: "sq-list-item-large",
  [ResizePreset.FullWidthTiny]: "fw-tiny",
  [ResizePreset.FullWidthMedium]: "fw-medium",
  [ResizePreset.FullWidthLarge]: "fw-large",
  [ResizePreset.HalfWidthTiny]: "hw-tiny",
  [ResizePreset.HalfWidthMedium]: "hw-medium",
  [ResizePreset.HalfWidthLarge]: "hw-large",
  [ResizePreset.EditorTiny]: "editor-tiny",
  [ResizePreset.EditorMedium]: "editor-medium",
  [ResizePreset.EditorLarge]: "editor-large",
  [ResizePreset.ItemTiny]: "item-tiny",
  [ResizePreset.ItemMedium]: "item-medium",
  [ResizePreset.ItemLarge]: "item-large",
  [ResizePreset.ItemZoomTiny]: "item-zoom-tiny",
  [ResizePreset.ItemZoomLarge]: "item-zoom-large",
  [ResizePreset.ItemThumb]: "item-thumb",
  [ResizePreset.ItemThumbLarge]: "item-thumb-large",
});

const queryStringPreset = function (preset: Preset) {
  const sorted = resizeTypes()[preset]?.sort((first, second) => 0 - (second.bp > first.bp ? -1 : 1));
  const selected = sorted.find(x => getImageBreakPoint() >= x.bp)?.preset;
  const presets = getPresets();
  return String(presets[selected]);
}

export function getImagePreset(preset?: Preset) {
  if (preset >= 0) {
    return queryStringPreset(preset);
  }
  return '';
}

export function appendImagePreset(imageUrl?: string, preset?: Preset) {
  if (imageUrl) {
    const url = new URLX(imageUrl);
    if (preset && preset >= 0) {
      url.searchParams.set('preset', queryStringPreset(preset));
    }
    return url.href;
  }

  return '';
}

// export function getPresetFromWidth(estimatedWidth: number, availableSizes: SizesType): Size {
//   return parseInt(Object.keys(availableSizes).find(sizeKey => availableSizes[sizeKey] >= estimatedWidth), 10);
// }

import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { styled, StyledProps } from '@glitz/react';
import { Style, StyleOrStyleArray } from '@glitz/type';
import { Close16x16, Close24x24 } from 'Shared/Icons/Close';
import { Plus24x24 } from 'Shared/Icons/Plus';
import connect from 'Shared/connect';
import Overlay from 'Shared/Overlay';
import * as style from 'Shared/Style';
import { isCompact } from 'Shared/Viewport';
import {
  H2,
  Paragraph,
  ResponsiveSize,
  ResponsiveLineHeight,
  TextLabel,
} from 'Shared/SharedComponents/atoms/Typography';
import { StyledDecorator } from '@glitz/react/types/styled/decorator';
import { createGridClasses, createOffsetGridClassLarge, createOffsetGridClassTiny } from 'Shared/grid';
import { GridWrap } from 'Shared/PageLayout';
import { SetModalIsOpen } from 'Shared/uiReducer';

const MOBILE_MARGIN_TOP = 6;
const MOBILE_MARGIN_BOTTOM = 10;
const DESKTOP_MARGIN = 8;
const MOBILE_GRID_ADJUSTMENT = '-4px';
const DESKTOP_GRID_ADJUSTMENT = '-8px';
const BLOCK_SHRINK_CLASS = 'block-shrink';

type RequiredPropType = {
  modalIsShown: boolean;
  hideModal: () => void;
  children: ReactNode;
  paneContent?: ReactNode;
  backgroundContent?: ReactNode;
  backgroundColor?: string;
  paneBackgroundColor?: string;
  widthInColumns?: number;
  paneWidthInColumns?: number;
  transitionTime?: number;
  isOnLeft?: boolean;
  paneContentShown?: boolean;
  paneFitContent?: boolean;
  shrinkableOnMobile?: boolean;
  shrunkOnMobile?: boolean;
  backgroundDisabled?: boolean;
  zIndex?: number;
  childZIndex?: number;
};

type ConnectedPropType = {
  currentBreakpoint: number;
  headerHeight: number;
  setModalIsOpen: (isOpen: boolean) => void;
  setMobileModalShrunk?: (shrunk: boolean) => void;
};

type ModalPropType = RequiredPropType & ConnectedPropType;

const ModalWrap = styled(GridWrap, {
  position: 'fixed',
  left: 0,
  right: 0,
  height: 0,
})

const BaseDiv = styled.div({
  borderRadius: '8px',
  overflow: 'hidden',
})

const Modal = ({
  modalIsShown,
  hideModal,
  children,
  paneContent,
  backgroundContent,
  backgroundColor = style.colors.monochrome.white,
  paneBackgroundColor = style.colors.monochrome.white,
  widthInColumns = 3,
  paneWidthInColumns = 3,
  transitionTime = 500,
  isOnLeft = false,
  paneContentShown = true,
  paneFitContent = false,
  shrinkableOnMobile = false,
  shrunkOnMobile = false,
  backgroundDisabled = true,
  currentBreakpoint,
  headerHeight,
  zIndex = style.ZIndex.Menu,
  childZIndex = -1,
  setModalIsOpen,
  setMobileModalShrunk,

}: ModalPropType) => {
  const isMobile = isCompact(currentBreakpoint);

  const topMargin = isMobile ? `${headerHeight + MOBILE_MARGIN_TOP}px` : `${headerHeight + DESKTOP_MARGIN}px`;
  const bottomMargin = isMobile ? `${MOBILE_MARGIN_BOTTOM}px` : `${DESKTOP_MARGIN}px`;
  const gridAdjustment = isMobile ? MOBILE_GRID_ADJUSTMENT : DESKTOP_GRID_ADJUSTMENT;
  const modalHeight = isMobile
    ? `calc(100vh - calc(100vh - 100%) - ${headerHeight + MOBILE_MARGIN_TOP + MOBILE_MARGIN_BOTTOM}px)`
    : `calc(100vh - calc(100vh - 100%) - ${headerHeight + DESKTOP_MARGIN * 2}px)`;
  const translationX = modalIsShown ? 0 : isOnLeft ? 'calc(-100% - 32px)' : 'calc(100% + 32px)';

  //const [shrunk, setShrunk] = useState(shrunkOnMobile);
  const [lastTouchStartY, setLastTouchStartY] = useState(0);
  const [hasMovedDuringTouch, setHasMovedDuringTouch] = useState(false);

  const baseRef = useRef([])

  const setModalShrunk = (isShrunk: boolean) => {
    if (setMobileModalShrunk) {
      setMobileModalShrunk(isShrunk);
    }
  }

  useEffect(() => {
    if (setMobileModalShrunk) {
      setMobileModalShrunk(shrunkOnMobile);
    }
  }, [shrunkOnMobile]);

  const removeBodyScrollingWhenModalOpen = (modalOpen: boolean) => {
    const body = document.body;
    if (modalOpen && isMobile) {
      document.body.style.overflowY = 'hidden';
      document.body.style.height = '100%';
      document.body.style.margin = '0';
    } else if (!modalOpen && isMobile) {
      document.body.style.overflowY = 'visible';
      document.body.style.height = '';
      document.body.style.margin = '';

    } else if (modalOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'visible';
    }
  };

  useEffect(() => {
    setModalIsOpen(modalIsShown);

    // Disable body scroll underneath the modal
    removeBodyScrollingWhenModalOpen(modalIsShown);

    const imBoxWrapId = document.querySelector('.imbox-wrapper')?.id;
    if (imBoxWrapId) {
      let styles = document.getElementById('overrides-imbox') as HTMLStyleElement;
      if (!styles && modalIsShown) {
        const nr = imBoxWrapId.split('-').pop();
        styles = document.createElement('style');
        styles.id = 'overrides-imbox';
        styles.innerHTML = `#imbox-launcher-container${nr} .imbox-frame iframe, #imbox-launcher-container${nr} .zoid-outlet, #imbox-launcher-container${nr}>div { z-index: inherit!important; }`;
        document.body.appendChild(styles);
      }
      if (!modalIsShown && styles) {
        const parent = styles.parentElement;
        if (parent) {
          parent.removeChild(styles);
        }
      }
    }
  }, [modalIsShown]);

  const doubleSize = paneWidthInColumns === (widthInColumns * 2);

  return (
    <Overlay enabled={backgroundDisabled && modalIsShown} onClose={() => hideModal ? hideModal() : null} zIndex={zIndex}>
      <ModalWrap
        className="grid"
        css={{
          top: topMargin,
          transform: `translateX(${translationX})`,
          transition: { property: ['transform'], duration: `${transitionTime}ms` },
          height: 'calc(100vh - calc(100vh - 100%))',
          zIndex: zIndex,
        }}
        onClick={(e) => {
          const currentTarget = e.target as HTMLElement

          for (let idx = 0; idx < baseRef.current.length; idx++) {
            const curr = baseRef.current[idx] as HTMLElement
            if (curr === null) {
              continue
            }

            if (curr.contains(currentTarget)) {
              // Exit! Click event happend inside a modal
              return
            }
          }
          if (hideModal) {
            hideModal()
          }

        }}
      >
        {modalIsShown && backgroundContent}

        {paneContent && !isOnLeft && (
          <BaseDiv
            className={createGridClasses(
              10,
              paneWidthInColumns,
              createOffsetGridClassLarge(13 - (widthInColumns + paneWidthInColumns)),
            )}
            css={{
              transition: { property: ['transform'], duration: '1000ms' },
              zIndex: childZIndex,
              bottom: bottomMargin,
              height: paneFitContent ? 'fit-content' : modalHeight,
              backgroundColor: paneBackgroundColor,
              marginRight: gridAdjustment,
              transform: `translateX(${paneContentShown ? 0 : 'calc(100% + 8px)'})`,
            }}
            ref={(element) => {
              baseRef.current.push(element)
            }}
          >
            {modalIsShown && paneContent}
          </BaseDiv>
        )}
        {shrinkableOnMobile && isMobile ? (
          <BaseDiv
            className={createGridClasses(12, widthInColumns)}
            onTouchStart={(event) => {
              setLastTouchStartY(event.touches[0].clientY);
            }}
            onTouchMove={(event) => {
              if (hasMovedDuringTouch === false) {
                if (
                  !shrunkOnMobile &&
                  event.changedTouches[0].clientY > lastTouchStartY + 100 &&
                  !Boolean((event.target as HTMLElement).closest(`.${BLOCK_SHRINK_CLASS}`))
                ) {
                  setModalShrunk(true);
                  setHasMovedDuringTouch(true);
                } else if (shrunkOnMobile && event.changedTouches[0].clientY + 100 < lastTouchStartY) {
                  setModalShrunk(false);
                  setHasMovedDuringTouch(true);
                }
              }
            }}
            onTouchEnd={(event) => {
              if (hasMovedDuringTouch === false) {
                if (
                  !shrunkOnMobile &&
                  event.changedTouches[0].clientY > lastTouchStartY + 100 &&
                  !Boolean((event.target as HTMLElement).closest(`.${BLOCK_SHRINK_CLASS}`))
                ) {
                  setModalShrunk(true);
                } else if (shrunkOnMobile && event.changedTouches[0].clientY + 100 < lastTouchStartY) {
                  setModalShrunk(false);
                }
              }
              setHasMovedDuringTouch(false);
            }}
            css={{
              ...(childZIndex > 0 && {
                zIndex: childZIndex,
              }),
              padding: { x: '12px', y: '12px' },
              bottom: bottomMargin,
              height: shrunkOnMobile ? `calc(0.6 * ${modalHeight})` : modalHeight,
              backgroundColor: backgroundColor,
              marginLeft: gridAdjustment,
              marginRight: gridAdjustment,
              ...style.transition({ property: ['height, margin-top'], duration: '300ms' }),
              ...(shrunkOnMobile && { marginTop: `calc(${modalHeight})` }),

            }}
            ref={(element) => {
              baseRef.current.push(element)
            }}
          >
            {modalIsShown && children}
          </BaseDiv>
        ) : (
          <BaseDiv
            className={createGridClasses(
              10,
              widthInColumns,
              isOnLeft ? '' : `${createOffsetGridClassTiny(3)} ${createOffsetGridClassLarge(13 - widthInColumns)}`,
            )}
            css={{
              ...(childZIndex > 0 && {
                zIndex: childZIndex,
              }),
              bottom: bottomMargin,
              height: modalHeight,
              backgroundColor: backgroundColor,
              padding: { x: isMobile ? '12px' : '16px', y: isMobile ? '12px' : '24px' },
              ...(isOnLeft ? { marginLeft: gridAdjustment } : { marginRight: gridAdjustment }),
            }}
            ref={(element) => {
              baseRef.current.push(element)
            }}
          >
            {modalIsShown && children}
          </BaseDiv>
        )}
        {paneContent && isOnLeft && (
          <BaseDiv className={createGridClasses(10, paneWidthInColumns, createOffsetGridClassLarge(widthInColumns + 1))}
            css={{
              //visibility: paneContentShown ? 'visible' : 'hidden',
              bottom: bottomMargin,
              height: paneFitContent ? 'fit-content' : modalHeight,
              backgroundColor: paneBackgroundColor,
              marginLeft: gridAdjustment,
              transform: `translateX(${paneContentShown ? 0 : doubleSize ? 'calc(-150% - 16px)' : 'calc(-100% - 8px)'})`,
              transition: { property: ['transform'], duration: '1000ms' },
              zIndex: childZIndex,
            }}
            ref={(element) => {
              baseRef.current.push(element)
            }}
          >
            {modalIsShown && paneContent}
          </BaseDiv>
        )}
      </ModalWrap>
    </Overlay>
  );
};

type ModalTopPropType = {
  textAboveHeading?: string;
  heading?: string;
  textAboveLine?: string;
  bottomLine?: boolean;
  closeText?: string;
  closeModal?: () => void;
  children?: ReactNode;
  closeIconSize?: number;
  bottomPadding?: number;
  topMargin?: number;
  mobileShrinkToggle?: boolean;
  shrunkOnMobile?: boolean;
};

const TopContentWrap = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
});

const TopContentWrapBorder = styled(TopContentWrap, {
  borderBottom: {
    width: '2px',
    style: 'solid',
    color: style.colors.monochrome.black
  }
});

const TopContentInnerWrap = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '6px',
  width: '100%',
});

const HeadingBase = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
})

const HeadingText = styled(HeadingBase, {
  alignItems: 'center',
})

const HeadingWrap = styled(HeadingBase, {
  alignItems: 'start',
})

const CloseWrap = styled.span({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer'
})

const Close16 = styled(Close16x16, {
  marginLeft: '8px'
})

const Close24 = styled(Close24x24, {
  marginLeft: '8px'
})

const ParagraphText = styled(Paragraph, {
  margin: { top: '24px', bottom: '4px' }
})

const Plus = styled(Plus24x24, {
  transform: 'rotate(0deg)',
  transition: {
    property: 'transform',
    duration: '300ms',
  },
})

const getCloseIcon = (closeIconSize: number, mobileShrinkToggle: boolean) => {
  if (mobileShrinkToggle) {
    return Plus;
  }

  return closeIconSize < 17 ? Close16 : Close24;
};

export const ModalTopContent = ({
  textAboveHeading,
  heading,
  textAboveLine,
  bottomLine = true,
  closeText,
  closeModal,
  children,
  closeIconSize = 16,
  bottomPadding = 8,
  topMargin = 0,
  mobileShrinkToggle = false,
  shrunkOnMobile = false,
}: ModalTopPropType) => {
  const CloseIcon = getCloseIcon(closeIconSize, mobileShrinkToggle);
  const TopWrap = bottomLine ? TopContentWrapBorder : TopContentWrap;
  return (
    <TopWrap
      css={{
        paddingBottom: `${bottomPadding}px`,
      }}
    >
      <TopContentInnerWrap
        css={{
          ...((topMargin > 0) && {
            marginTop: `${topMargin}px`
          })
        }}
      >
        {textAboveHeading && (
          <HeadingText>
            <Paragraph fontSize={ResponsiveSize.D16_M16}>{textAboveHeading}</Paragraph>
            {closeModal && (
              <CloseWrap
                onClick={() => closeModal()}
              >
                <TextLabel>{closeText}</TextLabel>
                <CloseIcon />
              </CloseWrap>
            )}
          </HeadingText>
        )}
        {heading &&
          <HeadingWrap>
            <H2 fontSize={ResponsiveSize.D40_M32}>{heading}</H2>
            {!textAboveHeading && closeModal && (
              <CloseWrap
                onClick={() => closeModal()}
              >
                <TextLabel>{closeText}</TextLabel>
                <CloseIcon
                  css={{
                    ...(mobileShrinkToggle && !shrunkOnMobile && {
                      transform: 'rotate(45deg)',
                    })
                  }}
                />
              </CloseWrap>
            )}
          </HeadingWrap>}
      </TopContentInnerWrap>
      {children}
      {textAboveLine && (
        <ParagraphText
          fontSize={ResponsiveSize.D12_M12}
          lineHeight={ResponsiveLineHeight.D120_M120}
        >
          {textAboveLine}
        </ParagraphText>
      )}
    </TopWrap>
  );
};

type ModalScrollableContentPropType = {
  children: ReactNode;
  css?: StyledDecorator | StyleOrStyleArray<Style>;
  resetOnContentChange?: boolean;
  blockShrink?: boolean;
  scrollElementRef?: React.MutableRefObject<HTMLDivElement>;
};

const ScrollableWrap = styled.div({
  height: '100%',
  width: '100%',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  // @ts-ignore
  '::-webkit-scrollbar': {
    display: 'none',
  },
  padding: { top: '0px', bottom: '32px', x: 0 },
  mask: {
    image: 'linear-gradient(rgb(0, 0, 0) 0px, rgb(0, 0, 0) calc(100% - 32px), rgba(0, 0, 0, 0) 100%, rgba(0, 0, 0, 0) 0px)',
    size: '100% 100%',
    position: '100% 50%',
    repeat: 'repeat no-repeat'
  },
  overflowY: 'scroll',
  overflowX: 'hidden',
  overscrollBehaviorY: 'contain',
})

export const ModalScrollableContent = styled((props: ModalScrollableContentPropType & StyledProps) => {
  const { resetOnContentChange, children, blockShrink, compose } = props;
  const scrollableRef = props.scrollElementRef ? props.scrollElementRef : useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (resetOnContentChange) {
      scrollableRef.current?.scrollTo(0, 0);
    }
  }, [children]);

  return (
    <ScrollableWrap
      ref={scrollableRef}
      className={blockShrink ? BLOCK_SHRINK_CLASS : ''}
      css={compose(props.css)}
    >
      {children}
    </ScrollableWrap>
  );
});

type ModalTabType = {
  tab: number;
  label: string;
};

type ModalTabsPropType = {
  tabs: ModalTabType[];
  activeTab: number;
  switchToTab: (tab: number) => void;
};

const ModalTabWrap = styled.div({
  display: 'flex',
  gap: '12px',
  margin: { top: '24px' },
  overflowX: 'scroll',
  height: '18px',
  width: '100%',
  scrollSnapType: 'x mandatory',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  // @ts-ignore
  '::-webkit-scrollbar': {
    display: 'none',
  },
})

const ModalTab = styled.div({
  cursor: 'pointer'
});

const ModalTabLabel = styled(TextLabel, {
  whiteSpace: 'nowrap',
})

export const ModalTabs = ({ tabs, activeTab, switchToTab }: ModalTabsPropType) => {
  return (
    <ModalTabWrap>
      {tabs.map((tab, index) => (
        <ModalTab
          key={index}
          onClick={() => {
            switchToTab(tab.tab);
          }}
        >
          <ModalTabLabel css={{ color: activeTab === tab.tab ? style.colors.monochrome.black : style.colors.monochrome.a11ygrey, }}>
            {tab.label}
          </ModalTabLabel>
        </ModalTab>
      ))}
    </ModalTabWrap>
  );
};

export default connect(
  (state) => ({
    currentBreakpoint: state.currentBreakpoint,
    headerHeight: state.UI?.headerHeight || 0,
  }),
  (dispatch) => ({
    setModalIsOpen(isOpen: boolean) {
      dispatch(SetModalIsOpen(isOpen));
    },
  }),
)(Modal);

import { PageType } from 'Shared/State';
import { componentNames } from 'Shared/component-registry';
import SearchPageViewModelType from './SearchPageViewModel.type';

type SearchPageType = PageType & SearchPageViewModelType;

export default (currentPage: PageType): currentPage is SearchPageType => {
  return componentIsSearch(currentPage.componentName);
};

export function componentIsSearch(component: string) {
  return component === componentNames.Avensia_Common_Features_Search_SearchPageViewModel;
}

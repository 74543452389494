import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { LinkProps as LinkPropType } from '@polarnopyret/scope';
import Link, { isDocumentLink } from 'Shared/Link';
import * as style from 'Shared/Style';

export type PropType = LinkPropType & {
  useDefaultLinkColor?: boolean;
};

const A = styled(function LinkElement(props: PropType & StyledProps) {
  const { compose, useDefaultLinkColor, ...restProps } = props;

  return (
    <Link
      {...restProps}
      css={compose({
        ...(useDefaultLinkColor && {
          color: style.colors.linkColors.default,
        }),
        textDecoration: 'none',
        cursor: 'pointer',
        ':hover': {
          textDecoration: 'underline',
        },
        ':focus': {
          textDecoration: 'underline',
        },
      })}

      {...(restProps.to && isDocumentLink(restProps.to) && {
        target: '_blank'
      })}
    />
  );
});

export default A;
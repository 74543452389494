// This file has been generated by the Scope build. Do not alter
// it manually as your changes will be overwritten. Run:
// scope.cmd frontend:build-component-registry
// instead.

/* tslint:disable */

// prettier-ignore
export default {
  Avensia_Common_Features_Account_Login_LoginPageViewModel: 'Avensia.Common.Features.Account.Login.LoginPageViewModel',
  Avensia_Common_Features_Account_Register_RegisterPageViewModel: 'Avensia.Common.Features.Account.Register.RegisterPageViewModel',
  Avensia_Common_Features_AppShell_AppShellViewModel: 'Avensia.Common.Features.AppShell.AppShellViewModel',
  Avensia_Common_Features_Article_ArticlePageViewModel: 'Avensia.Common.Features.Article.ArticlePageViewModel',
  Avensia_Common_Features_Category_CategoryTreeViewModel: 'Avensia.Common.Features.Category.CategoryTreeViewModel',
  Avensia_Common_Features_Category_CategoryViewModel: 'Avensia.Common.Features.Category.CategoryViewModel',
  Avensia_Common_Features_Checkout_Pages_Checkout_CheckoutPageViewModel: 'Avensia.Common.Features.Checkout.Pages.Checkout.CheckoutPageViewModel',
  Avensia_Common_Features_Checkout_Pages_OrderConfirmation_OrderConfirmationPageViewModel: 'Avensia.Common.Features.Checkout.Pages.OrderConfirmation.OrderConfirmationPageViewModel',
  Avensia_Common_Features_Cms_CmsPageViewModel: 'Avensia.Common.Features.Cms.CmsPageViewModel',
  Avensia_Common_Features_CustomerService_CustomerServiceItemViewModel: 'Avensia.Common.Features.CustomerService.CustomerServiceItemViewModel',
  Avensia_Common_Features_CustomerService_CustomerServiceViewModel: 'Avensia.Common.Features.CustomerService.CustomerServiceViewModel',
  Avensia_Common_Features_Error_Pages_PageNotFoundViewModel: 'Avensia.Common.Features.Error.Pages.PageNotFoundViewModel',
  Avensia_Common_Features_GiftCards_GiftCardPageViewModel: 'Avensia.Common.Features.GiftCards.GiftCardPageViewModel',
  Avensia_Common_Features_MagazineStart_MagazineStartPageViewModel: 'Avensia.Common.Features.MagazineStart.MagazineStartPageViewModel',
  Avensia_Common_Features_Markets_MarketsPageViewModel: 'Avensia.Common.Features.Markets.MarketsPageViewModel',
  Avensia_Common_Features_MyPages_MyPagesInformationPageViewModel: 'Avensia.Common.Features.MyPages.MyPagesInformationPageViewModel',
  Avensia_Common_Features_MyPages_MyPagesProfilePageViewModel: 'Avensia.Common.Features.MyPages.MyPagesProfilePageViewModel',
  Avensia_Common_Features_PickUpInStore_ArrivingOrders_ArrivingOrdersPageViewModel: 'Avensia.Common.Features.PickUpInStore.ArrivingOrders.ArrivingOrdersPageViewModel',
  Avensia_Common_Features_PickUpInStore_DeliveredOrders_DeliveredOrdersPageViewModel: 'Avensia.Common.Features.PickUpInStore.DeliveredOrders.DeliveredOrdersPageViewModel',
  Avensia_Common_Features_PickUpInStore_StoreSelectionPageViewModel: 'Avensia.Common.Features.PickUpInStore.StoreSelectionPageViewModel',
  Avensia_Common_Features_Product_VariationViewModel: 'Avensia.Common.Features.Product.VariationViewModel',
  Avensia_Common_Features_ResetPassword_ResetPasswordPageViewModel: 'Avensia.Common.Features.ResetPassword.ResetPasswordPageViewModel',
  Avensia_Common_Features_Search_SearchPageViewModel: 'Avensia.Common.Features.Search.SearchPageViewModel',
  Avensia_Common_Features_Shared_Blocks_AccordionBlock_AccordionBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.AccordionBlock.AccordionBlockViewModel',
  Avensia_Common_Features_Shared_Blocks_BlockSlider_BlockSliderBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.BlockSlider.BlockSliderBlockViewModel',
  Avensia_Common_Features_Shared_Blocks_CountryLink_CountryLinkBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.CountryLink.CountryLinkBlockViewModel',
  Avensia_Common_Features_Shared_Blocks_CTABlock_CTABlockViewModel: 'Avensia.Common.Features.Shared.Blocks.CTABlock.CTABlockViewModel',
  Avensia_Common_Features_Shared_Blocks_Editorial_EditorialBlock: 'Avensia.Common.Features.Shared.Blocks.Editorial.EditorialBlock',
  Avensia_Common_Features_Shared_Blocks_Hero_HeroBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.Hero.HeroBlockViewModel',
  Avensia_Common_Features_Shared_Blocks_Html_CssAndScriptBlock: 'Avensia.Common.Features.Shared.Blocks.Html.CssAndScriptBlock',
  Avensia_Common_Features_Shared_Blocks_Image_ImageBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.Image.ImageBlockViewModel',
  Avensia_Common_Features_Shared_Blocks_Imbox_ImboxBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.Imbox.ImboxBlockViewModel',
  Avensia_Common_Features_Shared_Blocks_InformationBlock_InformationBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.InformationBlock.InformationBlockViewModel',
  Avensia_Common_Features_Shared_Blocks_Magazine_MagazineArticleBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.Magazine.MagazineArticleBlockViewModel',
  Avensia_Common_Features_Shared_Blocks_Magazine_MagazineBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.Magazine.MagazineBlockViewModel',
  Avensia_Common_Features_Shared_Blocks_Product_ProductBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.Product.ProductBlockViewModel',
  Avensia_Common_Features_Shared_Blocks_Product_ProductGroupBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.Product.ProductGroupBlockViewModel',
  Avensia_Common_Features_Shared_Blocks_Product_ProductGroupItemBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.Product.ProductGroupItemBlockViewModel',
  Avensia_Common_Features_Shared_Blocks_Product_ProductListBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.Product.ProductListBlockViewModel',
  Avensia_Common_Features_Shared_Blocks_ProductFeature_ProductFeatureBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.ProductFeature.ProductFeatureBlockViewModel',
  Avensia_Common_Features_Shared_Blocks_ProductFeature_ProductFeatureListBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.ProductFeature.ProductFeatureListBlockViewModel',
  Avensia_Common_Features_Shared_Blocks_RowLayout_RowLayoutBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.RowLayout.RowLayoutBlockViewModel',
  Avensia_Common_Features_Shared_Blocks_SaleBlock_SaleBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.SaleBlock.SaleBlockViewModel',
  Avensia_Common_Features_Shared_Blocks_ShopTheLook_ShopTheLookBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.ShopTheLook.ShopTheLookBlockViewModel',
  Avensia_Common_Features_Shared_Blocks_ShopTheLook_ShopTheLookGroupBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.ShopTheLook.ShopTheLookGroupBlockViewModel',
  Avensia_Common_Features_Shared_Blocks_ShopTheLook_ShopTheLookItemBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.ShopTheLook.ShopTheLookItemBlockViewModel',
  Avensia_Common_Features_Shared_Blocks_TextBlock_SliderTextBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.TextBlock.SliderTextBlockViewModel',
  Avensia_Common_Features_Shared_Blocks_TextBlock_TextBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.TextBlock.TextBlockViewModel',
  Avensia_Common_Features_Shared_Blocks_Video_VideoProductsBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.Video.VideoProductsBlockViewModel',
  Avensia_Common_ContentTypes_Media_ImageDataModel: 'Avensia.Common.ContentTypes.Media.ImageDataModel',
  Avensia_Common_Features_Shared_Partials_PreviewViewModel: 'Avensia.Common.Features.Shared.Partials.PreviewViewModel',
  EPiServer_Core_XhtmlString: 'EPiServer.Core.XhtmlString',
  Avensia_Common_Features_SiteLayout_Footer_SiteFooter: 'Avensia.Common.Features.SiteLayout.Footer.SiteFooter',
  Avensia_Common_Features_SiteLayout_Footer_OptionLinks_MultipleOptionLinksBlockViewModel: 'Avensia.Common.Features.SiteLayout.Footer.OptionLinks.MultipleOptionLinksBlockViewModel',
  Avensia_Common_Features_SiteLayout_Footer_OptionLinks_SingleOptionLinksBlockViewModel: 'Avensia.Common.Features.SiteLayout.Footer.OptionLinks.SingleOptionLinksBlockViewModel',
  Avensia_Common_Features_SizeGuide_SizeGuideBlock: 'Avensia.Common.Features.SizeGuide.SizeGuideBlock',
  Avensia_Common_Features_Start_StartPageViewModel: 'Avensia.Common.Features.Start.StartPageViewModel',
  Avensia_Common_Features_Stores_DeviatingOpeningHourBlock: 'Avensia.Common.Features.Stores.DeviatingOpeningHourBlock',
  Avensia_Common_Features_Stores_StoreLandingPageViewModel: 'Avensia.Common.Features.Stores.StoreLandingPageViewModel',
  Avensia_Common_Features_WishList_WishListPageViewModel: 'Avensia.Common.Features.WishList.WishListPageViewModel',
  EPiServer_Core_ContentArea: 'EPiServer.Core.ContentArea',
  EPiServer_SpecializedProperties_LinkItemCollection: 'EPiServer.SpecializedProperties.LinkItemCollection',
};

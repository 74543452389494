import { styled } from '@glitz/react';
import React from 'react';
import { InstantSearchLink, SearchSoftHeading, searchUrl } from './shared';
import { replaceState } from '@polarnopyret/scope';

type RecentSearchesPropType = {
  isMobile: boolean;
  heading?: string;
  recentSearches?: string[];
};

const Items = styled.div({
  marginBottom: '80px'
})

const RecentSearches = (props: RecentSearchesPropType) => {
  const { heading, recentSearches, isMobile } = props;
  
  if(recentSearches === null || (recentSearches?.length ?? 0) < 1) {
    return (
      <></>
    );
  }

  return (
    <>
      <SearchSoftHeading>{heading}</SearchSoftHeading>
      <Items>
        {recentSearches?.map((recentSearch, index) => (
          <InstantSearchLink
            key={index}
            to={searchUrl(recentSearch, false)}
            css={{ margin: { top: index !== 0 ? '12px' : undefined } }}
            onClick={(e) => {
              e.preventDefault();
              replaceState(searchUrl(recentSearch, false));
            }}
          >
           {recentSearch}
          </InstantSearchLink>
        ))}
      </Items>
    </>
  );
};

export default RecentSearches;
import React from 'react';
import { styled, StyledProps } from '@glitz/react';

const iconStyled = styled({
  display: 'inline-flex',
  verticalAlign: 'text-top',
});

export default function factory(Glyph: typeof SVGComponent, width: number, height: number, currentColorFill?: boolean) {
  return iconStyled(({ compose, ...restProps }: StyledProps & React.HTMLAttributes<HTMLSpanElement>) => (
    <styled.Span {...restProps}
      css={compose({
        width: `${width}px`,
        height: `${height}px`,
      })}
    >
      <Glyph width={width + 'px'} height={height + 'px'} fill={currentColorFill ? "currentColor" : "none"} />
    </styled.Span>
  ));
}

export function flagFactory(Glyph: typeof SVGComponent) {
  return factory(Glyph, 20, 20);
  //return factoryScaledToFit(Glyph);
}

const iconStyledScaledToFit = styled({
  display: 'inline-flex',
  alignItems: 'center',
  width: '1em', // Needs to be 1em otherwise scale to fit will not work. em is a realtive measurement and will align to wrapped size
  height: 'auto', // See above
  lineHeight: '1em',
  /* verticalAlign: 'text-top', */
  fontSize: '1em',
});

export function factoryScaledToFit(Glyph: typeof SVGComponent, emptyFill?: boolean, provideOwnFill?: boolean, fillWithCurrentColor?: boolean) {
  return iconStyledScaledToFit(({ compose, ...restProps }: StyledProps & React.HTMLAttributes<HTMLSpanElement>) => (
    <styled.Span {...restProps} css={compose({})}>
      <Glyph style={{ width: '100%', height: '100%' }} fill={emptyFill ? "none" : (provideOwnFill ? "undefined" : (fillWithCurrentColor ? "currentColor" : "auto"))} />
    </styled.Span>
  ));
}
import { styled } from '@glitz/react';
import factory from './icon';
import Svg12x12 from './Glyphs/ArrowRight_12x12.svg';
import Svg16x16 from './Glyphs/ArrowRight_16x16.svg';
import Svg24x24 from './Glyphs/ArrowRight_24x24.svg';

export const Right12x12 = factory(Svg12x12, 12, 12);
export const Down12x12 = styled(Right12x12, {
  transform: 'rotate(90deg)',
})
export const Left12x12 = styled(Right12x12, {
  transform: 'rotate(180deg)',
  });
export const Up12x12 = styled(Right12x12, {
  transform: 'rotate(270deg)',
})

export const Right16x16 = factory(Svg16x16, 16, 16);
export const Down16x16 = styled(Right16x16, {
  transform: 'rotate(90deg)',
})
export const Left16x16 = styled(Right16x16, {
  transform: 'rotate(180deg)',
  });
export const Up16x16 = styled(Right16x16, {
  transform: 'rotate(270deg)',
})

export const Right24x24 = factory(Svg24x24, 24, 24);
export const Down24x24 = styled(Right24x24, {
  transform: 'rotate(90deg)',
})
export const Left24x24 = styled(Right24x24, {
  transform: 'rotate(180deg)',
  });
export const Up24x24 = styled(Right24x24, {
  transform: 'rotate(270deg)',
})

import { Style } from '@glitz/type';

export function truncate(props: { overflow?: string } = {}): Style {
  const { overflow = 'ellipsis' } = props;
  return {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: overflow,
  };
}

import { HeaderType, Action } from 'Shared/State';
import { HIDE_HEADER, COMPACT_HEADER, SCROLLUP_HEADER } from './action-creators';
import { SHOW_QUICKSEARCH, HIDE_QUICKSEARCH } from 'Search/action-creators';
import AppShellDataType from 'AppShell/AppShellContextData.type';

export default function(state: HeaderType = null, action: Action, appShellData: AppShellDataType): HeaderType {
  if (state === null) {
    state = {
      content: appShellData.header,
      isCollapsed: false,
      isVisible: true,
    };
  }
  switch (action.type) {
    case COMPACT_HEADER:
      return {
        ...state,
        isCollapsed: true,
      };
    case HIDE_HEADER:
      return {
        ...state,
        isVisible: false,
      };
    case SCROLLUP_HEADER:
      return {
        ...state,
        isCollapsed: false,
        isVisible: true,
      };
    case SHOW_QUICKSEARCH:
      return {
        ...state,
        isCollapsed: false,
      };
    case HIDE_QUICKSEARCH:
      return {
        ...state,
      };
    default:
      return state;
  }
}

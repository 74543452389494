import React from 'react';
import { translate } from '@polarnopyret/scope';
import { styled } from '@glitz/react';
import { Snackbar } from 'SiteLayout/Tray';
import { LoadFailure } from 'Shared/State';
import { ButtonTheme, SolidButton } from 'Shared/TextButtons';
import * as style from 'Shared/Style';

const Base = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  color: style.colors.monochrome.black,
});

const LinkWrap = styled.div({
  display: 'flex',
  gap: '12px'
})

type PropType = {
  reload: () => void;
  loadFailure: LoadFailure;
};

type State = {
  visible: boolean;
  dismissed: LoadFailure;
};

export default class NetworkError extends React.Component<PropType, State> {
  ignoredOfflineWarning: Date;
  retriedWhileOffline: boolean;
  scrollPositionWhenErrorShown: number;
  scrollListener: () => void;
  constructor(props: PropType) {
    super(props);
    this.state = {
      visible: this.isVisible(props),
      dismissed: null,
    };
  }
  isVisible(props: PropType) {
    if (props.loadFailure === null || props.loadFailure === undefined) {
      return false;
    } else {
      return (
        this.state &&
        this.state.dismissed !== props.loadFailure &&
        // AccountButton handles failed requests that requires auth
        props.loadFailure.status !== 401 &&
        // Don't bother the user if we're on the 404 page
        props.loadFailure.status !== 404 &&
        // Don't bother the user if we're showing a full version of the page
        (!props.loadFailure.isShowingFullCachedPage || props.loadFailure.status <= 500)
      );
    }
  }
  shouldComponentUpdate(nextProps: PropType, nextState: State) {
    return nextProps.loadFailure !== this.props.loadFailure || nextState.visible !== this.state.visible;
  }
  componentWillReceiveProps(nextProps: PropType) {
    const visible = this.isVisible(nextProps);

    if (visible !== this.state.visible) {
      if (
        !this.retriedWhileOffline &&
        visible &&
        !isOnline() &&
        this.ignoredOfflineWarning &&
        !oneHourHasPassedFrom(this.ignoredOfflineWarning)
      ) {
        // Don't hassle the user by telling them about offline all the time
        return;
      }

      this.setState({
        visible,
        dismissed: null,
      });
    }
  }
  onRetry() {
    if (!isOnline()) {
      // If you click Retry we don't want to show the "You're offline" message again
      this.retriedWhileOffline = true;
    }
    this.props.reload();
  }
  onDismiss() {
    if (!isOnline()) {
      this.ignoredOfflineWarning = new Date();
      this.retriedWhileOffline = false;
    }
    this.setState({ visible: false, dismissed: this.props.loadFailure });
  }
  render() {
    const showOfflineMessage = !isOnline() && !this.retriedWhileOffline;

    return this.state.visible ? (
      <Snackbar>
        <Base>
          <span>{showOfflineMessage ? translate('/site/errors/offline') : translate('/site/errors/pageload')}</span>
          <LinkWrap>
            {!showOfflineMessage && (
              // No reason to show a try again message when we know that the user is offline
              <SolidButton 
                theme={ButtonTheme.BlackToWhite}
                onClick={() => this.onRetry()}>
                {translate('/site/errors/retry')}
              </SolidButton>
            )}
            <SolidButton
              theme={ButtonTheme.WhiteToBlack}
              onClick={() => this.onDismiss()}
            >
              {translate('/site/shared/dismiss')}
            </SolidButton>
          </LinkWrap>
        </Base>
      </Snackbar>
    ) : null;
  }
}

function oneHourHasPassedFrom(date: Date) {
  return (new Date().valueOf() - date.valueOf()) / 1000 > 3600;
}

function isOnline() {
  return navigator.onLine === false ? false : true;
}

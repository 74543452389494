export const colors = {
  monochrome: {
    white: '#ffffffff',
    black: '#000000ff',
    extraLightGrey: '#f3f3f2',
    lightGrey: '#dbdbdb',
    mediumGrey: '#b2b1b1',
    darkGrey: '#878787',
    a11ygrey: '#676767',
    darkestGrey: '#3c3c3c',
  },
  brandColors: {
    darkBlue: '#171b42',
    blue: '#79c6dd',
    lightBlue: '#96acbe',
    pink: '#b71d65',
    burgundy: '#9c1051',
    green: '#7daa88',
    red: '#ff1e39',
    yellow: '#f2c94c',
    yellowAlternative: '#f9e4a5',
    orange: '#ec6907',
  },
  negativeColors: {
    negative: '#FF1E39',
    negativeLight: '#B71D65',
  },
  linkColors: {
    default: '#6b92a8'
  },
  functionColors: {
    sale: '#ec6907',
    onlineOnly: '#96acbe',
    new: '#DC9CA4',
    blackfriday: '#000000ff',
    discount: '#96acbe',
    christmas: '#ff1e39',
  },
  rgbaFilters: {
    blue: 'rgba(23,27,66,0.5)',
    white50: 'rgba(255,255,255, 0.5)',
    black50: 'rgba(0, 0, 0, 0.5)',
  }
};

export const hex2rgba = (hex: string, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

import { query } from '@glitz/core';
import { Breakpoint, widthFromBreakpoint } from '@polarnopyret/scope';
import { pixelsToUnit } from './unit';

function mediaQuery(breakpoint: Breakpoint) {
  return query({
    minWidth: pixelsToUnit(widthFromBreakpoint(breakpoint)),
  });
}

function mediaMaxQuery(breakpoint: Breakpoint) {
  return query({
    maxWidth: pixelsToUnit(widthFromBreakpoint(breakpoint)),
  });
}

// export function breakpointValue(breakpoint: Breakpoint) {
//   return pixelsToUnit(widthFromBreakpoint[breakpoint], Unit.Px);
// }

/** Media query for minimum window width for larger tablets in landscape mode, smaller laptops and desktops */
export const mediaMinLarge = mediaQuery(Breakpoint.Large);
export const mediaUpToLarge = mediaMaxQuery(Breakpoint.Large);

// export const mediaMinQueries = {
//   [Breakpoint.Large]: mediaMinLarge,
// };

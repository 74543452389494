const currencyIntagerFormatters: { [key: string]: Intl.NumberFormat } = {};
const currencyMixedFormatters: { [key: string]: Intl.NumberFormat } = {};

export function formatPrice(price: number, currency: string, locale: string, removeLiteralsAndCurrency?: boolean) {
  // Guard clause - if removeLiteralsAndCurrency is specified, we assume you know what this means.
  // It was created to remove the literals and currency from the price - required for finnish prices to have proper padding and delimiters (eg formatting 19.9 to 19,90 - always with 2 decimal places and comma as decimal separator)
  if (removeLiteralsAndCurrency) {
    return new Intl.NumberFormat('fi-FI', { style: 'currency', currency: 'EUR' }).formatToParts(price) 
      .filter(part => part.type !== 'currency' && part.type !== 'literal')
      .map(part => part.value)
      .join('');
  }
  // Hijacking the Norwegian currency from incorrectly formatting to `kr 100` and
  // format to Swedish-like format `100 kr`
  /* if (/NO$/.test(locale) && currency === 'NOK') {
    currency = 'SEK';
    locale = 'sv-NO';
  } */
  
  // This implementation uses `Intl.NumberFormat` which isn't supported for
  // browsers like IE10. If this is a problem, either do your own
  // implementation or polyfill it with the `intl` package.
  const isMixed = Math.floor(price) < price;
  const formatters = isMixed ? currencyMixedFormatters : currencyIntagerFormatters;
  const key = `${currency}.${locale}`;
  const formatter = (formatters[key] =
    formatters[key] ||
    new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      currencyDisplay: 'code',
      ...(!isMixed && {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
    }));

  return formatter.format(price);
}

export function formatNumber(number: number, locale: string) {
  return new Intl.NumberFormat(locale).format(number);

}

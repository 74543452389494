import React, { useEffect, useState, useRef } from 'react';
import { styled } from '@glitz/react';
import { currentUrl, get } from '@polarnopyret/scope';
import connect from 'Shared/connect';
import { connectWithFeedback, ConnectPropType as FeedbackPropType } from 'Shared/Button/Feedback';
import { loginUrl, registerPage } from 'Shared/known-urls';
import { Spinner24x24 } from 'Shared/Icons/Spinner';
import Modal from 'Shared/SharedComponents/atoms/Modal';
import LoginViewModel from 'Account/Login/LoginViewModel.type';
import RegisterViewModel from 'Account/Register/RegisterViewModel.type';
import ForgotPasswordForm from './ForgotPasswordForm';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import ResetPasswordForm from './ResetPasswordForm';
import ResetPasswordPageViewModel from 'ResetPassword/ResetPasswordPageViewModel.type';
import * as style from 'Shared/Style';

export enum ModalView {
  Login,
  ForgotPassword,
  Register,
  ResetPassword,
}

type RequiredPropType = {
  isOpen: boolean;
  hideModal: () => void;
  startModalView?: ModalView;
  redirectOnLogin: boolean;
  redirectToAfterLogin: string;
  isCompact: boolean;
  preloadedLoginView?: LoginViewModel;
  preloadedRegisterView?: RegisterViewModel;
  resetPasswordView?: ResetPasswordPageViewModel & { email: string; token: string };
  isWide?: boolean;
  backgroundDisabled?: boolean;
};

type PropType = RequiredPropType & FeedbackPropType;

const SpinnerWrap = styled.div({
  width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'
});

const ContentWrap = styled.div({
  height: '100%',
})

// Used for extending the "non-closing" area for the modal. For example when selecting text in inputs end mouseup is registerted outside the modal it will close it.
const ExtendedInvisiblePart = styled.div({
  position: 'absolute',
  top: 0,
  marginLeft: '-200px',
  width: '200px',
  height: '100vh',
  opacity: 0,
});

const LoginModal = (props: PropType) => {
  const {
    isOpen,
    hideModal,
    startModalView = ModalView.Login,
    isCompact,
    preloadedLoginView = null,
    preloadedRegisterView = null,
    resetPasswordView = null,
    isWide = false,
    backgroundDisabled = true,
    ...restProps
  } = props;
  const scrollableRef = useRef<HTMLDivElement>(null);
  const [modalView, setModalView] = useState(startModalView);
  const [loginViewContent, setLoginViewContent] = useState(preloadedLoginView);
  const [registerViewContent, setRegisterViewContent] = useState(preloadedRegisterView);
  const [email, setEmail] = useState('');
  const modalRef = useRef<HTMLDivElement>(null);

  async function getLoginViewContent() {
    const result = await get(loginUrl() + '/init');

    if (result && result.status >= 200 && result.status < 400) {
      setLoginViewContent((await result.json()) as LoginViewModel);
    }
  }
  
  async function getRegisterViewContent() {
    const result = await get(registerPage() + '/init');

    if (result && result.status >= 200 && result.status < 400) {
      setRegisterViewContent((await result.json()) as RegisterViewModel);
    }
  }

  useEffect(() => {
    if (isOpen && !loginViewContent) {
      getLoginViewContent();
    }
    if (isOpen && !registerViewContent) {
      getRegisterViewContent();
    }
  }, [isOpen]);

  useEffect(() => {
    setModalView(startModalView);
  }, [startModalView]);

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      !modalRef.current?.contains(event.target as Node) &&
      !(event.target as HTMLElement).classList.contains('modal-content')
    ) {
      if(hideModal) {
        hideModal();
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, hideModal]);

  const renderModalContent = () => {
    if (
      ((modalView === ModalView.Login || modalView === ModalView.ForgotPassword) && !loginViewContent) ||
      (modalView === ModalView.Register && !registerViewContent)
    ) {
      return (
        <SpinnerWrap>
          <Spinner24x24 />
        </SpinnerWrap>
      );
    }

    switch (modalView) {
      case ModalView.Login: {
        return (
          <LoginForm
            {...restProps}
            content={loginViewContent}
            email={email}
            hideModal={hideModal}
            setEmail={setEmail}
            toForgotPassword={() => setModalView(ModalView.ForgotPassword)}
            toRegister={() => setModalView(ModalView.Register)}
            isCompact={isCompact}
            redirectOnLogin={true}
            redirectToAfterLogin={currentUrl().href}
          />
        );
      }
      case ModalView.ForgotPassword: {
        return (
          <ForgotPasswordForm
            {...restProps}
            content={loginViewContent}
            email={email}
            setEmail={setEmail}
            toLogin={() => setModalView(ModalView.Login)}
            toRegister={() => setModalView(ModalView.Register)}
            isCompact={isCompact}
          />
        );
      }
      case ModalView.Register: {
        return (
          <RegisterForm
            {...restProps}
            content={registerViewContent}
            email={email}
            setEmail={setEmail}
            toLogin={() => setModalView(ModalView.Login)}
            isCompact={isCompact}
            scrollElementRef={scrollableRef}
          />
        );
      }
      case ModalView.ResetPassword: {
        const { email: resetPasswordEmail, token: resetPasswordToken, ...resetPasswordViewContent } = resetPasswordView;
        return (
          <ResetPasswordForm
            {...restProps}
            content={resetPasswordViewContent}
            email={resetPasswordEmail}
            token={resetPasswordToken}
            toForgotPassword={() => setModalView(ModalView.ForgotPassword)}
            isCompact={isCompact}
          />
        );
      }
      default: {
        return (
          <LoginForm
            {...restProps}
            content={loginViewContent}
            email={email}
            hideModal={hideModal}
            setEmail={setEmail}
            toForgotPassword={() => setModalView(ModalView.ForgotPassword)}
            toRegister={() => setModalView(ModalView.Register)}
            isCompact={isCompact}
          />
        );
      }
    }
  };

  return (
    <Modal
      modalIsShown={isOpen}
      hideModal={hideModal}
      widthInColumns={isWide ? 5 : 3}
      transitionTime={600}
      backgroundDisabled={isCompact || backgroundDisabled}
      zIndex={style.ZIndex.LoginRegister}
    >
      <ExtendedInvisiblePart />
      <ContentWrap ref={modalRef}>
        {renderModalContent()}
      </ContentWrap>
    </Modal>
  );
};

const LoginWithFeedback = connectWithFeedback({
  minimumPending: 750,
  maximumRejected: 500,
  maximumFulfilled: 500,
})(LoginModal);

export default connect()(
  function LinkElement(props: RequiredPropType) {
    const { ...restProps } = props;
    return (
      <LoginWithFeedback {...restProps} />
    );
  }
);

import React from 'react';
import connect from 'Shared/connect';
import { styled, StyledProps } from '@glitz/react';
import * as style from 'Shared/Style';
import { ActionButton } from 'SiteLayout/Header/ActionButtons';
import { Close24x24 } from 'Shared/Icons/Close';
import MenuLinkItem from 'SiteLayout/MainMenu/MenuLinkItem.type';
import { MenuLinkItemOnClick } from 'SiteLayout/MainMenu';
import { AccountBoxScene, openAccountBox } from 'SiteLayout/AccountBox/action-creators';
import Link from 'Shared/Link';

const TopMenuWrap = styled.div({
  background: 'black',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '28px', 

  ['@media ' + style.mediaMinLarge]: {
    height: '32px', 
  },
})

const TopMenuLinkWrap = styled.div({
  color: 'white',
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  paddingRight: '16px',
})

const TopMenuLink = styled(Link, {
  marginRight: '16px',
  cursor: 'pointer',
  textDecoration: 'none',
  color: style.colors.monochrome.white,
  fontSize: '14px',
})

export const SubMenu = connect(
  state => ({
    links: state.appShellData.header.subMenuLinks,
  }),
  dispatch => ({
    openAccountBox(scene?: AccountBoxScene) {
      dispatch(openAccountBox(scene));
    },
  }),
)(
  styled(
    (
      props: StyledProps & {
        toggleTopLinks: () => void;
        links: MenuLinkItem[];
      },
    ) => {
      const { compose, toggleTopLinks, links } = props;
      return (
        <TopMenuWrap>
          <TopMenuLinkWrap>
            {links.map((link, i) => (
              <TopMenuLink
                to={link.url}
                key={i}
                onClick={e => {
                  e.preventDefault();
                  MenuLinkItemOnClick(link, openAccountBox);
                  toggleTopLinks();
                }}
              >
                {link.title}
              </TopMenuLink>
            ))}
            <ActionButton onClick={toggleTopLinks} style={{ display: 'flex' }} Icon={Close24x24} />
          </TopMenuLinkWrap>
        </TopMenuWrap>
      );
    },
  ),
);

import deepmerge from 'deepmerge';
import { PAGE_UPDATE_SUCCESS, PageUpdateSuccessAction } from '@polarnopyret/scope';
import {
  CHECKOUT_OPTIMISTIC_UPDATE,
  CHECKOUT_CHANGES_ACCEPTED,
  COMPLETE_PURCHASE,
  COMPLETE_PURCHASE_WITHOUT_PAYMENT_GATEWAY_SUCCESS,
  COMPLETE_PURCHASE_FAILURE,
  CheckoutOptimisticUpdateAction,
  CheckoutChangesAcceptedAction,
} from '../../action-creators';
import currentPageIsCheckout from './current-page-is-checkout';
import CheckoutPageViewModel from './CheckoutPageViewModel.type';
import CheckoutPageUpdateViewModel from './CheckoutPageUpdateViewModel.type';
import { PageType, Action } from 'Shared/State';

export type CheckoutPageStateType = CheckoutPageViewModel &
  PageType & {
    pendingChanges: CheckoutPageDiffType[];
  };

export type CheckoutPageDiffType = CheckoutPageUpdateViewModel & {
  id: string;
};

export default function(state: PageType = null, action: Action): CheckoutPageStateType {
  const checkoutState = state as CheckoutPageStateType;
  // We can safely assume that the current pages component is loaded
  if (currentPageIsCheckout(state)) {
    switch (action.type) {
      case CHECKOUT_OPTIMISTIC_UPDATE: {
        const diff = [(action as CheckoutOptimisticUpdateAction).diff];
        const newState = applyPendingChanges(checkoutState, diff);
        newState.pendingChanges = (newState.pendingChanges || []).concat(diff);
        newState.scrollPosition = undefined;
        return newState;
      }
      case CHECKOUT_CHANGES_ACCEPTED: {
        const acceptedChangeIds = (action as CheckoutChangesAcceptedAction).changes.map(c => c.id);
        const checkoutPage = Object.assign({}, checkoutState);
        checkoutPage.pendingChanges = checkoutPage.pendingChanges.filter(p => acceptedChangeIds.indexOf(p.id) === -1);
        return checkoutPage;
      }
      case PAGE_UPDATE_SUCCESS: {
        let newState = (action as PageUpdateSuccessAction).page as CheckoutPageStateType;
        newState.scrollPosition = undefined;
        if (checkoutState.pendingChanges && checkoutState.pendingChanges.length) {
          newState = applyPendingChanges(newState, checkoutState.pendingChanges);
          newState.pendingChanges = checkoutState.pendingChanges;
        } else {
          newState.pendingChanges = [];
        }
        return Object.assign(checkoutState, newState);
      }
      case COMPLETE_PURCHASE: {
        state = Object.assign({}, state, { isLoading: true, showLoadingSpinner: false, scrollPosition: undefined });
        break;
      }
      case COMPLETE_PURCHASE_WITHOUT_PAYMENT_GATEWAY_SUCCESS: {
        state = Object.assign({}, state, { isLoading: false, showLoadingSpinner: false });
        break;
      }
      case COMPLETE_PURCHASE_FAILURE: {
        state = Object.assign({}, state, { isLoading: false, showLoadingSpinner: false });
        break;
      }
      default:
        break;
    }
  }
  return checkoutState;
}

function applyPendingChanges(
  state: CheckoutPageStateType,
  pendingChanges: CheckoutPageDiffType[],
): CheckoutPageStateType {
  pendingChanges.forEach(pendingChange => (state = deepmerge(state, pendingChange)));
  return state;
}

// Have properties order and prefered `z-index` order accordingly
// and make sure it always starts with 1
export enum ZIndex {
  Content = 1,
  Dropdown,
  UserMenu,
  LoginRegister,
  Menu,
  Header,
  StoreBanner,
  Tray,
  Overlay,
  Spinner,
  ImageViewer,
}

import React from 'react';
import { styled } from '@glitz/react';
import Image from 'Shared/Image';
import { loadScript } from '@polarnopyret/scope';
import connect from 'Shared/connect';

const Base = styled.div({
  
});

const ImageHolder = styled.div({
  
});

type RequiredPropType = {
  paymentWidgetLink: Scope.UrlViewModel;
  className: string;
};

type ConnectedPropType = {
  culture: string;
};

type PropType = RequiredPropType & ConnectedPropType;

class PW extends React.Component<PropType> {
  isScript() {
    return this.props.paymentWidgetLink.url.indexOf('.js') > -1;
  }

  componentDidMount() {
    if (this.isScript()) {
      loadScript(this.props.paymentWidgetLink.url);
    }
  }

  render() {
    
    const locale = this.props.culture.toLowerCase().replace('-', '_');
    const isJS = this.isScript();

    return (
      <Base
        className={this.props.className}
      >
        {isJS ? (
          <div
            className="klarna-widget klarna-badge-tooltip"
            data-eid="PK01859"
            data-locale={locale}
            data-badge-name="short-blue"
            data-badge-width="340"
          />
        ) : (
          <ImageHolder>
            <Image src={this.props.paymentWidgetLink} alt="payment" width={'100%'} />
          </ImageHolder>
        )}
      </Base>
    );
  }
}
export default connect(state => ({
  culture: state.appShellData.culture,
}))(PW);

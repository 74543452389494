import React, { useEffect, useState } from 'react';
import { styled } from '@glitz/react';
import connect from './../Shared/connect';
import * as style from './../Shared/Style';
import { pushState } from '@polarnopyret/scope/index';
import { ScrollableFade, ModalBase } from 'Shared/StyledComponents';
import { isCompact } from 'Shared/Viewport';
import { StyleOrStyleArray } from '@glitz/type';
import {
  ResponsiveSize,
  Text,
  H2
} from 'Shared/SharedComponents/atoms/Typography';

import { ANIMATION_DURATION } from 'SiteLayout/Header/DesktopHeader';
import { MiniCartType } from 'Shared/State';
import { HideCartModal, ShowCartModal } from 'Shared/uiReducer';
import { Close16x16 as Close } from '../Shared/Icons/Close';
import { ButtonTheme, SolidButton, LinkButton, ButtonHeight } from 'Shared/TextButtons';
import SitePages from 'AppShell/Models/SitePages.type';
import MiniCartViewModel from 'Cart/Models/MiniCartViewModel.type';
import { getMinicart } from 'Cart/action-creators';
import Modal from 'Shared/SharedComponents/atoms/Modal';
import CartList from './CartList';
import { checkoutPageUrl } from 'Shared/known-urls';
import RecommendationsList from 'Product/SwipeableList';
import Price from 'Pricing/Price';

type RequiredPropType = {};

type ConnectedPropType = {
  pages: SitePages;
  currentBreakpoint: number;
  displayCartModal: boolean;
  showCartModal: () => void;
  hideCartModal: () => void;
  miniCart: MiniCartType;
  culture: string;
  currency: string;
};

type PropType = RequiredPropType & ConnectedPropType & { headerHeight: number };

export const SecondaryRightmost = styled(ModalBase, {
  right: '8px',
  left: 'initial',
  ...style.transition({ property: ['transform', 'opacity'], duration: '300ms' }),
});

const mobileMarginBottom = 9;
export const MobileModal = styled(ModalBase, {
  right: '8px',
  left: '8px',
  overflow: 'hidden',
  margin: { xy: 0, x: 'auto' },
  ...style.transition({ property: ['transform', 'opacity'], duration: '300ms' }),
  bottom: `${mobileMarginBottom}px`,
  width: 'auto',
  top: 'initial',
});

const CartModal = (props: PropType) => {
  const { displayCartModal, showCartModal, hideCartModal } = props;
  const isMobile = isCompact(props.currentBreakpoint);
  const widthInColumns = isMobile ? 10 : 6;

  return (
    <Modal
      modalIsShown={displayCartModal}
      hideModal={hideCartModal}
      backgroundColor={style.colors.monochrome.white}
      widthInColumns={widthInColumns}
    >
      <CartModalContent {...props} />
    </Modal>
  );
};

const CartTopWrap = styled.div({
  display: 'flex', gap: '16px', width: '100%', flexWrap: 'wrap'
})

const CartTop = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
})

const CartTopText = styled(Text, {
  lineHeight: 1,
})

const CartTopClose = styled(Close, {
  height: 16, width: 16, cursor: 'pointer'
})

const CartTopLine = styled.hr({
  width: '100%',
  position: 'relative',
  backgroundColor: style.colors.monochrome.black,
  height: '2px',
  margin: { xy: 0, bottom: '12px' },
  border: { y: { width: 0 }, x: { width: 0 } },
  ...style.transition({
    property: ['margin', 'background-color'],
    duration: `${ANIMATION_DURATION}ms`,
  }),
})

const ScrollArea = styled(ScrollableFade, {
  padding: { xy: 0 },
  marginTop: '4px',
  gap: '30px', // additional 10 is added from the cart item
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '32px',
})

const EmptyCartWrap = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px',
  margin: { top: '36px' }
})

const EmptyCartHeader = styled(H2, {
  lineHeight: '100%',
  fontSize: '20px',
  ['@media ' + style.mediaMinLarge]: {
    fontSize: '32px'
  },
})

const EmptyCartIntro = styled(Text, {
  margin: { top: '16px' }
})

const EmptyCartButton = styled(SolidButton, {
  minHeight: '48px', margin: { top: '16px', bottom: '40px' }
})

const CartTotalsWrap = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
  margin: { top: 12 }
})

const CartTotalsText = styled(Text, {
  lineHeight: 1,
})

const CartTotalsPrice = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '4px',
})

const CartShippingWrap = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
  margin: { top: '8px' },
  fontWeight: 450
})

const CartShippingText = styled(Text, {
  lineHeight: 1,
  fontWeight: 450
})

const CartShippingPrice = styled.div({
  display: 'flex',
})

const CartSumInfo = styled.div({
  display: 'flex',
  padding: { xy: '8px' },
  gap: '8px',
  flexDirection: 'row',
  justifyContent: 'left',
  alignItems: 'center',
  borderRadius: '8px',
  backgroundColor: style.hex2rgba(style.colors.brandColors.green, 0.3),
  width: '100%',
  margin: { top: '8px' },
  ['@media ' + style.mediaMinLarge]: {
    justifyContent: 'center',
    borderRadius: '38px',
  },
})

const CartSumInformationLine = styled.span({
  display: 'block',
  textAlign: 'center'
})

const CartButton = styled(LinkButton, {
  minHeight: '48px', margin: { top: '8px' }
})

export const CartModalContent = ({
  currentBreakpoint,
  miniCart,
  displayCartModal,
  hideCartModal,
}: PropType & { css?: StyleOrStyleArray }) => {
  const isMobile = isCompact(currentBreakpoint);

  const [retainedCartModal, setRetainedCartModal] = useState<MiniCartViewModel | undefined>(undefined);

  useEffect(() => {
    if (displayCartModal) {
      getMinicart().then((cart: any) => setRetainedCartModal(cart));
    }
  }, [displayCartModal]);

  useEffect(() => {
    if (displayCartModal) {
      setRetainedCartModal(miniCart);
    }
  }, [miniCart]);

  const itemsInCart = retainedCartModal?.cartViewModel.items.reduce((qtyInCart: number, item: { quantity: number }) => qtyInCart + item.quantity, 0)
  const emptyCart = Boolean(itemsInCart < 1)

  return (
    <>
      {retainedCartModal && (
        <>
          <CartTopWrap>
            <CartTop>
              <CartTopText fontSize={ResponsiveSize.D16_M16} upperCase>
                {retainedCartModal?.labels.yourCart ?? 'labels.yourCart'} ({itemsInCart})
              </CartTopText>
              <CartTopClose onClick={() => hideCartModal()} />
            </CartTop>
            <CartTopLine />
          </CartTopWrap>
          <ScrollArea>
            {emptyCart && (
              <EmptyCartWrap>
                <EmptyCartHeader>{retainedCartModal?.emptyCartHeading ?? 'emptyCartHeading'}</EmptyCartHeader>
                <EmptyCartIntro>{retainedCartModal?.emptyCartIntro ?? 'emptyCartIntro'}</EmptyCartIntro>
                {retainedCartModal?.emptyCartButton?.text && retainedCartModal?.emptyCartButton?.url &&
                  <EmptyCartButton
                    text={retainedCartModal?.emptyCartButton.text}
                    onClick={() => {
                      pushState(retainedCartModal?.emptyCartButton.url);
                      hideCartModal();
                    }}
                    height={ButtonHeight.Big}
                    theme={ButtonTheme.BlackToWhite}
                    display="block"
                  />
                }
                <RecommendationsList
                  numberOfProductsVisible={isMobile ? 1.5 : 3}
                  alternativeNav
                  listHeading={retainedCartModal.labels.recommendationsHeadingEmptyCart}
                  products={retainedCartModal.recommendations || []}
                  quickAddToCart={false}
                  isCompact={isMobile}
                  overflowHidden
                  showNav={!isMobile}
                />
              </EmptyCartWrap>
            )}
            {!emptyCart && (
              <>
                <CartList isMobile={isMobile} miniCart={retainedCartModal} close={hideCartModal} ></CartList>
                <RecommendationsList
                  numberOfProductsVisible={isMobile ? 1.5 : 3}
                  alternativeNav
                  listHeading={retainedCartModal.labels.recommendationsHeading}
                  products={retainedCartModal.recommendations || []}
                  quickAddToCart={false}
                  isCompact={isMobile}
                  overflowHidden
                  showNav={!isMobile}
                />
              </>
            )}
          </ScrollArea>
          {!emptyCart && (
            <>
              <CartTotalsWrap>
                <CartTotalsText fontSize={ResponsiveSize.D16_M16} upperCase>
                  {retainedCartModal?.labels.sum ?? 'labels.sum'}
                </CartTotalsText>
                <CartTotalsPrice>
                  <Price listPrice={retainedCartModal.cartViewModel.totalListPrice} sellingPrice={retainedCartModal.cartViewModel.totalPlacedPrice} fontSize={16} />
                </CartTotalsPrice>
              </CartTotalsWrap>
              <CartShippingWrap>
                <CartShippingText fontSize={ResponsiveSize.D16_M16}>
                  {retainedCartModal?.labels.shipment ?? 'labels.shipment'}
                </CartShippingText>
                <CartShippingPrice>
                  <Price sellingPrice={retainedCartModal.cartViewModel.shippingTotal} fontSize={16} fontWeight={450} />
                </CartShippingPrice>
              </CartShippingWrap>
            </>
          )}
          {retainedCartModal?.cartViewModel?.cartSumInformation && (
            <CartSumInfo>
              {/* <Truck /> */}
              <Text fontSize={ResponsiveSize.D12_M12}>
                {retainedCartModal.cartViewModel.cartSumInformation.map((ci, index) => {
                  return (<CartSumInformationLine key={index}>{ci}</CartSumInformationLine>);
                })}
              </Text>
            </CartSumInfo>
          )}
          {!emptyCart && (
            <CartButton
              text={retainedCartModal?.labels.proceedToCheckout ?? ''}
              to={checkoutPageUrl()}
              fullWidth
              onClick={() => {
                hideCartModal();
              }}
              height={ButtonHeight.Big}
              theme={ButtonTheme.BlackToWhite}
              display="block"
            />
          )}
        </>
      )}
    </>
  );
};

export default connect(
  (state) => ({
    //cart: state.cart,
    miniCart: state.miniCart,
    currentBreakpoint: state.currentBreakpoint,
    displayCartModal: state.UI.displayCartModal,
    pages: state.appShellData.pages,
    culture: state.appShellData.culture,
    currency: state.appShellData.market?.currency,
  }),
  (dispatch) => ({
    showCartModal: () => dispatch(ShowCartModal()),
    hideCartModal: () => dispatch(HideCartModal()),
  }),
)(CartModal);

export const missingValue = 'MISSING VALUE';

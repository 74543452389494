import factory from './icon';
import Svg12x12 from './glyphs/Plus_12x12.svg';
import Svg16x16 from './glyphs/Plus_16x16.svg';
import Svg24x24 from './glyphs/Plus_24x24.svg';

export const Plus12x12 = factory(Svg12x12, 12, 12);
export const Plus16x16 = factory(Svg16x16, 16, 16);
export const Plus24x24 = factory(Svg24x24, 24, 24);


export const Cross48Uri =
  'data:image/svg+xml,' +
  encodeURIComponent(`<svg width="48" height="48" viewBox="0 0 48 48" fill="none" version='1.1' x='0px' y='0px'  style='enable-background:new 0 0 512 512;' xmlns="http://www.w3.org/2000/svg" xmlns:xlink= "http://www.w3.org/1999/xlink" xml:space='preserve' >
<path d="M40.9709 7.0293L24.0002 24M24.0002 24L7.02954 40.9706M24.0002 24L40.9709 40.9706M24.0002 24L7.02954 7.0293" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
</svg>`); //Hard coded because lord knows how to get it to render correctly with all the attributes in the SVG tag that appear to get discarded with the renderToStaticMarkup function

export const Plus48Uri =
  'data:image/svg+xml,' +
  encodeURIComponent(`<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.0001 0.999857L25.0001 25M25.0001 25L25.0001 49.0001M25.0001 25L49.0003 25M25.0001 25L1 25" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
</svg>`); //Hard coded because lord knows how to get it to render correctly with all the attributes in the SVG tag that appear to get discarded with the renderToStaticMarkup function

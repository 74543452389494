import atIcon from './AT';
import beIcon from './BE';
import bgIcon from './BG';
import cyIcon from './CY';
import czIcon from './CZ';
import deIcon from './DE';
import dkIcon from './DK';
import eeIcon from './EE';
import esIcon from './ES';
import fiIcon from './FI';
import frIcon from './FR';
import gbIcon from './GB';
import grIcon from './GR';
import hrIcon from './HR';
import huIcon from './HU';
import ieIcon from './IE';
import itIcon from './IT';
import ltIcon from './LT';
import luIcon from './LU';
import lvIcon from './LV';
import mtIcon from './MT';
import nlIcon from './NL';
import noIcon from './NO';
import plIcon from './PL';
import ptIcon from './PT';
import roIcon from './RO';
import seIcon from './SE';
import siIcon from './SI';
import skIcon from './SK';
import usIcon from './US';

const GetFlagIcon = (twoLetterCountryCode: string) => {
  if (twoLetterCountryCode == null) {
    return null;
  }

  switch (twoLetterCountryCode.toLowerCase()) {
    case 'at':
      return atIcon;
    case 'be':
      return beIcon;
    case 'bg':
      return bgIcon;
    case 'cy':
      return cyIcon;
    case 'cz':
      return czIcon;
    case 'de':
      return deIcon;
    case 'dk':
      return dkIcon;
    case 'ee':
      return eeIcon;
    case 'es':
      return esIcon;
    case 'fi':
      return fiIcon;
    case 'fr':
      return frIcon;
    case 'gb':
      return gbIcon;
    case 'gr':
      return grIcon;
    case 'hr':
      return hrIcon;
    case 'hu':
      return huIcon;
    case 'ie':
      return ieIcon;
    case 'it':
      return itIcon;
    case 'lt':
      return ltIcon;
    case 'lu':
      return luIcon;
    case 'lv':
      return lvIcon;
    case 'mt':
      return mtIcon;
    case 'nl':
      return nlIcon;
    case 'no':
      return noIcon;
    case 'pl':
      return plIcon;
    case 'pt':
      return ptIcon;
    case 'ro':
      return roIcon;
    case 'se':
      return seIcon;
    case 'si':
      return siIcon;
    case 'sk':
      return skIcon;
    case 'us':
      return usIcon;
    default:
      console.log(`${twoLetterCountryCode} could not be found!`);
      return null;
  }
}

export default GetFlagIcon;
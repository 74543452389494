import { addUserLog, scrollPosition } from '@polarnopyret/scope';
import State, { Action, Dispatch } from 'Shared/State';
import { batchActions } from 'redux-batched-actions';
import MainMenuItemType from './MainMenuItem.type';
import SecondaryMenuItem from 'SiteLayout/MainMenu/SecondaryMenuItem.type';

export const TOGGLE_MAINMENU = 'TOGGLE_MAINMENU';
export const SET_MAINMENU_CATEGORY = 'SET_MAINMENU_CATEGORY';
export const SET_SECONDARY_MENUITEM = 'SET_SECONDARY_MENUITEM';

export type SetMainMenuAction = Action & {
  selectedCategory: MainMenuItemType;
};

export type SetSecondaryMenuAction = Action & {
  selectedSecondaryMenuItem: SecondaryMenuItem;
};

export type ToggleMainMenuAction = Action & {
  menuTopPosition: number;
};

export function toggleMainMenu(selectedCategory?: MainMenuItemType) {
  return (dispatch: Dispatch, getState: () => State) => {
    const menuTopPosition = getState().mainMenu.isOpen ? getState().mainMenu.menuTopPosition : scrollPosition();

    const toggleAction = {
      type: TOGGLE_MAINMENU,
      menuTopPosition,
    } as Action;

    if (selectedCategory) {
      dispatch(batchActions([setMainMenuCategory(selectedCategory), toggleAction]));
    } else {
      dispatch(toggleAction);
    }
  };
}

export function setMainMenuCategory(selectedCategory: MainMenuItemType) {
  return {
    type: SET_MAINMENU_CATEGORY,
    selectedCategory,
  } as SetMainMenuAction;
}

export function setSelectedSecondaryMenuItem(selectedSecondaryMenuItem: SecondaryMenuItem) {
  return {
    type: SET_SECONDARY_MENUITEM,
    selectedSecondaryMenuItem,
  } as SetSecondaryMenuAction;
}

import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { URLX, relativeUrl } from '@polarnopyret/scope';
import { Preset, getImagePreset } from '../image-sizes';
import NoImage from './noimage.svg';
import * as style from '../Style';

const ImageTransition = styled.img(style.transition({ property: 'opacity' }));

const ImageTransitionHidden = styled(ImageTransition, {
  opacity: 0,
})

const BasicPicture = styled.picture({
});

const BasicImage = styled.img({
  height: 'inherit',
  width: 'inherit',
  borderRadius: 'inherit',
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: 'inherit'
});

export { Preset } from '../image-sizes';

enum Status {
  Pending,
  Rejected,
  Fulfilled,
}
type Merge<T, S> = Pick<T, Exclude<keyof T, keyof S>> & S;

export type PropType = StyledProps &
  Merge<
    React.ImgHTMLAttributes<HTMLImageElement>,
    {
      src?: Scope.UrlViewModel;
      preset?: Preset;
      pendingPreview?: boolean;
      breakPoint?: number;
    }
  >;

export type PicturePropType = StyledProps &
  Merge<
    React.HTMLAttributes<HTMLPictureElement>,
    {
      src?: string;
      preset?: Preset;
      alt?: string;
      imageRef?: React.Ref<HTMLPictureElement>;
    }
  >;

type StateType = {
  status?: Status;
};

export const PictureImage = (props: PicturePropType) => {
  const { src, preset, title, alt, imageRef, compose, ...restProps } = props;

  if (!src) {
    return (
      <NoImage
        className={props.className}
        style={{ display: 'block', ...props.style }}
        width="100%"
        height="100%"
      />
    );
  }

  const url = new URLX(src);

  if (preset >= 0) {
    url.searchParams.set('preset', getImagePreset(preset));
  }
  const imageUrl = relativeUrl(url);

  return (
    <BasicPicture {...restProps} css={compose ? compose() : null} ref={imageRef}>
      {imageUrl.includes('preset') && <source type='image/webp' srcSet={imageUrl + "-wp"} />}
      <BasicImage loading='lazy' alt={alt} title={title} src={imageUrl} />
    </BasicPicture>
  );
};

export default styled(
  class Img extends React.Component<PropType, StateType> {
    mounted: boolean;
    isCached: boolean;
    currentLoad: Promise<HTMLImageElement>;
    src: string | null = null;
    constructor(props: PropType) {
      super(props);
      this.src = this.srcUrl();
      this.mounted = false;
      this.isCached = !!props.src && !!findBrowserCachedResource(props.src.url);
      this.state = {
        status: props.src ? (this.isCached ? Status.Fulfilled : Status.Pending) : Status.Rejected,
      };
    }
    componentDidMount() {
      this.mounted = true;

      if (this.cachedIsNotForCurrentPreset() && this.src) {
        this.loadResource(this.src, this.props.src.url);
      }
    }

    cachedIsNotForCurrentPreset() {
      if (this.state.status === Status.Rejected) {
        return false;
      }
      const cachedResource = findBrowserCachedResource(this.props.src.url);

      if (cachedResource) {
        if (this.props.preset) {
          let imagePreset = getImagePreset(this.props.preset);
          if (imagePreset !== cachedResource.preset) {
            return true;
          }

          return false;
        }
      }
      return true;
    }

    componentWillReceiveProps(nextProps: PropType) {
      if (
        this.props.src !== nextProps.src ||
        this.props.preset !== nextProps.preset ||
        this.props.breakPoint !== nextProps.breakPoint ||
        this.cachedIsNotForCurrentPreset()
      ) {
        this.src = this.srcUrl(nextProps.src?.url, nextProps.preset);
        if (this.src) {
          this.loadResource(this.src, nextProps.src.url);
        }
      }
    }
    componentDidUpdate(prevProps: PropType) {
      if (!this.props.src) {
        this.status(Status.Rejected);
      } else if (this.props.src !== prevProps.src || this.props.preset !== prevProps.preset) {
        if (!this.isCached) {
          this.status(Status.Pending);
        }
      }
    }
    componentWillUnmount() {
      this.mounted = false;
    }
    async loadResource(src: string, originalSrc: string) {
      this.status(Status.Pending);

      const promise = (this.currentLoad = load(src));
      try {
        const image = await promise;
        rememberBrowserCachedResource(image, originalSrc);
        if (this.mounted && this.currentLoad === promise) {
          this.status(Status.Fulfilled);
        }
      } catch (e) {
        if (this.mounted && this.currentLoad === promise) {
          this.status(Status.Rejected);
        }
      }
    }
    status(status: Status) {
      if (this.state.status !== status) {
        this.setState({ status });
      }
    }
    srcUrl(src = this.props.src?.url, preset = this.props.preset, quality?: number): string {
      if (src) {
        const url = new URLX(src);

        if (typeof preset === 'number') {
          url.searchParams.set('preset', getImagePreset(preset));
        }

        if (quality) {
          url.searchParams.set('quality', String(quality));
        }

        return relativeUrl(url);
      }

      return null;
    }
    checkIfCachedByBrowser = (image: HTMLImageElement) => (this.isCached = assumeCachedByBrowser(image));
    render() {
      if (this.src) {
        const { status } = this.state;

        const { compose, pendingPreview, breakPoint, preset, ...restProps } = this.props;

        if (status === Status.Fulfilled) {
          return <ImageTransition {...restProps} src={this.src} ref={this.checkIfCachedByBrowser} css={compose()} />;
        }

        if (this.src) {
          const cache = findBrowserCachedResource(this.props.src.url);

          if (cache) {
            return <ImageTransition {...restProps} src={cache.src} ref={this.checkIfCachedByBrowser} css={compose()} />;
          }

          if (status === Status.Pending) {
            return pendingPreview ? (
              <ImageTransition
                {...restProps}
                src={this.src}
                ref={this.checkIfCachedByBrowser}
                css={compose()}
              />
            ) : (
              <ImageTransitionHidden
                {...restProps}
                src={this.src}
                ref={this.checkIfCachedByBrowser}
                css={compose()}
              />
            );
          }
        }
      }

      return (
        <NoImage
          className={this.props.className}
          style={{ display: 'block', ...this.props.style }}
          width="100%"
          height="100%"
        />
      );
    }
  },
);

function load(src: string) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    Object.assign(image, {
      onload: () => resolve(image),
      onerror: () => reject(),
      src,
    });

    if (image.complete) {
      if (image.naturalWidth > 0) {
        resolve(image);
      } else {
        requestAnimationFrame(() => {
          // Some browsers is slow to report width so give it one more try before we reject it
          if (image.naturalWidth > 0) {
            resolve(image);
          } else {
            reject();
          }
        });
      }
    }
  });
}

type ResourceType = {
  width: number;
  height: number;
  src: string;
  preset?: string;
};

const browserCachedResources: { [src: string]: ResourceType } = {};

function findBrowserCachedResource(originalSrc: string) {
  return browserCachedResources[originalSrc];
}

function rememberBrowserCachedResource(image: HTMLImageElement, originalSrc: string) {
  // Firefox has an ugly tendency to report naturalWidth as 0 when a SW is active.
  // But if we wait just a little bit, the sizes have been calculated. Broken
  // images should be uncommon, so we can afford the setTimeout here
  requestAnimationFrame(() => {
    if (image && assumeCachedByBrowser(image)) {
      const cached = findBrowserCachedResource(originalSrc);
      if (!cached || image.naturalWidth > cached.width) {
        const url = new URLX(image.currentSrc);
        const preset = url.searchParams.get('preset');
        browserCachedResources[originalSrc] = {
          width: image.naturalWidth,
          height: image.naturalHeight,
          src: image.currentSrc,
          preset: preset,
        };
      }
    }
  });
}

function assumeCachedByBrowser(image: HTMLImageElement) {
  if (!image) {
    return false;
  }
  const url = new URLX(image.src);

  // Firefox and IE has a bug where svg reports 0 for natural size in some cases
  if (/\.svg/.test(url.pathname)) {
    return false;
  }
  return image.complete && image.naturalWidth > 0;
}

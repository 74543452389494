import React from 'react';
import { styled, StyledProps, applyClassName } from '@glitz/react';
import * as style from 'Shared/Style';

type PropType = React.HTMLAttributes<HTMLElement> & {
  component?: string;
};

export const NeutralMessage = styled(
  applyClassName(({ compose, component: TagName = 'div', ...restProps }: StyledProps & PropType) => (
    <TagName {...restProps} />
  )),
  {
    color: style.colors.monochrome.black,
    margin: { xy: '16px' },
    textAlign: 'center',
  },
);

export const ErrorMessage = styled(NeutralMessage, {
  color: style.colors.negativeColors.negative,
});

export const SucessMessage = styled(NeutralMessage, {
  color: style.colors.monochrome.black,
});

import * as style from './Style';
import { styled } from '@glitz/react';
import { Basic } from './PageLayout';

const Base = styled(Basic, {
    backgroundColor: style.colors.monochrome.white,
    opacity: 1,
    position: 'absolute',
    left: '50%',
    top: 0,
    transform: 'translateX(-50%)',
    pointerEvents: 'none',
});

export const ScrollableFade = styled(Basic, {
    overflowY: 'scroll',
    padding: { top: 24, x: 16 },
    height: '100%',
    width: '100%',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    // @ts-ignore
    '::-webkit-scrollbar': {
      display: 'none',
    },
    //maskImage: `linear-gradient(to bottom, black calc(100% - 32px), transparent 100%)`,
    mask: {
      image: 'linear-gradient(rgb(0, 0, 0) 0px, rgb(0, 0, 0) calc(100% - 32px), rgba(0, 0, 0, 0) 100%, rgba(0, 0, 0, 0) 0px)',
      size: '100% 100%',
      position: '100% 50%',
      repeat: 'repeat no-repeat'
    },
  });

  export const ModalBase = styled(Base, {
    pointerEvents: 'initial',
    position: 'fixed',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
    width: '330px',
    borderRadius: '8px',
    order: 0,
    flexGrow: 0,
    left: 8,
    display: 'flex',
    ...style.transition({ property: ['transform'], duration: '300ms' }),
  });
  
import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import * as style from 'Shared/Style';

type IndicatorPropType = React.HTMLAttributes<HTMLSpanElement> & {
  children?: number;
};

const Wrap = styled.span({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  height: '18px',
  fontSize: '12px',
  fontWeight: 'normal',
  lineHeight: 1,
  borderRadius: '100vw',
  ['@media ' + style.mediaMinLarge]: {
    minWidth: '3ch',
  },
  ...style.transition({ property: ['transform', 'width', 'color'] }),
})

export const Indicator = styled(({ compose, children, ...restProps }: StyledProps & IndicatorPropType) => (
  <Wrap
    {...restProps}
    css={compose({
      ...(children < 10
        ? {
            width: '16px',
          }
        : {
            width: '20px',
          }),
      transform: `scale(${children > 0 ? 1 : 0})`,
    })}
  >
    {children}
  </Wrap>
));
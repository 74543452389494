import SitePagesType from 'AppShell/Models/SitePages.type';
import ContentType from 'AppShell/Models/Content.type';
let sitePages: SitePagesType = null;
let content: ContentType = null;

export function setKnownUrls(pages: SitePagesType, content: ContentType) {
  sitePages = pages;
  content = content;
}

export function searchPageUrl() {
  return sitePages.searchPage ? sitePages.searchPage.url : '';
}

export function resetPasswordPage() {
  return sitePages.resetPasswordPage ? sitePages.resetPasswordPage.url : '';
}

export function checkoutPageUrl() {
  return sitePages.checkoutPage ? sitePages.checkoutPage.url : '';
}

export function myAccountPage() {
  return sitePages.myAccountPage ? sitePages.myAccountPage.url : '';
}

export function startPage() {
  return sitePages.startPage ? sitePages.startPage.url : '';
}

export function pageNotFoundBlocks() {
  return content?.pageNotFoundBlocks;
}

export function registerPage() {
  return sitePages.registerPage ? sitePages.registerPage.url : '';
}

export function giftCardUrl() {
  return sitePages.giftCardPage ? sitePages.giftCardPage.url : '';
}

export function pickUpInStorePage() {
  return sitePages.pickUpInStorePage ? sitePages.pickUpInStorePage.url : '';
}

export function wishListUrl() {
  return sitePages.wishlist ? sitePages.wishlist.url : '';
}

export function loginUrl() {
  return sitePages.loginPage ? sitePages.loginPage.url : '';
}

import React from 'react';
import { Left48x48, Right48x48 } from '../Icons/ArrowCircle';
import { Left16x16, Right16x16 } from '../Icons/Arrow';
import * as style from 'Shared/Style';

type ConnectedPropType = {
  isCompact: boolean;
  previousDisabled?: boolean;
  nextDisabled?: boolean;
  sideMargin?: string;
  color?: string;
  onClick: () => void;
};

export const SwipeLeft = ({
  isCompact,
  previousDisabled,
  sideMargin = '16px',
  color = style.colors.monochrome.white,
  onClick,
}: ConnectedPropType) => {
  return (
    <Left48x48
      css={{
        color: color,
        position: 'absolute',
        top: `calc(50% - 24px)`,
        left: sideMargin,
        zIndex: 1,
        userSelect: 'none',
        display: isCompact ? 'none' : 'block',
        opacity: previousDisabled ? 0 : 1,
        transition: {
          property: 'opacity',
          duration: '0.3s',
          timingFunction: 'ease-in-out',
        },
        cursor: 'pointer',
      }}
      onClick={(e) => onClick()}
    />
  );
};

export const SwipeLeftAlt2 = ({
  isCompact,
  previousDisabled,
  color = style.colors.monochrome.black,
  onClick,
}: ConnectedPropType) => {
  return (
    <Left16x16
      css={{
        userSelect: 'none',
        opacity: previousDisabled ? 0.5 : 1,
        transition: {
          property: 'opacity',
          duration: '0.3s',
          timingFunction: 'ease-in-out',
        },
        cursor: 'pointer',
        color: color,
      }}
      onClick={(e) => onClick()}
    />
  );
};

export const SwipeRight = ({
  isCompact,
  nextDisabled,
  sideMargin = '16px',
  color = style.colors.monochrome.white,
  onClick,
}: ConnectedPropType) => {
  return (
    <Right48x48
      css={{
        color: color,
        position: 'absolute',
        top: `calc(50% - 24px)`,
        right: sideMargin,
        zIndex: 1,
        userSelect: 'none',
        display: isCompact ? 'none' : 'block',
        opacity: nextDisabled ? 0 : 1,
        transition: {
          property: 'opacity',
          duration: '0.3s',
          timingFunction: 'ease-in-out',
        },
        cursor: 'pointer',
      }}
      onClick={(e) => onClick()}
    />
  );
};

export const SwipeRightAlt2 = ({
  isCompact,
  nextDisabled,
  color = style.colors.monochrome.black,
  onClick,
}: ConnectedPropType) => {
  return (
    <Right16x16
      css={{
        userSelect: 'none',
        opacity: nextDisabled ? 0.5 : 1,
        transition: {
          property: 'opacity',
          duration: '0.3s',
          timingFunction: 'ease-in-out',
        },
        cursor: 'pointer',
        color: color,
      }}
      onClick={(e) => onClick()}
    />
  );
};

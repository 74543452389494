import {
  post,
  URLX,
} from '@polarnopyret/scope';
import { wishListUrl } from 'Shared/known-urls';
import { AddItem, RemoveItem } from 'WishList/wishlistReducer';
import WishListUpdateResult from 'WishList/WishListUpdateResult.type';
import { Dispatch } from 'Shared/State';

export function toggleWishlistItem (
  isFavorited: boolean,
  code: string,
  colorProductCode: string,
  currentCategory: string,
) : any {
  return (dispatch: Dispatch) => {
    const url = new URLX(`${wishListUrl()}/update`);
    
    return post(
      url, 
      {
        items: {
          '': {
            newQuantity: isFavorited ? 0 : 1,
            previousQuantity: isFavorited ? 1 : 0,
            code: code,
            colorProductCode: colorProductCode,
            currentCategory: currentCategory,
          },
        },
      }, 
      {}
    )
    .then(r => r.json())
    .then((json: WishListUpdateResult) => {
      if (json.statusCode !== 500)
      {
        dispatch(
          isFavorited
            ? RemoveItem(json.updatedParentCode)
            : AddItem(json.updatedParentCode)
        );
      }
      //return json;
      return Promise.resolve(json);
    })
    .catch(
      e => {
        return Promise.reject(null);
      }
    )
  }
};

import connect from 'Shared/connect';
import { styled, StyledProps } from '@glitz/react';
import * as style from 'Shared/Style';
import React, { useEffect, useRef, useState } from 'react';
import Usp from 'AppShell/Models/Usp.type';
import { pushState } from '@polarnopyret/scope/index';
import Link from 'Shared/Link';

const Wrap = styled.div({
  background: 'black',
  display: 'flex',
  overflow: 'hidden',
  flexDirection: 'column',
  overflowY: 'hidden',
  height: '28px', 
  ['@media ' + style.mediaMinLarge]: {
    height: '32px', 
  },
})

const UspWrap = styled.div({
  ...style.transition({ property: ['transform'], duration: '800ms' }),
  display: 'block',
  height: '100%',
})

const UspLinkWrap = styled.div({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  height: '28px', 
  ['@media ' + style.mediaMinLarge]: {
    height: '32px', 
  },
})

const UspLink = styled(Link, {
  color: style.colors.monochrome.white,
  fontSize: '12px',
  fontWeight: 'bold',
  display: 'inline-flex',
  textDecoration: 'none',
  cursor: 'pointer',
})

const UspLinkText = styled.span({
  textTransform: 'uppercase',
  display: 'inline-block',
})

export const Usp = connect(
  state => ({
    usps: state.appShellData.usps,
  }),
)(
  styled(
    (
      props: StyledProps & {
        usps: Usp[];
        delay: number;
        isMobile: boolean;
      },
    ) => {
      const { compose, usps, delay, isMobile } = props;
      const uspRef = useRef<HTMLDivElement>(null);
      const height = isMobile ? 28 : 32;
      const [currentUsp, setCurrentUspIndex] = useState(0);

      useEffect(() => {
        const interval = setInterval(() => {
          if (usps && usps.length !== 0) {
            setCurrentUspIndex(
              currentUsp => (currentUsp + 1) % usps.length
            );
          }
        }, delay);
        return () => clearInterval(interval);
      }, []);

      useEffect(() => {
        if (uspRef.current) {
          const nr = currentUsp > 0 ? currentUsp * height : 0;
          
          if((currentUsp + 1) === usps?.length) {
            uspRef.current.style.transform = `translate3d(0px, ${height}px, 0px)`;
          }

          uspRef.current.style.transform = `translate3d(0px, -${nr}px, 0px)`;
        }
      }, [currentUsp]);
      
      return (
        <Wrap>
          {usps && usps.length > 0 && (
            <UspWrap key={'USPs'} ref={uspRef}
              style={{
                transform: 'translate3d(0px, 0px, 0px)',
              }}
            >
              {usps.map((link, index) => (
                <UspLinkWrap key={index}>
                  <UspLink
                    to={link.url}
                    onClick={!link.openInNewWindow ? e => {
                      if(!link.openInNewWindow) {
                        e.preventDefault();
                        if (link.url) pushState(link.url);
                      }
                    } : null}
                    target={link.openInNewWindow ? '_blank' : ''}
                    rel={link.openInNewWindow ? 'noopener noreferrer' : ''}
                    title={link.title}
                    css={{
                      ...(!link.url && {
                        cursor: undefined,
                      })
                    }}
                  >
                    <UspLinkText>
                      {link.text}
                    </UspLinkText>
                  </UspLink>
                </UspLinkWrap>
              ))}
            </UspWrap>
          )}
        </Wrap>
      );
    },
  ),
);

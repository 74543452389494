import { styled } from '@glitz/react';
import React from 'react';
import { InstantSearchLink, SearchSoftHeading } from './shared';
import * as style from 'Shared/Style';
import { replaceState } from '@polarnopyret/scope';

type SuggestionsPropType = {
  isMobile: boolean;
  heading?: string;
  categories?: {name: string, url: string}[];
};

const InlineBlack = styled.span({
  color: style.colors.monochrome.black
})

const Items = styled.div({
  marginBottom: '40px'
})

const CategoryList = (props: SuggestionsPropType) => {
  const { heading, categories, isMobile } = props;
  
  if(categories === null || (categories?.length ?? 0) < 1) {
    return (
      <></>
    );
  }
  
  return (
    <>
      <SearchSoftHeading>{heading}</SearchSoftHeading>
      <Items>
        {categories?.map((suggestion, index) => (
          <InstantSearchLink
            key={index}
            to={suggestion.url}
            onClick={(e) => {
              e.preventDefault();
              replaceState(suggestion.url);
            }}
            css={{ margin: { top: index !== 0 ? '12px' : undefined } }}
          >
            <InlineBlack>{suggestion.name}</InlineBlack>  
          </InstantSearchLink>
        ))}
      </Items>
    </>
  );
};

export default CategoryList;
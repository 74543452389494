import React, { useState } from 'react';
import { styled } from '@glitz/react';
import { addUserLog, pathCombine, postJson, URLX } from '@polarnopyret/scope';
import { ConnectPropType as FeedbackPropType, ButtonTheme } from 'Shared/Button/Feedback';
import { H2, ResponsiveSize, Text } from 'Shared/SharedComponents/atoms/Typography';
import Input from 'Shared/Fields/Text';
import Form from 'Shared/Fields/Form';
import { resetPasswordPage } from 'Shared/known-urls';
import LoginViewModel from 'Account/Login/LoginViewModel.type';
import { BorderStyle } from 'Shared/Style';
import { Left16x16, Left24x24 } from 'Shared/Icons/Arrow';
import ResetPasswordResultViewModel from 'ResetPassword/ResetPasswordResultViewModel.type';
import { ButtonHeight, SolidButton } from 'Shared/TextButtons';
import { ModalScrollableContent } from 'Shared/SharedComponents/atoms/Modal';
import * as style from 'Shared/Style';

type RequiredPropType = {
  redirectOnLogin: boolean;
  redirectToAfterLogin: string;
  toLogin: () => void;
  toRegister: () => void;
  isCompact: boolean;
};

type PropType = RequiredPropType & FeedbackPropType;

const ModalScrollableContentContainer = styled(ModalScrollableContent, {
  paddingTop: '32px',
})

const EmailSentContainer = styled.div({
  width: '100%',
  marginTop: '16px',
  ['@media ' + style.mediaMinLarge]: {
    marginTop: '24px',
  },
})

const ForgotPasswordForm = (
  props: PropType & { content: LoginViewModel; email: string; setEmail: (email: string) => void },
) => {
  const { content, email, setEmail, isCompact, feedback } = props;
  const FeedbackButton = feedback.Button;

  const [emailSent, setEmailSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  async function postForgotPassword() {
    try {
      const url = new URLX(pathCombine(resetPasswordPage(), 'SendResetPasswordLink'));
      const result: ResetPasswordResultViewModel = await postJson(url, {
        email: email.trim(),
      });
      if (result.success) {
      } else {
        setErrorMessage(result.message);
        return Promise.reject(null);
      }
    } catch (e) {
      setErrorMessage(e.errorMessage);
      return Promise.reject(null);
    }

    setEmailSent(true);
    return Promise.resolve();
  }

  const formContent = (
    <>
      <Text
        fontSize={ResponsiveSize.D16_M16}
        css={{
          color: style.colors.monochrome.black,
          width: '100%',
          marginBottom: errorMessage ? '4px' : '32px',
        }}
      >
        {emailSent ? content?.labels.passwordResetIntro : content?.labels.forgotPasswordIntro}
      </Text>
      {!!errorMessage && (
        <Text fontSize={ResponsiveSize.D14_M14_L130} css={{ color: style.colors.negativeColors.negative, paddingBottom: '7px' }}>
          {errorMessage}
        </Text>
      )}
      {emailSent ? (
        <EmailSentContainer>
          <SolidButton
            text={content?.labels.backToLogin}
            onClick={() => props.toLogin()}
            height={ButtonHeight.Default}
            theme={ButtonTheme.BlackToWhite}
            display="block"
            fullWidth
          />
        </EmailSentContainer>
      ) : (
        <Form onSubmitCallback={() => feedback.push(postForgotPassword())} isIncomplete={!email}>
          <Input
            value={email}
            required
            type="email"
            onChange={(e: React.FormEvent<HTMLInputElement>) => setEmail(e.currentTarget.value)}
            placeholder={content?.labels.email}
            errorText={email ? content?.labels.invalidEmail : content?.labels.missingEmail}
            css={{ display: 'block' }}
            borderStyle={BorderStyle.BlackThinSolid}
          />
          <styled.Div
            css={{
              width: '100%',
              marginTop: isCompact ? '16px' : '24px',
            }}
          >
            <FeedbackButton
              type="submit"
              height={ButtonHeight.Default}
              fullWidth
              display="block"
              pendingText={content?.labels.resetPassword}
              text={content?.labels.resetPassword}
              theme={ButtonTheme.BlackToWhite}
              onClick={() => { }}
            />
          </styled.Div>
        </Form>
      )}
    </>
  );

  return (
    <>
      <styled.Div
        onClick={() => props.toLogin()}
        css={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          color: style.colors.monochrome.black,
          ...(isCompact && {
            paddingBottom: '12px',
            borderBottom: { style: 'solid', width: '2px', color: style.colors.monochrome.black },
          }),
        }}
      >
        {isCompact ? <Left24x24 css={{ marginRight: '12px' }} /> : <Left16x16 css={{ marginRight: '12px' }} />}
        <Text fontSize={ResponsiveSize.D16_M16} css={{ width: '100%' }} upperCase>
          {content?.labels.backToLogin}
        </Text>
      </styled.Div>
      <ModalScrollableContentContainer>
        <H2 fontSize={ResponsiveSize.D40_M32} css={{ marginBottom: '16px' }}>
          {emailSent ? content?.labels.passwordResetHeading : content?.labels.forgotPasswordHeading}
        </H2>
        {formContent}
      </ModalScrollableContentContainer>
    </>
  );
};

export default ForgotPasswordForm;

import { PageType } from 'Shared/State';
import { componentNames } from 'Shared/component-registry';
import WishListPageViewModel from './WishListPageViewModel.type';

type WishListPageType = WishListPageViewModel & PageType;

export default (currentPage: PageType): currentPage is WishListPageType => {
  return (
    currentPage.componentName === componentNames.Avensia_Common_Features_WishList_WishListPageViewModel
  );
};

import { pseudo } from '@glitz/core';
import { styled } from '@glitz/react';
import * as style from 'Shared/Style';

export const TR = styled.tr({
  ...pseudo(':nth-child(even)', {
    backgroundColor: style.colors.monochrome.extraLightGrey,
  }),
  ...pseudo(':nth-child(even) td:first-child', {
    borderTopLeftRadius: '16px',
    borderBottomLeftRadius: '16px',
    backgroundColor: style.colors.monochrome.extraLightGrey,
  }),
  ...pseudo(':nth-child(even) td:last-child', {
    borderTopRightRadius: '16px',
    borderBottomRightRadius: '16px',
  }),

  ...pseudo(':nth-child(odd) td:first-child', {
    borderTopLeftRadius: '16px',
    borderBottomLeftRadius: '16px',
    backgroundColor: style.colors.monochrome.white,
  }),
});

export const TD = styled.td({
  border: { xy: { style: 'none' } },
  padding: {
    x: '8px',
    y: '4px'
  },
  fontSize: '14px',
  fontWeight: 500,
  ...pseudo(':first-child', {
    position: 'sticky',
    left: 0,
    top: 0
  }),
});

export const TH = styled.th({
  border: { xy: { style: 'none' } },
  backgroundColor: 'white',
  textTransform: 'uppercase',
  fontSize: '12px',
  fontWeight: 500,
  color: style.colors.monochrome.a11ygrey,
  padding: {
    x: '8px',
    y: '4px'
  },
  ...pseudo(':first-child', {
    position: 'sticky',
    left: 0,
    top: 0
  }),
});

export const Table = styled.table({
  borderCollapse: 'collapse',
  textAlign: 'center',
  tableLayout: 'fixed',
  border: {
    xy: {
      style: 'none',
    }
  }
});
